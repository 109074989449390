import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as SubjectSelector from "../../_store/selectors/subject.selector";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as SubjectReducer from "../../_store/reducers/subject.reducer";
import { Subject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  @Input() subjectRequest;

  displayedColumns: string[] = ['documentName', 'created_at', 'userName', 'total_pages', 'step'];
  dataSource = new MatTableDataSource([]);
  destroy: Subject<void> = new Subject();

  constructor(
      private store$: Store<SubjectReducer.State>,
  ) { }

  ngOnInit() {
    this.store$.select(SubjectSelector.getSubjectFlow).pipe(takeUntil(this.destroy)).subscribe(subjectFlow => {
      if (subjectFlow === null) {
        return;
      }

      const dataSource = subjectFlow.documents.map(document => {
        const step = document.step;
        const userName = document.userCreator.full_name_prefix;
        const uploadDate = document.created_at;
        const documentName = document.filename;
        const totalPages = document.total_pages
        return {
          documentName: documentName,
          created_at: uploadDate,
          userName: userName,
          step: step,
          total_pages: totalPages
        }
      });

      this.dataSource = new MatTableDataSource(dataSource);
    });
  }

  ngOnDestroy(): void {

  }
}
