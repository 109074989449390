import { Action } from '@ngrx/store';
import { UserRoleOrganizationalUnitModel } from "../../_models/UserRoleOrganizationalUnit.model";
import { CatParticipantActionsModel } from "../../_models/CatParticipantActions.model";

export const ADD_PARTICIPANTS = '[Assign Participants] Add Participants';
export const UPDATE_PARTICIPANT = '[Assign Participants] Update Participant';
export const REMOVE_PARTICIPANT = '[Assign Participants] Remove Participant';
export const STORE_PARTICIPANT_ACTIONS = '[Assign Participants] Store Participant Actions';
export const SET_VALID_PARTICIPANTS = '[Assign Participants] Set Valid Participants';
export const CLEAR_SELECTED_PARTICIPANTS = '[Assign Participants] Clear Selected Participants';

export class AddParticipants {
  readonly type = ADD_PARTICIPANTS;

  constructor(public participants) {}
}

export class removeParticipant implements Action {
  readonly type = REMOVE_PARTICIPANT;
  constructor(public participant: UserRoleOrganizationalUnitModel) {}
}

export class updateParticipant implements Action {
  readonly type = UPDATE_PARTICIPANT;
  constructor(public participant: UserRoleOrganizationalUnitModel) {}
}

export class StoreParticipantActions implements Action {
  readonly type = STORE_PARTICIPANT_ACTIONS;

  constructor(public catParticipantActions: Array<CatParticipantActionsModel>) {
  }
}

export class SetValidParticipants implements Action {
  readonly type = SET_VALID_PARTICIPANTS;
  constructor(public validParticipants: boolean) {}
}

export class ClearSelectedParticipants implements Action {
  readonly type = CLEAR_SELECTED_PARTICIPANTS;
  constructor() {
  }
}

export type Actions = AddParticipants
  | removeParticipant
  | StoreParticipantActions
  | updateParticipant
  | SetValidParticipants
  | ClearSelectedParticipants;
