import * as GraphActions from '../actions/graph.actions';

export interface State {
    typeReport: any;
}

const initialState: State = {
    typeReport: 'inbox',
};

export function reducer(state = initialState, action: GraphActions.Actions): any {

    switch (action.type) {
        case GraphActions.STORE_TYPE_REPORT:
            return { ...state, typeReport: action.payload };
        case GraphActions.CLEAR_ALL:
            return { ...initialState };
        default:
            return state;
    }
}


