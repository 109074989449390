import { Action } from '@ngrx/store';
import { UserRoleOrganizationalUnitModel } from '../../_models/UserRoleOrganizationalUnit.model';
import { OfficeModelInterface } from '../../_models/OfficeModelInterface';
import { CatParticipantActionsModel } from '../../_models/CatParticipantActions.model';
import { SubjectRequestModel } from "../../../../../../src/app/_models/SubjectRequest.model";

export const ADD_PARTICIPANTS = '[New Office] Add Participants';
export const STORE_METADATA = '[New Office] Store Metadata';
export const CLEAR_ALL = '[New Office] Clear All';
export const SET_DOCUMENT_FLOW_TYPE = '[New Office] Set Document Flow Type';
export const SET_VALID_PARTICIPANTS = '[New Office] Set Valid Participants';
export const UPDATE_PARTICIPANT = '[New Office] Update Participant';
export const SET_VALID_DOCUMENT_METADATA = '[New Office] Set Valid Document Metadata';
export const REMOVE_PARTICIPANT = '[New Office] Remove Participant';
export const STORE_DOCUMENT = '[New Office] Store Document';
export const SET_IS_LOADING = '[New Office] Set isloading';
export const STORE_PARTICIPANT_ACTIONS = '[New Office] Store Participant Actions';
export const STORE_RESPOND_SUBJECT = '[New Office] Store Respond Subject';

export class ClearAll implements Action{
  readonly type = CLEAR_ALL;

  constructor() {}
}

export class AddParticipants {
    readonly type = ADD_PARTICIPANTS;

    constructor(public participants) {}
}

export class StoreMetadata implements Action{
  readonly type = STORE_METADATA;

  constructor(public metadata: Array<any>) {
  }
}

export class SetDocumentFlowType implements Action {
  readonly type = SET_DOCUMENT_FLOW_TYPE;
  constructor(public documentFlowType: number) {}
}

export class SetValidParticipants implements Action {
  readonly type = SET_VALID_PARTICIPANTS;
  constructor(public validParticipants: boolean) {}
}

export class updateParticipant implements Action {
  readonly type = UPDATE_PARTICIPANT;
  constructor(public participant: UserRoleOrganizationalUnitModel) {}
}

export class removeParticipant implements Action {
  readonly type = REMOVE_PARTICIPANT;
  constructor(public participant: UserRoleOrganizationalUnitModel) {}
}

export class SetValidDocumentMetadata implements Action {
  readonly type = SET_VALID_DOCUMENT_METADATA;
  constructor(public documentMetadata: boolean) {}
}

export class StoreDocument implements Action {
  readonly type = STORE_DOCUMENT;
  constructor(public document:OfficeModelInterface) {}
}

export class setIsLoading implements Action {
  readonly type = SET_IS_LOADING;
  constructor(public status: boolean) {}
}

export class StoreParticipantActions implements Action {
  readonly type = STORE_PARTICIPANT_ACTIONS;
  constructor(public catParticipantActions: Array<CatParticipantActionsModel>) {}
}

export class StoreRespondSubject implements Action {
  readonly type = STORE_RESPOND_SUBJECT;
  constructor(public subjectRequest: SubjectRequestModel) {}
}

export type Actions = AddParticipants
  | ClearAll
  | StoreMetadata
  | SetDocumentFlowType
  | SetValidParticipants
  | updateParticipant
  | SetValidDocumentMetadata
  | removeParticipant
  | StoreDocument
  | setIsLoading
  | StoreParticipantActions
  | StoreRespondSubject;
