import {Directive, ElementRef, Input, Renderer2} from '@angular/core';


@Directive({
  selector: '[appScrollPadding]'
})
export class ScrollPaddingDirective {
  @Input() titleLength: number;
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(){
    if(this.titleLength > 90){
      this.el.nativeElement.style = "padding-top: 10px";
    }else{
      this.el.nativeElement.style = "padding-top: 2px;";
    }
  }
}
