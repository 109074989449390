import { Action } from '@ngrx/store'
import * as RecipientsTreeAction from './../actions/recipients-tree.actions';
import { UserModel } from "../../../../../../src/app/_models/user.model";
import { OrganizationalUnitModel } from "../../../../../../src/app/_models/OrganizationalUnit.model";
import {UserRoleOrganizationalUnitModel } from "../../_models/UserRoleOrganizationalUnit.model";

export interface State {
  users: Array<UserRoleOrganizationalUnitModel>,
  organizationalUnit: OrganizationalUnitModel
}

const initialState: State = {
  users: null,
  organizationalUnit: null
};

export function reducer(state = initialState, action: RecipientsTreeAction.Actions) : any {

    switch(action.type) {
        case RecipientsTreeAction.STORE_RECIPIENTS:
            return {...state, users: action.users, organizationalUnit: action.organizationalUnit };
        case RecipientsTreeAction.CLEAR_ALL:
            return { ...initialState };
        default:
            return state;
    }
}


