import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { SubjectDialogDataService } from '../_services/subject-dialog-data.service';
import { SubjectRequestModel } from '../_models/SubjectRequest.model';
import { MAILBOX_STATUS_HISTORICAL, MAILBOX_TYPE_INBOX, MAILBOX_TYPE_INBOXOUT } from '../_constants/MailboxConstants';
import { SubjectService } from '../_services/subject.service';
import { Store } from '@ngrx/store';
import * as SubjectReducer from '../_store/reducers/subject.reducer';
import * as SubjectActions from '../_store/actions/subject.actions';
import * as SubjectStatus from '../_constants/SubjectStatus.constants';
import * as MailboxReducer from '../_store/reducers/mailbox.reducer';
import { SubjectRequestService } from '../_services/subject-request.service';
import { SubjectPreviewDirective } from './subject-preview.directive';
import { AdItem } from './ad-item';
import { AdService } from './ad.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotifierComponent} from '../notifier/notifier.component';
import * as SubjectViewModule from '../_constants/SubjectViewModuleConstants';
@Component({
  templateUrl: './subject-preview-initializator.component.html',
})

/** Component that will be opened when a user opens a subject, this class will find the correct component to open as a dialog
 * and display the subject */

export class SubjectPreviewInitializatorComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(SubjectPreviewDirective, { static: true }) adHost!: SubjectPreviewDirective;
  @ViewChild('notifierComponent', { static: true }) notifier: NotifierComponent;
  @Output() close = new EventEmitter();

  ads: AdItem[] = [];
  interval;
  currentAdIndex = -1;
  dialogClass: ComponentType<unknown> = null;
  subjectRequest: SubjectRequestModel = null;

  /** Defined sí es interno o externo */
  subjectType: string;

  /** Define sí es entrada o salida */
  mailboxType: string;

  recipientId: number;

  module: string;

  routeSubscription: Subscription;
  private dialogRef: MatDialogRef<unknown, any>;
  subjectRequestMessage = 'UNATHORISED';

  constructor(
      public _snackBar: MatSnackBar,
      private dialog: MatDialog,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private subjectDialogDataService: SubjectDialogDataService,
      private subjectService: SubjectService,
      private subject$: Store<SubjectReducer.State>,
      private store$: Store<MailboxReducer.State>,
      private subjectRequestService: SubjectRequestService,
      public adservice: AdService,
      private host: ElementRef<HTMLElement>
  ) {
  }

  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      this.subjectDialogDataService.recipientId = parseInt(params['recipientId']); // TODO eliminar servicio
      this.subjectDialogDataService.subjectRequestId = ( parseInt(params['subjectRequestId']) > 0) ? parseInt(params['subjectRequestId']) : null; // TODO eliminar servicio

      this.recipientId =  parseInt(params['recipientId']);
      this.subjectType = params['subjectType'];
      this.mailboxType = params['mailboxType'];
      this.module = params['module']? params['module'] : SubjectViewModule.MODULE_CG;

      if ( this.recipientId && this.subjectType && this.mailboxType ) {
        this.openSubject();
        return ;
      }
      if (this.recipientId) {
        this.openSearchSubject();
      }

      this.destroyComponent();

      return false;


    });

  }

  openSearchSubject() {
    if ( this.dialogClass != null ) {
      return;
    }

    if ( this.routeSubscription ) {
      this.routeSubscription.unsubscribe();
    }

    this.dialogClass = this.adservice.getSubjectPreviewComponent(null, this.mailboxType);

    this.openDialog();

    this.getSubjectRequest();
  }

  private openSubject() {
    if ( this.dialogClass != null ) {
      return;
    }

    if ( this.routeSubscription ) {
      this.routeSubscription.unsubscribe();
    }

    this.dialogClass = this.adservice.getSubjectPreviewComponent(this.subjectType, this.mailboxType);

    this.openDialog();

    this.getSubjectRequest();
  }

  private getSubjectRequest() {
    this.subject$.dispatch(new SubjectActions.SetIsLoading(true));

    this.subjectRequestService.subjectRequest(this.subjectDialogDataService.recipientId, this.module).subscribe(response => {
        if (response.status) {
          this.subject$.dispatch(new SubjectActions.StoreCurrentSubject(response.subject));
          // this.validateRoute(response.subject);
        } else if (!response['status'] && response['auth'] == this.subjectRequestMessage) {
          this.cleanData();
          this.destroyComponent();
          this.dialogRef.close();
          this.openSnackBar(response['message']);
        } else {
          // TODO display dialog with error content
          return null;
        }

        this.subject$.dispatch(new SubjectActions.SetIsLoading(false));
      },
      error => {
        console.error(error);
        this.subject$.dispatch(new SubjectActions.SetIsLoading(false));
      });
  }

  /**
   *
   * @param subjectRequest
   */
  private validateRoute(subjectRequest: SubjectRequestModel): void {
    const organizationalUnitId = this.activatedRoute.parent.snapshot.parent.paramMap.get('organizationalUnitId');

    if (this.isInactiveSubject(subjectRequest) && this.isUrlHistoricalPath()) {
      const urlRedirect = '/panel/mailbox/' + this.getSubjectTypeFromUrl() + '/' + MAILBOX_STATUS_HISTORICAL + '/' + organizationalUnitId + '/' + subjectRequest.destinatario.id + '/' + subjectRequest.id ;
      this.router.navigate([urlRedirect], {relativeTo: this.activatedRoute});

    }
  }

  private isUrlHistoricalPath(): boolean {
    const mailboxType = this.getMailboxTypeFromUrl();
    return (mailboxType == MAILBOX_TYPE_INBOXOUT || mailboxType == MAILBOX_TYPE_INBOX);
  }

  /**
   *
   */
  private getMailboxTypeFromUrl(): string {
    return this.activatedRoute.parent.snapshot.parent.url[1].path;
  }

  /**
   *
   */
  private getSubjectTypeFromUrl(): string {
    return this.activatedRoute.parent.snapshot.parent.url[0].path;
  }

  /**
   *
   * @param subjectRequest
   */
  private isInactiveSubject(subjectRequest: SubjectRequestModel): boolean {
    return subjectRequest.status.code == SubjectStatus.CLOSED;
  }

  /**
   *
   */
  private cleanData(): void {
    this.subjectDialogDataService.subjectRequestId = null;
    this.subjectDialogDataService.recipientId = null;
    this.subject$.dispatch(new SubjectActions.ClearSubject());
  }

  private openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { subjectRequest: this.subjectRequest };
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = '1200px';
    // dialogConfig.height = '90%';
    // dialogConfig.panelClass = 'dialogClosed-class';

    this.dialogRef = this.dialog.open(this.dialogClass, dialogConfig);
    this.dialogRef.afterClosed().subscribe(result => {

      /**
       * pass through the result after close the dialog
       */
      this.subjectDialogDataService.dialogClosed.emit(result);

      // this.router.navigate(['/panel/mailbox/inboxout/nuevo/4'], { relativeTo: this.activatedRoute }); // WORKS

      this.destroyComponent();

    });
  }

  destroyComponent() {
    this.close.emit(true);
  }

  openSnackBar(message) {
    this.notifier.show({
      type: 'warning',
      message: message
    });
  }

  ngOnDestroy(): void {
    if ( this.routeSubscription ) {
      this.routeSubscription.unsubscribe();
    }

    this.cleanData();
  }

  ngAfterViewInit(): void {
  }

}
