import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { WarningConfirmationDialogComponent } from "../dialog/warning-confirmation-dialog/warning-confirmation-dialog.component";
import { AuthClass } from "../auth/Auth.class";
import { LocalstorageService } from "./Localstorage.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private dialog: MatDialog,
    private auth: AuthClass,
    private localStorage: LocalstorageService,
    private router: Router,
  ) { }

  execute() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {title: 'Precaución', textContent: 'Esta a punto de cerrar sesión. ¿Desea continuar?'};
    dialogConfig.maxWidth = '300px';
    dialogConfig.panelClass = 'dialog-confirmation-class';

    const dialog = this.dialog.open(WarningConfirmationDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(userConfirmation => {
      if (userConfirmation !== null && userConfirmation !== undefined && userConfirmation.confirmation) {
        this.auth.logout().subscribe(
          response => {
            if (response.status) {
              this.localStorage.clearSessionStorage();
              this.router.navigate(["/login"]);
            }
          },
          error => {
            console.error(error);
          }
        );
      }
    });
  }
}
