import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { ErrorMessageComponent } from '../../messages/error-message/error-message.component';
import { ViewerService } from '../../document-viewer/viewer.service';
import { UsersService } from '../../_services/users.service';
import { DocumentModel } from '../../_models/Document.model';
import { File } from '../../_helpers/file';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { SubjectDocumentService } from '../../_services/subject-document.service';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import {UserModel} from '../../_models/user.model';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-answer-documents',
  templateUrl: './answer-documents.component.html',
  styleUrls: ['./answer-documents.component.css']
})
export class AnswerDocumentsComponent implements OnInit {
  @ViewChild(ErrorMessageComponent) errorMessageComponent: ErrorMessageComponent;
  @Input() fileNameDownload: string = new Date().toISOString();
  @Input() repositoryType: string; // tasks || subject || answer
  @Input() documents: Array<DocumentModel> = [];

  loading = false;
  faAttachments = faPaperclip;
  faDownload = faDownload;

  constructor(
      private subjectDocumentServices: SubjectDocumentService,
      private viewerService: ViewerService,
      public userService: UsersService,
  ) {
  }

  ngOnInit() {
    // console.log(this.documents);
  }

  downloadAll() {
    const documentIds = [];
    const self = this;
    let fileName;

    this.documents.map(document => {
        if ( this.isDownloableDocument(document) ) {
          documentIds.push(document.id);
        }
      });

    console.log(documentIds);

    console.log('downloading...');

    if (documentIds.length > 0) {
      if (documentIds.length === 1) {
          fileName = this.documents[0].filename;
      } else {
        fileName = null;
      }
      this.download(documentIds, fileName);
    }
  }

  downloadSingleFile(document: DocumentModel) {
    if (this.isDownloableDocument(document)) {
      this.download([document.id], document.filename);
    }
  }

  download(documentIds: Array<any>, fileNameDownload) {

    const request = {
      documentIds: documentIds,
      repositoryType: this.repositoryType
    };

    if (documentIds.length > 1) {
      fileNameDownload = null;
    }

    this.subjectDocumentServices.download(request).subscribe(
      response => {
        let filename = this.fileNameDownload.replace('.', '_');
        let extension = '';

        if (documentIds.length > 1) {
          extension = 'zip';

        } else if (fileNameDownload !== null) {
          extension = fileNameDownload.split('.').pop();
          filename = fileNameDownload.replace('.' + extension, '');
        } else {
          let nameFile = '';

          for (const document of this.documents) {
            nameFile = document.filename;
          }

          extension = nameFile.split('.').pop();
          filename = nameFile.replace('.' + extension, '');
        }

        saveAs(response, filename + '.' + extension);
      },
      error => {
        if (error.status == 420) {
          this.errorMessageComponent.setErrorMessage('No tiene permisos de descarga del documento');
        } else {
          this.errorMessageComponent.setErrorMessage('Error en la descarga de documentos');
        }

      }
    );
  }

  openDocument(document) {
    this.viewerService.openDocument(this.repositoryType, document);
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //
  //     if (isNullOrUndefined(changes.documents.currentValue))
  //         return;
  //
  //     changes.documents.currentValue.forEach(function (document) {
  //         if (!isNullOrUndefined(document.config)) {
  //             let config = JSON.parse(document.config);
  //
  //             if (isObject(config)) {
  //                 document = {...document, config: config}
  //             }
  //
  //         } else {
  //             document = {...document, config: {}};
  //         }
  //     });
  //
  // }

  icon(filename: string) {
    return File.icon(filename);
  }

  canDownloadAll() {
    return this.documents.filter(doc => {
      return this.isDownloableDocument(doc);
    });
  }

  /**
   *
   * @param document
   */
  isDownloableDocument(document): boolean {
    // return (this.userService.userData.id == document.createdByUserId) ? true : (document.canDownload == true) ? true : false;
    return document.canDownload === 1 ? true : false;
  }

  userName(user: UserModel): string {
    if (user === undefined) {
      return '';
    }
    return ( user.prefix !== '' ? user.prefix : '' ) + user.name + ' ' + user.last_name
            + ' ' + ( user.mothers_last_name !== null ? user.mothers_last_name : '' );
  }
}
