import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from '../reducers/index';


export const mailboxFeature = createFeatureSelector<State>('editor');

const getMailboxtate = createSelector(mailboxFeature, state => {
    return state.mailbox
});

export const getColumnFiltersSelected = createSelector(
    getMailboxtate,
    state => {
        return state.columnFiltersSelected
    }
);

export const getMailboxSubjects = createSelector(
    getMailboxtate,
    state => {
        return state.mailboxSubjects
    }
);

export const getMailboxUnread = createSelector(
    getMailboxtate,
    (state) => {
        return state.mailboxUnread
    }
);

export const getCurrentOrganizationalUnitId = createSelector(
    getMailboxtate,
    (state) => {
        return state.currentOrganizationalUnitId;
    }
);

export const getIsLoadedUnreadOffices = createSelector(
  getMailboxtate,
  (state) => {
    return state.isLoadedUnreadOffices;
  }
);

export const getIsCurrentEditorRoute = createSelector(
  getMailboxtate,
  (state) => {
    return state.isCurrentEditorRoute;
  }
);

export const getIsCurrentEditorRouteAndIsLoadedUnreadOffices = createSelector(
  getIsCurrentEditorRoute,
  getIsLoadedUnreadOffices,
  (isCurrentEditorRoute, isLoadedUnreadOffices) => {

    if ( isCurrentEditorRoute == null || isLoadedUnreadOffices == null ) {
      return null;
    }

    return {
      isCurrentEditorRoute: isCurrentEditorRoute,
      isLoadedUnreadOffices: isLoadedUnreadOffices
    };
  }
);
