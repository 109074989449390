import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent, Dimensions, ImageTransform } from 'ngx-image-cropper';
import { UsersService } from '../../_services/users.service';

@Component({
  selector: 'app-user-profile-picture',
  templateUrl: './user-profile-picture.component.html',
  styleUrls: ['./user-profile-picture.component.css']
})
export class UserProfilePictureComponent implements OnInit {
  imageChangedEvent: any;
  croppedImage: any;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  croppedImageConvert: any;
  url: any;
  buttonDisabled: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef<UserProfilePictureComponent>,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    this.profileSetting();
  }

  profileSetting() {

    const id_user = this.data.id;

    this.userService.profileSetting(id_user).subscribe(response => {
      if (response) {
        console.log(response);

        this.userService.getProfile(this.data.id).subscribe(x => {
          console.log(x);
          const file = this.preview(x);

          this.url = file;
        });

        //console.log(JSON.parse(response.crop_setting));
        const profileSetting = JSON.parse(response.crop_setting);

        this.scale = profileSetting.scale;
        this.rotation = profileSetting.rotation;
        this.canvasRotation = profileSetting.canActivate;

        this.transform = {
          ...this.transform,
          rotate: this.rotation,
          scale: this.scale
        };
      }

    }, error => {
      console.log(error);
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const file = this.convertImage(this.croppedImage, 'profile.png');

    this.croppedImageConvert = file;
  }

  convertImage(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  zoom(e) {
    this.scale = e;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  rotationUpdate(e) {
    this.rotation = e;
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  uploadProfile() {
    this.buttonDisabled = true;
    const formData = new FormData();
    if (!this.imageChangedEvent) {
      formData.append('profile_original', 'original_profile_picture.png');
    } else {
      formData.append('profile_original', this.imageChangedEvent.target.files[0]);
    }

    const crop_setting = {
      scale: this.scale,
      rotation: this.rotation,
      canvasRotation: this.canvasRotation ? this.canvasRotation : 0,
      transform: {}
    };

    formData.append('user_id', this.data.id);
    formData.append('crop_setting', JSON.stringify(crop_setting));
    formData.append('profile', this.croppedImageConvert);

    this.userService.addUserPicture(formData).subscribe(response => {
      if (response.status) {
        this.matDialogRef.close(this.croppedImageConvert);
      }
      this.buttonDisabled = false;

    }, error => {
      console.log(error);
      this.buttonDisabled = false;
    });
  }

  deleteProfile() {
    this.buttonDisabled = true;
    const userId = { user_id: this.data.id };
    this.userService.deleteProfile(userId).subscribe(response => {
      if (response.status) {
        this.matDialogRef.close(response);
        this.buttonDisabled = false;
      }

    }, error => {
      console.log(error);
    });
  }

  preview(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }
}
