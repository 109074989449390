import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../../../environments/environment';
import * as TreeExpedientReducer from './TreeExpedient.reducer';
import * as ExpedientReducer from './Expedient.reducer';
import * as NewExpedientReducer from './NewExpedient.reducer';
import * as SubjectTransferReducer from './SubjectTransfer.reducer';
import * as GeneralReducer from './General.reducer';
import * as FormReducer from './Form.reducer';

export interface State {
    treeExpedient: TreeExpedientReducer.State;
    expedient: ExpedientReducer.State;
    newExpedient: NewExpedientReducer.State;
    subjectTransfer: SubjectTransferReducer.State;
    general: GeneralReducer.State;
    form: FormReducer.State;
}

export const reducers: ActionReducerMap<any> = {
    treeExpedient: TreeExpedientReducer.reducer,
    expedient: ExpedientReducer.reducer,
    newExpedient: NewExpedientReducer.reducer,
    subjectTransfer: SubjectTransferReducer.reducer,
    general: GeneralReducer.reducer,
    form: FormReducer.reducer,
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return function(state: State, action: any): State {
        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger, storeFreeze] : [];
