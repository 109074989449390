import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '../../components/dynamic-form/dynamic-form.component';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { SubjectService } from '../../_services/subject.service';
import { ProcessService } from '../../_services/process.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicFormBuilder } from '../../components/DynamicFormBuilder';
import { Store } from '@ngrx/store';
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import { UsersService } from '../../_services/users.service';
import * as MailboxSelector from '../../_store/selectors/mailbox.selector';
import { filter, takeUntil } from 'rxjs/operators';
import * as MailboxReducer from '../../_store/reducers/mailbox.reducer';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubjectRequestModel } from '../../_models/SubjectRequest.model';
import { SystemSettingsService } from '../../_services/system-settings.service';
import { GeneratepdfService } from '../../_services/generatepdf.service';
import { saveAs } from '@progress/kendo-file-saver';
import * as SubjectSelector from '../../_store/selectors/subject.selector';
import * as SubjectActions from '../../_store/actions/subject.actions';
import { Preview } from '../../mailboxes/Preview';
import * as NewOfficeReducer from '../../../../projects/editor/src/app/_store/reducers/new-document.reducer';
import { SubjectRequestService } from '../../_services/subject-request.service';
import { MAILBOX_HISTORY } from '../../process-tree-panel/_models/MailboxNodes';

@Component({
  selector: 'app-internal-historico-preview-dialog',
  templateUrl: './internal-historico-preview-dialog.component.html',
  styleUrls: [ './internal-historico-preview-dialog.component.css' ]
})
export class InternalHistoricoPreviewDialogComponent extends Preview implements OnInit, OnDestroy {
  @ViewChild('dynamicFormComponent', { static: true }) dynamicFields: DynamicFormComponent;

  destroy: Subject<void> = new Subject();
  mailboxType = MAILBOX_HISTORY;

  subjectRequest;
  // tasks;
  organizationalUnitId: number;
  fullMetadatos: any;
  disabled: boolean;
  showLoading$: Observable<boolean>;

  loading = false;
  faUserSecret = faUserSecret;
  // systemSettings: Object = {};

  constructor(
          private _GeneratepdfService: GeneratepdfService,
          public systemSettingsService: SystemSettingsService,
          public subjectRequestService: SubjectRequestService,
          private subjectService: SubjectService,
          private processService: ProcessService,
          public activatedRoute: ActivatedRoute,
          private formBuilder: DynamicFormBuilder,
          public dialogRef: MatDialogRef<InternalHistoricoPreviewDialogComponent>,
          public dialog: MatDialog,
          public subject$: Store<SubjectReducer.State>,
          public router: Router,
          public userService: UsersService,
          private store$: Store<MailboxReducer.State>,
          public newOffice$: Store<NewOfficeReducer.State>,
          @Inject(MAT_DIALOG_DATA) public subject
  ) {
    super(systemSettingsService, router, activatedRoute, dialogRef, newOffice$, subject$, dialog, userService, subjectRequestService);
  }

  ngOnInit() {
    this.buildSubjectForm();

    this.store$.select(MailboxSelector.getCurrentOrganizationalUnitId)
    .pipe(
            takeUntil(this.destroy),
            filter(data => data !== null)
    )
    .subscribe(
            response => {
              this.organizationalUnitId = response;
            }
    );
    this.showLoading$ = this.subject$.select(SubjectSelector.getIsLoadingPDF);
    this.isLoading$ = this.subject$.select(SubjectSelector.getIsLoading);

    this.subject$.select(SubjectSelector.getIsLoadingFlowChart).pipe(takeUntil(this.destroy),
            filter(data => data !== null)).subscribe(loading => {
      if (loading) {
        this.scrollToElement();
      }
    });
  }

  buildSubjectForm() {
    this.subjectService.getCurrentSubject().pipe(
            filter(data => data != null),
            takeUntil(this.destroy)
    ).subscribe(
            subjectRequest => {
              this.subjectRequest = subjectRequest;
              this.mapFieldsAndBuildForm(subjectRequest);
            },
            error => {
              console.error(error);
            }
    );
  }

  mapFieldsAndBuildForm(subjectRequest: SubjectRequestModel) {
    const metadata = [];
    const metadataValue = subjectRequest.metadataValue;

    this.subjectRequest.process.metadata.forEach(function (field, index) {
      const metadataFieldValue = metadataValue.find(metadataValueField => metadataValueField.metadata_id === field.id);

      if (metadataFieldValue === undefined || metadataFieldValue === null) {
        return;
      }

      field = { ...field, value: metadataFieldValue.value };

      metadata.push(field);
    });

    this.fullMetadatos = metadata;

    this.dynamicFields.createControls(metadata);
    this.dynamicFields.disableForm();
  }

  openSubjectAnswerDialog() {
  }

  showTasksTab(): boolean {
    if (!this.subjectRequest || this.subjectRequest.tasks == null || this.organizationalUnitId === undefined) {
      return false;
    }

    if (this.userService.isTitular(this.organizationalUnitId) || this.userService.isAsistente(this.organizationalUnitId)) {
      return this.subjectRequest.tasks.length > 0;
    } else {
      return this.subjectRequest.tasks.filter(
              task => task.userAssigned !== null && task.userAssigned.id === this.userService.userData.id).length > 0;
    }
  }

  canAddAttachments(): boolean {
    if (!this.subjectRequest) {
      return false;
    }

    return this.subjectRequest.destinatario.isSender === true;
  }

  generatePdf() {
    this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(true));
    const params = {
      title: [ this.subjectRequest.title ],
      subjectRequest: this.subjectRequest,
      fullMetadata: this.fullMetadatos,
    };
    const nameFile = 'Flow - ' + this.subjectRequest.folio;
    this._GeneratepdfService.subjectReport(params).subscribe(response => {
      saveAs(response, nameFile);
      this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(false));
    }, error => {
      this.errorMessage.setErrorMessage(error);
      this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(true));
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  avanzar() {
  }

  finalizarAsunto() {
  }

  marcarResuelto(recipient) {
  }

}
