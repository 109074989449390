import { Action } from '@ngrx/store';
import { NotificationInterface } from '../../_models/notification.interface';

export const MARK_ALL_NOTIFICATIONS_AS_OPEN = '[OfficeNotifications] Mark All Notifications As Open';
export const MARK_AS_OPEN = '[OfficeNotifications] Mark as open';
export const STORE_NOTIFICATIONS = '[OfficeNotifications] Store Notifications';
export const REMOVE_NOTIFICATION = '[OfficeNotifications] Remove Notification';

export class StoreNotifications implements Action {
  readonly type = STORE_NOTIFICATIONS;

  constructor(public notifications: Array<NotificationInterface> ) {}
}

export class MarkAllNotificationsAsOpen implements Action {
  readonly type = MARK_ALL_NOTIFICATIONS_AS_OPEN;
  constructor() {}
}

export class MarkNotificationAsOpen implements Action {
  readonly type = MARK_AS_OPEN;
  constructor(public notficationId: number) {}
}

export class RemoveNotificationNumber implements Action {
  readonly type = REMOVE_NOTIFICATION;
  constructor(public notificationId: number) {
  }
}

export type Actions =
  | MarkAllNotificationsAsOpen
  | StoreNotifications
  | RemoveNotificationNumber
  | MarkNotificationAsOpen;
