import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  faBan, faFilePdf, faReplyAll, faUserSecret,
  faCheckCircle, faReply, faCalendarCheck, faFileAlt, faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { Preview } from '../../mailboxes/Preview';
import { SystemSettingsService } from '../../_services/system-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { InboxPreviewDialogComponent } from '../inbox-preview/inbox-preview-dialog.component';
import { Store } from '@ngrx/store';
import { UsersService } from '../../_services/users.service';
import { SubjectRequestService } from '../../_services/subject-request.service';
import { SubjectRequestModel } from '../../_models/SubjectRequest.model';
import { SubjectDialogDataService } from '../../_services/subject-dialog-data.service';
import { SubjectService } from '../../_services/subject.service';
import { Subject } from 'rxjs';
import { CLOSED } from '../../_constants/SubjectStatus.constants';
import * as NewOfficeReducer from '../../../../projects/editor/src/app/_store/reducers/new-document.reducer';
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import * as MailboxSelector from '../../_store/selectors/mailbox.selector';
import * as SubjectSelector from '../../_store/selectors/subject.selector';
import * as SubjectSelect from '../../_store/selectors/subject.selector';
import * as MailboxReducer from '../../_store/reducers/mailbox.reducer';
import * as SubjectActions from '../../_store/actions/subject.actions';
import { filter, takeUntil } from 'rxjs/operators';
import { saveAs } from '@progress/kendo-file-saver';
import { isObject } from 'rxjs/internal-compatibility';
import { GeneratepdfService } from '../../_services/generatepdf.service';
import { AddAttachmentsSubjectComponent } from '../../mailboxes/add-attachments-subject/add-attachments-subject.component';
import { SubjectAnswerDialogComponent } from '../../share-components/subject-answer/subject-answer-dialog.component';
import { SolveSubjectComponent } from '../../mailboxes/solve-subject/solve-subject.component';
import { AvanzarDialogComponent } from '../../share-components/avanzar/avanzar-dialog.component';
import { RejectSubjectComponent } from '../../mailboxes/reject-subject/reject-subject.component';
import { WarningConfirmationDialogComponent } from '../../dialog/warning-confirmation-dialog/warning-confirmation-dialog.component';


@Component({
  selector: 'app-subject-buttons-action',
  templateUrl: './subject-buttons-action.component.html',
  styleUrls: ['./subject-buttons-action.component.css']
})
export class SubjectButtonsActionComponent extends Preview implements OnInit, OnDestroy {
  subjectRequest: SubjectRequestModel;
  destroy: Subject<void> = new Subject();

  faAnswer = faReply;
  faCancel = faBan;
  faFinish = faCalendarCheck;
  faResolver = faCheckCircle;
  faUserSecret = faUserSecret;
  icoPdf = faFilePdf;
  faTurn = faReplyAll;
  officeIcon = faFileAlt;
  faReject = faWindowClose;
  allowByOrg = true;
  fullMetadata: any[];
  canRejectSubjectStatus: boolean;
  allowCancelSubject: boolean;
  closedSubjectRequest: boolean;
  constructor(
    private _GeneratePdfService: GeneratepdfService,
    public systemSettingsService: SystemSettingsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<InboxPreviewDialogComponent>,
    public newOffice$: Store<NewOfficeReducer.State>,
    public subject$: Store<SubjectReducer.State>,
    public dialog: MatDialog,
    private store$: Store<MailboxReducer.State>,
    public userService: UsersService,
    public subjectRequestService: SubjectRequestService,
    private subjectDialogDataService: SubjectDialogDataService,
    private subjectService: SubjectService,
  ) {
    super(systemSettingsService, router, activatedRoute, dialogRef, newOffice$, subject$, dialog, userService, subjectRequestService);
  }

  ngOnInit(): void {
    this.store$.select(MailboxSelector.getCurrentOrganizationalUnitId)
      .pipe((takeUntil(this.destroy)),
        filter(response => response !== null && response > 0))
      .subscribe(
        response => {
          this.organizationalUnitId = response;
        }
      );
    this.buildSubjectForm();
    this.mailboxButtonValidator();
    this.cancelSubjectAllowed();
  }

  buildSubjectForm() {
    const addresseeId = this.subjectDialogDataService.recipientId;
    if (!(addresseeId > 0)) {
      return 0;
    }

    this.loading = true;

    this.subjectService.getCurrentSubject()
      .pipe(
        takeUntil(this.destroy),
        filter(data => data !== null && data !== undefined)
      )
      .subscribe(subjectRequest => {
          this.subjectRequest = subjectRequest;
          this.mapFieldsAndBuildForm(subjectRequest);
        },
        error => {
          this.loading = false;
          console.error(error);
        }
      );
    this.canRejectSubject();
  }

  mapFieldsAndBuildForm(subjectRequest: SubjectRequestModel) {
    const metadata = [];
    const metadataValue = subjectRequest.metadataValue;

    this.subjectRequest.process.metadata.forEach(function (field) {
      const metadataFieldValue = metadataValue.find(metadataValueField => metadataValueField.metadata_id === field.id);

      if (metadataFieldValue === null || metadataFieldValue === undefined) {
        return;
      }

      field = {...field, value: metadataFieldValue.value};
      metadata.push(field);
    });
    this.fullMetadata = metadata;
    this.closedSubjectRequest = this.subjectRequest.status.code === CLOSED;
  }

  cancelSubjectAllowed() {
    this.subject$.select(SubjectSelect.getSubjectRecipients)
      .pipe(
        takeUntil(this.destroy),
      ).subscribe(recipients => {
      if (recipients === null) {
        this.allowCancelSubject = false;
      } else {

        const isSender = recipients.some(recipient => recipient.step == 1
          && recipient.user_id == this.userService.userData.id && recipient.isSender == true);

        const subjectOpen = recipients.some(recipient => recipient.last_seen != null
          && recipient.user_id != this.userService.userData.id);

        this.allowCancelSubject = isSender == true && subjectOpen == false;
      }

    });
  }

  canRejectSubject() {
    this.subject$.select(SubjectSelector.getCurrentSubject)
      .pipe(
        takeUntil(this.destroy),
        filter(data => data !== null))
      .subscribe(subjectRequest => {
        this.organizationalUnitId = subjectRequest.recipient.organizationalUnit_id;
        let allow = true;

        if (subjectRequest.recipient.rejected) {
          allow = false;
        }

        const subjectAnswers = subjectRequest.answers.filter(answer => {
          if (this.userService.isAsistente(this.organizationalUnitId) || this.userService.isTitular(this.organizationalUnitId)) {
            return answer.organizationalUnitId === this.organizationalUnitId &&
              answer.step === subjectRequest.destinatario.step;
          } else {
            return answer.organizationalUnitId === this.organizationalUnitId &&
              answer.step === subjectRequest.destinatario.step;
          }
        });

        const advancedSubject = subjectRequest.recipients.filter(recipient => {
          if (this.userService.isAsistente(this.organizationalUnitId) || this.userService.isTitular(this.organizationalUnitId)) {
            return recipient.step > subjectRequest.destinatario.step && recipient.sender_organizationalUnit_id === this.organizationalUnitId;
          } else {
            return recipient.step < subjectRequest.destinatario.step
              && recipient.sender_organizationalUnit_id === this.organizationalUnitId
              && recipient.sender_user_id === this.userService.userData.id;
          }
        });

        const tasks = subjectRequest.tasks.filter(task => {
          if (this.userService.isAsistente(this.organizationalUnitId) || this.userService.isTitular(this.organizationalUnitId)) {
            return task.organizationalUnit_id === this.organizationalUnitId && (task.answers.length > 0 || task.completed || task.userAssigned !== null);
          } else {
            return task.organizationalUnit_id === this.organizationalUnitId && task.user_id === this.userService.userData.id && (task.answers.length > 0 || task.completed);
          }
        });

        if (advancedSubject.length > 0 || subjectAnswers.length > 0 || tasks.length > 0) {
          allow = false;
        }

        this.canRejectSubjectStatus = allow;
      });

  }

  generatePdf() {
    this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(true));
    const params = {
      title: [this.subjectRequest.title],
      subjectRequest: this.subjectRequest,
      fullMetadata: this.fullMetadata,
    };
    const nameFile = 'Flow - ' + this.subjectRequest.folio;
    this._GeneratePdfService.subjectReport(params).subscribe(response => {
      saveAs(response, nameFile);
      this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(false));
    }, error => {
      this.errorMessage.setErrorMessage(error);
      this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(true));
    });
  }

  attachDocumentsToSubject() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.subjectRequest;
    dialogConfig.maxWidth = '1000px';
    dialogConfig.width = '800px';

    const dialog = this.dialog.open(AddAttachmentsSubjectComponent, dialogConfig);

    dialog.afterClosed().subscribe(answer => {
      if (isObject(answer)) {

      }
    });
  }

  rejectSubject(recipient) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Confirmación',
      textContent: '¿Realmente desea rechazar el asunto? el emisor recibirá una notificación sobre esta acción.',
      recipient: recipient
    };
    dialogConfig.maxWidth = '500px';
    dialogConfig.maxHeight = '500px';
    dialogConfig.panelClass = 'dialog-confirmation-class';

    const dialog = this.dialog.open(RejectSubjectComponent, dialogConfig);

    dialog.afterClosed().subscribe(userConfirmation => {
      if (userConfirmation !== null && userConfirmation !== undefined && userConfirmation.confirmation) {
        this.dialogRef.close({
          'action': 'rejected',
        });
      }
    });
  }

  cancelSubject(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {title: 'Cancelar asunto', textContent: 'Esta a punto de cancelar el asunto ¿Desea continuar?'};
    dialogConfig.maxWidth = '300px';
    dialogConfig.panelClass = 'dialog-confirmation-class';

    const dialog = this.dialog.open(WarningConfirmationDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(userConfirmation => {
      if (userConfirmation !== undefined && userConfirmation !== null && userConfirmation.confirmation) {
        this.subjectService.cancel(this.subjectRequest.destinatario.id).subscribe(response => {
          if (response['status']) {
            this.dialogRef.close({action: 'cancelSubject'});
          } else {
            this.errorMessage.setErrorMessage(response);
          }
        }, error => {
          console.error(error);
          this.errorMessage.setErrorMessage(error);
        });
      }
    });
  }

  avanzar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {subjectRequest: this.subjectRequest, organizationalUnitId: this.organizationalUnitId};
    dialogConfig.maxWidth = '800px';
    dialogConfig.width = '800px';

    const dialog = this.dialog.open(AvanzarDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(result => {
      if (isObject(result)) {
        this.notifier.show({
          message: 'Asunto avanzado',
          type: 'success'
        });
      }
    });
  }

  finalizarAsunto() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {title: 'Finalizar Asunto', textContent: 'Esta a punto de finalizar el asunto ¿Desea continuar?'};
    dialogConfig.maxWidth = '300px';
    dialogConfig.panelClass = 'dialog-confirmation-class';

    const dialog = this.dialog.open(WarningConfirmationDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(userConfirmation => {
      if (userConfirmation !== null && userConfirmation !== undefined && userConfirmation.confirmation) {
        this.subjectService.finalizarAsunto(this.subjectRequest.id, this.organizationalUnitId).subscribe(
          response => {
            if (response['status']) {
              this.dialogRef.close({
                'action': 'asuntoFinalizado',
              });
            } else {
              this.errorMessage.setErrorMessage(response);
            }
          },
          error => {
            this.errorMessage.setErrorMessage(error);
          }
        );
      }
    });
  }

  marcarResuelto(recipient) {
    // if (this.mailboxType == MAILBOX_INBOX_CODE_EXTERNAL) {
    //   this.markResueltoExternal(recipient);
    // } else {
    this.markResueltoInternal(recipient);
    // }
  }

  markResueltoExternal(recipient) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {title: 'Confirmación', textContent: '¿Desea marcar como Resuelto el asunto?'};
    dialogConfig.maxWidth = '300px';
    dialogConfig.panelClass = 'dialog-confirmation-class';

    const dialog = this.dialog.open(WarningConfirmationDialogComponent, dialogConfig);

    const status = 1;

    dialog.afterClosed().subscribe(userConfirmation => {
      if (userConfirmation !== undefined && userConfirmation !== null && userConfirmation.confirmation) {
        this.subjectService.marcarResuelto(recipient.id, status).subscribe(
          response => {
            if (response['status']) {

              recipient = {...recipient, resuelto: true};

              this.dialogRef.close({
                'action': 'asuntoResuelto',
              });

            } else {
              this.errorMessage.setErrorMessage(response);
            }
          },
          error => {
            this.errorMessage.setErrorMessage(error);
          }
        );
      }
    });
  }

  markResueltoInternal(recipient) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Confirmación',
      textContent: '¿Desea marcar como Resuelto el asunto?',
      recipient: recipient
    };
    dialogConfig.maxWidth = '500px';
    dialogConfig.panelClass = 'dialog-confirmation-class';

    const dialog = this.dialog.open(SolveSubjectComponent, dialogConfig);
    dialog.afterClosed().subscribe(response => {
      if (response.status) {
        this.subjectRequest = {...this.subjectRequest, destinatario: {...this.subjectRequest.destinatario, resuelto: true}};

        this.dialogRef.close({
          'action': 'asuntoResuelto',
        });
      }

    }, error => {
      console.log(error);
    });
  }

  participants(subjectRequest) {
    if (subjectRequest) {
      /* const self = this;
      const userOUs = this.userService.userData.organizationalUnits;
      userOUs.forEach(function (org) {
        const organizationalUnit_id = subjectRequest.recipient.organizationalUnit_id == org.id;
        if (organizationalUnit_id) {
          self.allowByOrg = false;
        }
      });
      return this.allowByOrg; */

      const userOUs = this.userService.userData.id;

      const participant = subjectRequest.recipients.find(x => x.user_id === userOUs);
      this.allowByOrg = !!participant;

      return this.allowByOrg;

    }
    return false;
  }

  openSubjectAnswerDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.subjectRequest;
    dialogConfig.maxWidth = '1000px';
    dialogConfig.width = '800px';

    const dialog = this.dialog.open(SubjectAnswerDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(answer => {
    });

  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
