import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faUser, faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { SystemInitService } from '../system-init/SystemInitService';
import { UsersService } from '../_services/users.service';
import * as ProcessActions from '../_store/actions/process.actions';
import { Store} from '@ngrx/store';
import * as ProcessReducer from '../_store/reducers/process.reducer';
import * as NotificationReducer from '../_store/reducers/notification.reducer';
import * as EditorNotificationReducer from '../../../projects/editor/src/app/_store/reducers/notification.reducer';
import * as EditorNotificationSelector from '../../../projects/editor/src/app/_store/selectors/notification.selector';
import { ProcessService } from '../_services/process.service';
import { Router } from '@angular/router';
import * as ProcessSelector from '../_store/selectors/process.selector';
import * as NotificationSelector from '../_store/selectors/notification.selector';
import { takeUntil } from 'rxjs/operators';
import { OrganizationalUnitModel } from '../_models/OrganizationalUnit.model';
import { Observable, Subject } from 'rxjs';
import { SystemSettingsService } from '../_services/system-settings.service';
import { ProcessModel } from '../_models/process.model';
import { UserModel } from '../_models/user.model';
import { LogoutService } from '../_services/logout.service';
import * as MailboxSelector from '../_store/selectors/mailbox.selector';
import { ViewerService } from '../document-viewer/viewer.service';

export interface userProfile {
    name: string;
    lastName: string;
}

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit, OnDestroy, AfterViewChecked {
    faBars = faBars;
    showPanel: boolean;
    windowWidth: number;
    panelMode: string;
    hasBackdrop: boolean;
    userData: UserModel;
    faSignOut = faSignOutAlt;
    organizationalUnitsRole: Array<any>;
    processes: ProcessModel[];
    organizationalUnit: Array<OrganizationalUnitModel> = [];
    destroy: Subject<void> = new Subject();
    systemSettings: Object = {};
    totalNewNotifications: Observable<any>;
    totalNewEditorNotifications: Observable<any>;
    showEditorOption = false;
    isTitular: boolean;
    isAsistente: boolean;
    showLoading$: Observable<boolean>;

    constructor(
        private title: Title,
        public userService: UsersService,
        private store$: Store<ProcessReducer.State>,
        private notification$: Store<NotificationReducer.State>,
        private editorNotification$: Store<EditorNotificationReducer.State>,
        private processService: ProcessService,
        private systemInitService: SystemInitService,
        private router: Router,
        private process$: Store<ProcessReducer.State>,
        public systemSettingsService: SystemSettingsService,
        private logoutService: LogoutService,
        public viewerService: ViewerService,
        private changeDetector: ChangeDetectorRef,
    ) {
        this.onResize(null);
    }

    ngOnInit() {
        this.systemSettings = this.systemSettingsService.getLoginSettings();

        this.showEditorOption = this.systemSettingsService.isEditorActive();

        this.showPanel = true;

        this.title.setTitle('Control de Gestión');

        this.systemInitService.init();

        this.setOpenStatus();

        this.userData = this.getUserData();

        this.setUserData();

        this.getProcesses();

        this.totalNewNotifications = this.notification$.select(NotificationSelector.getTotalUnreadNotifications);

        this.totalNewEditorNotifications = this.editorNotification$.select(EditorNotificationSelector.getTotalUnreadNotifications);

        this.showLoading$ = this.store$.select(MailboxSelector.isLoading);
    }


  @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.windowWidth = window.innerWidth;

        if (this.windowWidth > 978) {
            this.panelMode = 'side';
            this.hasBackdrop = false;
        } else {
            this.panelMode = 'over';
            this.hasBackdrop = true;
        }

    }

    setOpenStatus() {
        this.showPanel = (window.innerWidth > 1200) ? true : false;
    }

    getUserData() {
        return this.userService.userData;
    }

    setUserData() {
        if (this.userData === null) {
            return;
        }

        this.organizationalUnitsRole = this.userData.organizationalUnits;
    }

    closeStart() {
        this.showPanel = false;
    }

    openStart() {
        this.showPanel = true;

    }

    closed() {

    }

    opened() {

    }

    ngAfterViewInit(): void {

    }

    getProcesses() {
        this.process$.select(ProcessSelector.getProcesses)
            .pipe(takeUntil(this.destroy))
            .subscribe(processes => {
                if (processes !== null) {
                    this.processes = processes;
                    this.organizationalUnit = this.userService.userData.organizationalUnits as Array<OrganizationalUnitModel>;
                }
            });

        this.processService.getProcessesWithMetadata().subscribe(
            response => {
                if (response['status']) {
                    this.store$.dispatch(new ProcessActions.AddProcessesActions(response['processes']));
                } else {
                    console.error(response);
                }
            },
            error => {
                console.error(error);
            }
        );
    }

    public gotToArchival() {
        const userData = this.userService.userData;
        const firstId = userData.organizationalUnits.shift()?.id;
        if (firstId !== undefined) {
            this.router.navigate([ '/archivistica/subject/' + firstId ]);
        }
    }

    logout() {
        this.logoutService.execute();
    }

    ngOnDestroy(): void {
        this.destroy.next();
    }

    ngAfterViewChecked(): void {
        this.changeDetector.detectChanges();
    }
}
