import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";
import * as MailboxReducer from "../_store/reducers/mailbox.reducer";
import * as MailboxAction from "../_store/actions/mailbox.actions";


/**
 *  Guard utilizado para determinar si el usuario se encuentra parado sobre el editor, se cambia un flag en true en caso
 *  de ser verdadero y false en caso contrario.
 */

@Injectable({
  providedIn: 'root'
})
export class EditorRouteGuard implements CanActivate, CanDeactivate<unknown> {

  constructor(
    public mailbox$: Store<MailboxReducer.State>,
    ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if ( this.isEditorRoute(next)) {
      this.mailbox$.dispatch(new MailboxAction.SetIsCurrentEditorRoute(true));
    } else {
      this.mailbox$.dispatch(new MailboxAction.SetIsCurrentEditorRoute(false));
    }

    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if ( this.isEditorRouteDeactivate(nextState)) {

      this.mailbox$.dispatch(new MailboxAction.SetIsCurrentEditorRoute(true));
    } else {
      this.mailbox$.dispatch(new MailboxAction.SetIsCurrentEditorRoute(false));
    }

    return true;
  }

  private isEditorRouteDeactivate(nextState: RouterStateSnapshot): boolean {
    const routes: Array<string> = nextState.root.url.join().split(',');
    const isEditorRoute = routes.some(route => route == 'editor');

    return isEditorRoute;
  }

  private isEditorRoute(nextState: ActivatedRouteSnapshot): boolean {
    const routes: Array<string> = nextState.url.join().split(',');
    const isEditorRoute = routes.some(route => route == 'editor');
    return isEditorRoute;
  }

}
