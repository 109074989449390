import { Action } from '@ngrx/store';

export const STORE_STRUCTURE_FORM_UNIFIED = '[New Expedient Data] Store StructureFormUnified';
export const STORE_EXPEDIENT_METADATA = '[New Expedient Data] Store ExpedientMetaData';
export const STORE_SERIES_METADATA = '[New Expedient Data] Store SeriesMetaData';
export const STORE_TOPOGRAPHY = '[New Expedient Data] Store Topography';
export const STORE_SERIES = '[New Expedient Data] Store Series';
export const STORE_ORGANIZATIONAL_UNITS = '[New Expedient Data] Store OrganizationalUnit';
export const CLEAR_ALL = '[New Expedient Data] Clear all';

export class StoreStructureFormUnified implements Action {
  readonly type = STORE_STRUCTURE_FORM_UNIFIED;

  constructor ( public payload ) {
  }
}

export class StoreExpedientMetaData implements Action {
  readonly type = STORE_EXPEDIENT_METADATA;

  constructor ( public payload ) {
  }
}

export class StoreSeriesMetaData implements Action {
  readonly type = STORE_SERIES_METADATA;

  constructor ( public payload ) {
  }
}

export class StoreTopography implements Action {
  readonly type = STORE_TOPOGRAPHY;

  constructor ( public payload ) {
  }
}

export class StoreSeries implements Action {
  readonly type = STORE_SERIES;

  constructor ( public payload ) {
  }
}

export class StoreOrganizationalUnit implements Action {
  readonly type = STORE_ORGANIZATIONAL_UNITS;

  constructor ( public payload ) {
  }
}

export class ClearDataAction implements Action {
  readonly type = CLEAR_ALL;

  constructor () {
  }
}

export type Actions
= StoreStructureFormUnified
| StoreExpedientMetaData
| StoreSeriesMetaData
| StoreTopography
| StoreSeries
| StoreOrganizationalUnit
| ClearDataAction;
