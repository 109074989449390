import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Helper } from "../_helpers/Helper";
import { environment } from "../../environments/environment";
import {SubjectRequestModel} from "../_models/SubjectRequest.model";
import * as SubjectViewModule from 'src/app/_constants/SubjectViewModuleConstants';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};
@Injectable({
  providedIn: 'root'
})
export class SubjectRequestService {

    constructor(private http: HttpClient, helper: Helper) {
    }

    url = environment.apiUrl + '/api/subjectRequest';

    /**
     *
     * @returns {Observable<any>}
     */
    public subjectRequest(addresseeId, fromModule = SubjectViewModule.MODULE_CG): Observable<any> {
      let httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        params: { "module" : fromModule }
      };
        return this.http.get(this.url + '/' + addresseeId, httpOptions);
    }

    /**
     *
     * @param subjectRequestId
     */
    public getSubjectFlow(subjectRequestId): Observable<any> {
        return this.http.get(this.url + '/subjectFlow/' + subjectRequestId);
    }
}
