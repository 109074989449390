import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "../reducers/index";


export const mailboxFeature = createFeatureSelector<State>('newSubject');

const getMailboxtate = createSelector(mailboxFeature, state => {
    return state.mailbox
});

export const getColumnFiltersSelected = createSelector(
    getMailboxtate,
    state => {
        return state.columnFiltersSelected;
    }
);

export const getMailboxSubjects = createSelector(
    getMailboxtate,
    state => {
        return state.mailboxSubjects
    }
);

export const getMailboxUnread = createSelector(
    getMailboxtate,
    (state) => {
        return state.mailboxUnread
    }
);

export const getCurrentOrganizationalUnitId = createSelector(
    getMailboxtate,
    (state) => {
        return state.currentOrganizationalUnitId;
    }
);

export const getSubjectType = createSelector(
  getMailboxtate,
  (state) => {
    return state.subjectType;
  }
);

export const getLastAccess = createSelector(
  getMailboxtate,
  (state) => {
    return state.lastAccess;
  }
);

export const getSubjectTypeCode = createSelector(
  getMailboxtate,
  (state) => {
    return state.subjectTypeCode;
  }
);

export const getMailboxTypeCode = createSelector(
  getMailboxtate,
  (state) => {
    return state.mailboxTypeCode;
  }
);

export const isLoading = createSelector(
  getMailboxtate,
  state => {
    return state.isLoading;
  }
);

export const GetPage = createSelector(
  getMailboxtate,
  state => {
      return {...state.mailboxSubjects, data : [] };
  }
);
