import { Component, Input, OnInit } from '@angular/core';
import { OfficeNotificationService } from '../../_services/office-notification.service';
import { NotificationInterface } from "../../_models/notification.interface";
import { Store } from "@ngrx/store";
import * as NotificationReducer from '../../_store/reducers/notification.reducer';
import * as NotificationAction from '../../_store/actions/notification.action';
import { RoutingService } from '../../_services/routing.service';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import * as MailboxReducer from '../../_store/reducers/mailbox.reducer';
import { filter, take } from "rxjs/operators";
import * as MailboxActions from "../../_store/actions/mailbox.actions";
import { CatMailboxConstants } from "../../_constants/CatMailboxStatusConstants";
import { getIsCurrentEditorRouteAndIsLoadedUnreadOffices } from "../../_store/selectors/mailbox.selector";

@Component({
  selector: 'app-office-row',
  templateUrl: './editor-row.component.html',
  styleUrls: ['./editor-row.component.css']
})
export class EditorRowComponent implements OnInit {
  @Input() notification: NotificationInterface;

  faTrash = faTrashAlt;
  faEye = faEye;

  constructor(
    private officeNotificationService: OfficeNotificationService,
    private notification$: Store<NotificationReducer.State>,
    private routerService: RoutingService,
    public store$: Store<MailboxReducer.State>,
  ) { }

  ngOnInit(): void {
  }

  private markAsOpen($event, notification: NotificationInterface): void {
    $event.stopPropagation();
    this.markAsOpenAction(notification);
  }
  /**
   *
   * @param notification
   * @private
   */
  private markAsOpenAction(notification: NotificationInterface): void {
    if (notification.open)
      return;

    this.notification$.dispatch(new NotificationAction.MarkNotificationAsOpen(notification.id));

     this.officeNotificationService.markAsOpen(notification.id).subscribe(
      response => {
        if (response['status']) {

        } else {
        console.error(response);
        }
      }, error => {
        console.error(error);
      }
    );
  }

  /**
   *
   * @param $event
   * @param matMenu
   * @private
   */
  private removeNotification($event, notification: NotificationInterface): void {
    $event.stopPropagation();

    this.notification$.dispatch(new NotificationAction.RemoveNotificationNumber(notification.id));

    this.officeNotificationService.remove(notification.id).subscribe(
      response => {
        if (response['status']) {

        } else {
          console.error(response);
        }
      }, error => {
        console.error(error);
      }
    );
  }

  /**
   * defines the action to execute when users click on the row notification
   * @param notification
   */
  private clickNotification(notification: NotificationInterface): void {
    if (notification.office_id !== null) {
      const participant = notification.office.participants.find(element => element.recipient_user_id === notification.user_id);

      this.store$.select(getIsCurrentEditorRouteAndIsLoadedUnreadOffices)
        .pipe(take(1), filter(data => data != null))
        .subscribe(currentMailboxAndOffices => {
          /** Cuando se esta parado sobre un  */
          if (!participant.was_read) {
            this.markAsOpenAction(notification);

            if ( currentMailboxAndOffices.isCurrentEditorRoute ) {
              this.store$.dispatch(new MailboxActions.SubtractUnreadMailbox(notification.mailboxType, CatMailboxConstants.NEW, notification.organizationalUnitId, notification.office.id));
            }
          }

          this.routerService.openOffice(notification.organizationalUnitId, notification.recipientId);
        });
    } else {
      this.markAsOpenAction(notification);
    }
  }
}
