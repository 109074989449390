import * as NewExpedientAction from '../actions/NewExpedient.actions';

export interface State {
  structureFormUnified: any;
  expedientMetaData: any;
  series: any;
  seriesMetaData: any;
  organizationalUnit: any;
  topography: number | any;
}

const initialState: State = {
  structureFormUnified: [],
  expedientMetaData: [],
  seriesMetaData: [],
  topography: 0,
  series: null,
  organizationalUnit: null,
};

export function reducer(state = initialState, action: NewExpedientAction.Actions): any {

  switch (action.type) {
    case NewExpedientAction.CLEAR_ALL:
      return [];
    case NewExpedientAction.STORE_STRUCTURE_FORM_UNIFIED:
      return {...state, structureFormUnified: action.payload};
    case NewExpedientAction.STORE_EXPEDIENT_METADATA:
      return {...state, expedientMetaData: action.payload};
    case NewExpedientAction.STORE_SERIES_METADATA:
      return {...state, seriesMetaData: action.payload};
    case NewExpedientAction.STORE_TOPOGRAPHY:
      return {...state, topography: action.payload};
    case NewExpedientAction.STORE_SERIES:
      return {...state, series: action.payload};
    case NewExpedientAction.STORE_ORGANIZATIONAL_UNITS:
      return {...state, organizationalUnit: action.payload};
    default:
      return state;
  }
}
