export const OPEN = 'open';
export const CLOSED = 'closed';
export const RECHAZADO = 'rechazado';
export const FINALIZADO = 'finalizado';
export const AVANZADO = 'avanzado';
export const NUEVO = 'nuevo';
export const CANCELLED = 'cancelado';
export const INPROGRESS = 'inprogress';
export const VALIDATING_OFFICE_PREVIEW = 'validating_office_preview';
export const ARCHIVED = 'archivado';
