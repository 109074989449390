import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { SubjectNotificationService } from '../../_services/subject-notification.service';
import { NotificationInterface } from '../../_models/notification.interface';
import { Store } from '@ngrx/store';
import * as NotificationReducer from '../../_store/reducers/notification.reducer';
import * as NotificationAction from '../../_store/actions/notification.action';
import { faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SubjectPreviewDirective } from '../../subject/subject-preview.directive';
import { SUBJECT_FINISHED } from '../../_constants/NotificationAction.constants';
import * as MailboxConstants from '../../_constants/MailboxConstants';

@Component({
  selector: 'app-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.css']
})
export class RowComponent implements OnInit {
    @Input() notification: NotificationInterface;
    @ViewChild(SubjectPreviewDirective, { static: true }) adHost!: SubjectPreviewDirective;

    faTrash = faTrashAlt;
    faEye = faEye;

    constructor(
            public activatedRoute: ActivatedRoute,
            public _componentFactoryResolver: ComponentFactoryResolver,
            private subjectNotificationService: SubjectNotificationService,
            private notification$: Store<NotificationReducer.State>,
            private router: Router
    ) { }

    ngOnInit(): void {
    }

    markAsOpen($event, notification: NotificationInterface): void {
        $event.stopPropagation();
        this.markAsOpenAction(notification);
    }

    /**
     *
     * @param notification
     * @private
     */
    private markAsOpenAction(notification: NotificationInterface): void {
        if (notification.open) {
            return;
        }

        this.notification$.dispatch(new NotificationAction.MarkNotificationAsOpen(notification.id));

        this.subjectNotificationService.markAsOpen(notification.id).subscribe(
                response => {
                    if (response['status']) {

                    } else {
                        console.error(response);
                    }
                }, error => {
                    console.error(error);
                }
        );
    }

    /**
     *
     * @param $event
     * @param notification
     * @private
     */
    removeNotification($event, notification: NotificationInterface): void {
        $event.stopPropagation();

        this.notification$.dispatch(new NotificationAction.RemoveNotificationNumber(notification.id));

        this.subjectNotificationService.remove(notification.id).subscribe(
                response => {
                    if (response['status']) {

                    } else {
                        console.error(response);
                    }
                }, error => {
                    console.error(error);
                }
        );
    }

    /**
     * defines the action to execute when users click on the row notification
     * @param notification
     */
    clickNotification(notification: NotificationInterface): void {
        const urlSubjectOpen = '/panel/mailbox/' + notification.subjectType + '/' + notification.mailboxType
                + '/' + notification.mailboxStatus + '/' + notification.organizationalUnitId + '/' + notification.recipientId;

        const urlSubjectFinish = '/panel/mailbox/' + notification.subjectType + '/'
                + MailboxConstants.MAILBOX_STATUS_HISTORICAL + '/' + notification.organizationalUnitId + '/' + notification.recipientId;

        const url = notification.actionType === SUBJECT_FINISHED ? urlSubjectFinish : urlSubjectOpen;

        const queryParams: Params = {
            recipientId: notification.recipientId,
            subjectType: notification.subjectType,
            mailboxType: notification.actionType === SUBJECT_FINISHED ? notification.mailboxStatus : notification.mailboxType
        };
        this.router.navigate(
                [ url ],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: queryParams,
                    queryParamsHandling: 'merge', // remove to replace all query params by provided
                }
        ).then(() => {
            this.markAsOpenAction(notification);
        });
    }

}
