import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureMethodsComponent } from './signature-methods/signature-methods.component';
import { PanelComponent } from './panel/panel.component';
import { RouterModule, Routes } from "@angular/router";
import { UserProfileComponent } from "./user-profile.component";
import { UserResetPasswordComponent } from "./user-reset-password/user-reset-password.component";
import { MaterialModule } from "../material.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SharedModule } from "../shared.module";
import { SatSignatureComponent } from './sat-signature/sat-signature.component';
import { ProcessTreePanelModule } from "../process-tree-panel/process-tree-panel.module";

const routes: Routes = [
  {
    path: '',
    component: PanelComponent,
    children: [
      // { path: '', redirectTo: 'details' },
      {
        path: '',
        component: UserProfileComponent,
        // pathMatch: 'full'
      },
      {
        path: 'resetpassword',
        component: UserResetPasswordComponent
      }
    ]
  }
];

@NgModule({
    declarations: [
        SignatureMethodsComponent,
        PanelComponent,
        UserResetPasswordComponent,
        SatSignatureComponent,
    ],
    exports: [
        SignatureMethodsComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FontAwesomeModule,
        SharedModule,
        RouterModule.forChild(routes),
        ProcessTreePanelModule,
    ]
})
export class UserProfileModule { }
