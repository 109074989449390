import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { reducers } from "./_store/reducers";
import { metaReducers } from "./_store/reducers";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { TreeModule } from 'angular-tree-component';
import { SharedModule } from "../../../../src/app/shared.module";
import { DynamicformsModule } from "../../../../src/app/dynamicforms.module";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        CKEditorModule,
        ReactiveFormsModule,
        DynamicformsModule,
        AppRoutingModule,
        StoreModule.forFeature('editor', reducers, {metaReducers}),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        TreeModule,
    ],
    bootstrap: [AppComponent],
    exports: [
    ],
    entryComponents: [

    ]
})
export class EditorAppModule { }

@NgModule({})
export class EditorProject{
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: EditorAppModule,
      providers: []
    }
  }
}
