import { Component, OnInit } from '@angular/core';
import { SystemSettingsService } from '../_services/system-settings.service';
import { LINK_LOGO_DEFAULT, SYSTEM_LOGO } from '../_constants/SystemSettingsConstants';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-navbar-logo',
  templateUrl: './navbar-logo.component.html',
  styleUrls: ['./navbar-logo.component.css']
})
export class NavbarLogoComponent implements OnInit {

  public imgURL: string|ArrayBuffer = null;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  constructor(
    public systemSettings: SystemSettingsService
  ) { }

  ngOnInit(): void {
    const logoUrl =  this.systemSettings.getSettingValue(SYSTEM_LOGO);
    if ( logoUrl != null){
      if (logoUrl == LINK_LOGO_DEFAULT){
        this.imgURL = environment.apiUrl +  '/' + LINK_LOGO_DEFAULT;
        this.favIcon.href = 'assets/images/login/CuboCSDocs.png';
      }else{
        this.imgURL = this.systemSettings.getSettingValue(SYSTEM_LOGO);
        this.favIcon.href = this.systemSettings.getSettingValue(SYSTEM_LOGO);
      }
    }
    else {
      this.imgURL = environment.apiUrl +  '/' + LINK_LOGO_DEFAULT;
      this.favIcon.href = 'assets/images/login/CuboCSDocs.png';
    }
  }

}
