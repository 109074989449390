import { Action } from '@ngrx/store'
import { UserRoleOrganizationalUnitModel } from "../../_models/UserRoleOrganizationalUnit.model";
import * as AssignParticipantsActions from "../actions/assing-participants.actions";
import { CatParticipantActionsModel } from "../../_models/CatParticipantActions.model";

/**
 *
 * Clase encargada de almacenar los participantes a los que se les turnará el oficio (Botón Turnar Participante)
 *
 */

export interface State {
  participants: Array<UserRoleOrganizationalUnitModel>,
  catParticipantActions: Array<CatParticipantActionsModel>,
  validParticipants: boolean
}

const initialState: State = {
  participants: [],
  catParticipantActions: null,
  validParticipants: false
};

export function reducer(state = initialState, action: AssignParticipantsActions.Actions) : any {

    switch(action.type) {
      case AssignParticipantsActions.ADD_PARTICIPANTS: {
        const newParticipants = state.participants === null ? action.participants : action.participants.filter(newParticipant => {
          return state.participants.find(participant => participant.user.id == newParticipant.user.id) == undefined;
        });
        let currentParticipants = state.participants !== null ? [...state.participants] : [];
        return {...state, participants: currentParticipants.concat(newParticipants)};
      }
      case AssignParticipantsActions.SET_VALID_PARTICIPANTS:
        return {...state, validParticipants: action.validParticipants };
      case AssignParticipantsActions.STORE_PARTICIPANT_ACTIONS:
        return { ...state, catParticipantActions: action.catParticipantActions }
      case AssignParticipantsActions.REMOVE_PARTICIPANT: {
        return {...state, participants: state.participants.filter(participant => participant.id != action.participant.id) };
      }
      case AssignParticipantsActions.UPDATE_PARTICIPANT:
        const participants = state.participants.map(participant => {
          if (participant.id == action.participant.id) {
            return action.participant;
          }
          return participant;
        });
        return {...state, participants: participants };
      case AssignParticipantsActions.CLEAR_SELECTED_PARTICIPANTS: {
        return { ...state , participants: [] }
      }
        default:
            return state;
    }
}


