import { Injectable } from '@angular/core';
import { Validators } from "@angular/forms";
import { MetadataModel } from "../_models/metadata.model";
import { CAT_DATA_TYPE } from "../_constants/CatDataType";

@Injectable({
    providedIn: 'root'
})
export class DynamicFormBuilder {

  buildForm(form, metadata: Array<MetadataModel>) {
    for (let field of metadata) {
      let fieldType = field.catDataType.field_name.toLocaleLowerCase();
      switch (fieldType) {
        case CAT_DATA_TYPE.STRING:
          form.push(DynamicFormBuilder.stringField(field));
          break;
        case CAT_DATA_TYPE.TEXT:
          form.push(DynamicFormBuilder.textField(field));
          break;
        case CAT_DATA_TYPE.DATE_TIME:
          form.push(DynamicFormBuilder.dateTimeField(field));
          break;
        case CAT_DATA_TYPE.CATALOG:
          form.push(DynamicFormBuilder.catalogField(field));
          break;
        case CAT_DATA_TYPE.BOOLEAN:
          form.push(DynamicFormBuilder.booleanField(field));
          break;
        case CAT_DATA_TYPE.INTEGER:
          form.push(DynamicFormBuilder.numberField(field));
          break;
        default:
          form.push();
          break;
      }
    }
    return form;
  }

  /**
   *
   * @param field
   */
  private static stringField(field: MetadataModel) {
    let validations = [];

    if (field.required) {
      validations.push(
        {
          name: "required",
          validator: Validators.required,
          message: field.field_name_text + " requerido"
        }
      );
    }

    if (field.max_length > 0) {
      validations.push({
        name: "maxlength",
        validator: Validators.maxLength(field.max_length),
        message: field.field_name_text + " requiere máximo " + field.max_length + " caracteres"
      });
    }

    if (field.min_length > 0) {
      validations.push({
        name: "minlength",
        validator: Validators.minLength(field.min_length),
        message: field.field_name_text + " requiere mínimo " + field.min_length + " caracteres"
      });
    }
    return {
      id: field.id,
      type: "input",
      label: field.field_name_text,
      tooltip: field['tooltip'],
      inputType: "text",
      name: field.field_name,
      value: field.value,
      validations: validations,
      readonly: field['readonly']
    };
  }

  private static numberField(field: MetadataModel) {
    let validations = [];

    if (field.required) {
      validations.push(
        {
          name: "required",
          validator: Validators.required,
          message: field.field_name_text + " requerido"
        }
      );
    }

    if (field.max_length > 0) {
      validations.push({
          name: "maxlength",
          validator: Validators.maxLength(field.max_length),
          message: field.field_name_text + " requiere máximo " + field.max_length + " caracteres"
        },
        {
          name: "maxlength",
          validator: Validators.pattern('^[0-9]*$'),
          message: "El dato debe ser numérico"
        });
    }

    if (field.min_length > 0) {
      validations.push({
        name: "minlength",
        validator: Validators.minLength(field.min_length),
        message: field.field_name_text + " requiere mínimo " + field.min_length + " caracteres"
      }, {
        name: "minlength",
        validator: Validators.pattern('^[0-9]*$'),
        message: "El dato debe ser numérico"
      });
    }
    return {
      id: field.id,
      type: "input",
      label: field.field_name_text,
      tooltip: field['tooltip'],
      inputType: "number",
      name: field.field_name,
      value: field.value,
      validations: validations,
      readonly: field['readonly']
    };
  }

  /**
   *
   * @param field
   */
  private static textField(field: MetadataModel) {
    let validations = [];

    if (field.required) {
      validations.push(
        {
          name: "required",
          validator: Validators.required,
          message: field.field_name_text + " requerido"
        }
      );
    }

    if (field.max_length > 0) {
      validations.push({
        name: "maxlength",
        validator: Validators.maxLength(field.max_length),
        message: field.field_name_text + " requiere maximo " + field.max_length + " caracteres"
      });
    }

    if (field.min_length > 0) {
      validations.push({
        name: "minlength",
        validator: Validators.minLength(field.min_length),
        message: field.field_name_text + " requiere mínimo " + field.min_length + " caracteres"
      });
    }

    return {
      id: field.id,
      type: "textarea",
      label: field.field_name_text,
      tooltip: field['tooltip'],
      inputType: "text",
      name: field.field_name,
      value: field.value,
      validations: validations,
      readonly: field['readonly']
    };
  }

  /**
   *
   * @param field
   */
  private static dateTimeField(field: MetadataModel) {
    let validations = [];
    // const readonly = field['readonly'] == true ? true: false;

    if (field.required) {
      validations.push(
        {
          name: "required",
          validator: Validators.required,
          message: field.field_name_text + " requerido"
        }
      );
    }

    // validations.push(
    //     {
    //         name: "pattern",
    //         validator: Validators.pattern('^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$'),
    //         message: 'fecha inválida'
    //     }
    // );

    return {
      id: field.id,
      type: "date",
      label: field.field_name_text,
      name: field.field_name,
      tooltip: field['tooltip'],
      validations: validations,
      value: field.value,
      // readonly: readonly
      // value: {value: '', disabled:true}
    };
  }

  private static catalogField(field: MetadataModel) {
    let validations = [];

    const value = parseInt(field.value) > 0 ? parseInt(field.value) : '';

    if (field.required) {
      validations.push(
        {
          name: "required",
          validator: Validators.required,
          message: field.field_name_text + " requerido"
        }
      );
    }

    return {
      id: field.id,
      type: "select",
      label: field.field_name_text,
      name: field.field_name,
      tooltip: field['tooltip'],
      options: field.catalogData,
      validations: validations,
      value: value,
      readonly: field['readonly']
    };
  }

  private static booleanField(field: MetadataModel) {
    let validations = [];

    if (field.required) {
      validations.push(
        {
          name: "required",
          validator: Validators.required,
          message: field.field_name_text + " requerido"
        }
      );
    }

    return {
      id: field.id,
      type: "checkbox",
      label: field.field_name_text,
      name: field.field_name,
      tooltip: field['tooltip'],
      validations: validations,
      value: field.value == null ? false : field.value == '1',
    };
  }
}
