import { Action } from '@ngrx/store'
import * as SystemSettingsActions from './../actions/system-settings.actions'

export interface State {
    global: Array<any>
}

const initialState: State = {
    global: [],
};

export function reducer(state = initialState, action: SystemSettingsActions.Actions) : any {

    switch(action.type) {
        case SystemSettingsActions.STORE_GLOBAL_SETTINGS:
            return {...state, global: action.globalSettings};
        default:
            return {...state};
    }
}


