import { Action } from '@ngrx/store'
import * as MailboxActions from './../actions/mailbox.actions'
import { ProcessModel } from "../../_models/process.model";
import { UnreadMailboxModel } from "../../_models/UnreadMailboxModel";
import { SET_SUBJECT_TYPE_CODE } from "./../actions/mailbox.actions";

export interface State {
    process: Array<ProcessModel>,
    columnFiltersSelected: Array<any>,
    mailboxSubjects: pageData,
    mailboxUnread: Array<UnreadMailboxModel>,
    currentOrganizationalUnitId: number,
    subjectType: number,
    isLoading: boolean
    lastAccess: any,
    mailboxTypeCode: string | null,
    subjectTypeCode: string | null
}
export interface pageData {
  current_page: number;
  data: Array<any>;
  first_page_url: string|null;
  from: number;
  last_page: number;
  last_page_url: string|null;
  next_page_url: string|null;
  path: string|null;
  per_page: number;
  prev_page_url: string|null;
  to: number;
  total: number
}

const initialState: State = {
    process: [],
    columnFiltersSelected: [],
    mailboxSubjects: null,
    mailboxUnread: [],
    currentOrganizationalUnitId: null,
    subjectType: null,
    isLoading: false,
    lastAccess: null,
    mailboxTypeCode: null,
    subjectTypeCode: null
};

export function reducer(state = initialState, action: MailboxActions.Actions) : any {

    switch(action.type) {
      case MailboxActions.SET_SUBJECT_TYPE_CODE: {
        return {...state, subjectTypeCode: action.subjectTypeCode};
      }
      case MailboxActions.SET_MAILBOX_TYPE_CODE: {
        return {...state, mailboxTypeCode: action.mailboxTypeCode};
      }
        case MailboxActions.STORE_PROCESS:
            return {...state, process: action.payload};
        case MailboxActions.CLEAR_ALL_PROCESS:
            return {...state, process: []};
        case MailboxActions.STORE_COLUMN_FILTERS_SELECTED:
            return {...state, columnFiltersSelected: action.payload};
        case MailboxActions.GET_COLUMN_FILTERS_SELECTED:
            return {...state.columnFiltersSelected};
        case MailboxActions.CLEAR_COLUMN_FILTERS_SELECTED:
            return {...state, columnFiltersSelected: []};
        case MailboxActions.STORE_MAILBOX_SUBJECTS:
            return {...state, mailboxSubjects: action.payload};
        case MailboxActions.GET_MAILBOX_SUBJECTS:
            return {...state.mailboxSubjects};
        case MailboxActions.CLEAR_MAILBOX_SUBJECTS:
            return {...state, mailboxSubjects: []};
        case MailboxActions.STORE_UNREAD_MAILBOX:
            return {...state, mailboxUnread: action.payload};
        case MailboxActions.GET_UNREAD_MAILBOX:
            return {...state.mailboxUnread};
        case MailboxActions.SET_IS_LOADING:
          return { ...state, isLoading: action.isLoading };
        case MailboxActions.SUBTRACT_COUNT_UNREAD_MAILBOX:
            const mailboxState = state.mailboxUnread.map(mailbox => {
              if ( mailbox.code == action.code
                && mailbox.subjectType == action.subjectType
                && mailbox.organizationalUnitId == action.organizationalUnitId ) {

                let total_ = ((mailbox.total - 1) < 0 ) ? 0 : mailbox.total - 1;

                return {...mailbox, total: total_}
              }

              return mailbox;
            });

            return {...state, mailboxUnread: mailboxState};
      case MailboxActions.SET_CURRENT_ORGANIZATIONALUNITID:
            return {...state, currentOrganizationalUnitId: action.organizationalUnitId};
      case MailboxActions.SET_SUBJECT_TYPE:
        return {...state, subjectType: action.subjectType};
      case MailboxActions.SET_LAST_MAILBOX_DATETIME:
        return {...state, lastAccess: action.lastAccess };
      case MailboxActions.UPDATE_UNREAD_MAILBOX:
        return {
          ...state,
          mailboxUnread: {
            ...state.mailboxUnread.map(element =>
              element.subjectType === action.unreadMailbox.subjectType ? action.unreadMailbox : element)
          }
        }
        default:
            return {...state};
    }
}


