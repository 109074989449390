import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SystemSettingsService } from '../../../../../../src/app/_services/system-settings.service';
import { UsersService } from '../../../../../../src/app/_services/users.service';
import { InstancesApiModel } from '../_models/InstancesApi.model';
import { SerieApiModel } from '../_models/SerieApi.model';
import { SubjectRequestModel } from '../../../../../../src/app/_models/SubjectRequest.model';
import { ARCHIVAL_ENABLED, ARCHIVAL_HOST } from '../../../../../../src/app/_constants/SystemSettingsConstants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root'
})
export class ArchiveSubjectService {
  url = environment.apiUrlCG + '/api/archival';
  onSerieStepChange: EventEmitter<any> = new EventEmitter();
  /** callback when user go to step two */
  onTemplateStepChange: EventEmitter<any> = new EventEmitter();
  /** callback when user go to step three */
  onResumeStepChange: EventEmitter<any> = new EventEmitter();
  /** callback when user go to step four */
  urlArch = '';

  constructor(
          private http: HttpClient,
          private systemSettings: SystemSettingsService,
          private router: Router,
          private userService: UsersService,
          private systemSettingsService: SystemSettingsService
  ) {
    this.urlArch = systemSettingsService.getSettingValue(ARCHIVAL_HOST) + '/api';
  }

  public getInstances(): Observable<InstancesApiModel> {
    return this.http.get<InstancesApiModel>(this.url + '/instances', httpOptions);
  }

  /**
   *
   * @param headers
   */
  public getSeries(headers: any): Observable<SerieApiModel> {
    const httpOptions = {
      headers: new HttpHeaders({ ...{ 'Content-Type': 'application/json' }, ...headers }),
    };

    return this.http.get<SerieApiModel>(this.url + '/series', httpOptions);
  }

  public getSeriesRepositories(): Observable<any> {
    return this.http.get(this.urlArch + '/repositories/getSeries');
  }

  /**
   *
   * @param repositoryId
   * @param params
   * @param headers
   */
  public createExpedient(repositoryId: number, params, headers): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ ...{ 'Content-Type': 'application/json' }, ...headers }),
    };
    return this.http.post(this.url + '/expedient/' + repositoryId, params, httpOptions);
  }

  /**
   *
   * @param repositoryId
   */
  public getExpedient(repositoryId: number): Observable<any> {
    return this.http.get(this.url + '/expedient/' + repositoryId, httpOptions);
  }

  /**
   *
   * @param repositoryId
   * @param serieId
   * @param headers
   */
  public getTemplateMetadata(repositoryId: number, serieId: number, headers: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ ...{ 'Content-Type': 'application/json' }, ...headers }),
    };
    return this.http.get(this.url + '/expedient/template/' + repositoryId + '/' + serieId, httpOptions);
  }

  public isModelActivated(): boolean {
    const systemSettings = this.systemSettings.systemSettings;

    const archivalEnabled = systemSettings?.find(setting => setting.code == ARCHIVAL_ENABLED);

    return archivalEnabled != undefined && ( archivalEnabled.value == true || archivalEnabled.value == 1 );
  }

  /**
   *
   * @param subjectRequest
   */
  public goToArchiveSubject(subjectRequest: SubjectRequestModel) {
    const url = subjectRequest.archivado.organizationalUnit_id + '/' + subjectRequest.destinatario.id + '/' + subjectRequest.destinatario.subjectRequest_id;
    return this.router.navigate([ '/archivistica/subject/' + url ]);
  }

  /**
   *
   * @param instanceName
   * @return {headers<{email: string, instanceName: string}>}
   */
  public getArchivalHeaders(instanceName: string) {
    const headers = {
      email: this.userService.userData['email'],
      instanceName: instanceName
    };

    console.log(headers);

    return headers;
  }

}
