import { Action } from '@ngrx/store';

export const STORE_DATA  = '[Expedient Data] Store Data';
export const CLEAR_DATA  = '[Expedient Data] Clear Data';

export const STORE_OPENED  = '[ExpedientOpened Data] Store Opened';
export const CLEAR_OPENED  = '[ExpedientOpened Data] Clear Opened';

export class StoreExpedients implements Action {
  readonly type = STORE_DATA;

  constructor(public payload ) {}
}

export class StoreOpenedExpedient implements Action {
  readonly type = STORE_OPENED;

  constructor(public payload ) {}
}

export class ClearOpenedExpedient implements Action {
  readonly type = CLEAR_OPENED;

  constructor() {}
}

export class ClearDataAction implements Action {
  readonly type = CLEAR_DATA;

  constructor() {}
}


export type Actions = StoreExpedients
  | StoreOpenedExpedient
  | ClearDataAction
  | ClearOpenedExpedient;
