import { EventEmitter, Injectable } from '@angular/core';
import { DialogStateModel } from "../_models/DialogState.model";

@Injectable({
  providedIn: 'root'
})
export class SubjectDialogDataService {
  subjectRequestId: number = null;
  recipientId: number = null;
  dialogClosed: EventEmitter<DialogStateModel> = new EventEmitter();

  constructor() { }
}
