import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Helper } from "../_helpers/Helper";
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root'
})
export class OrganizationalUnitService {
  url = environment.CGHost + '/api/organizationalUnit';
  cg = environment.CGHost + '/api';
  office = environment.CGHost + '/api/office/organizationalUnit';
  constructor(private http: HttpClient, helper: Helper) {
  }

  /**
   *
   * @returns {Observable<any>}
   */
  public getWithCompanies(): Observable<any> {
    const url = this.url + '/withCompanies';
    return this.http.get(url);
  }

  /**
   *
   * @param organizationalUnitId
   */
  getMembers(organizationalUnitId): Observable<any> {
    return this.http.get(this.url + '/' + organizationalUnitId + '/members');
  }

  /**
   *
   * @param officeId
   * @param originOrganizationalUnitId
   * @param destinOrganizationalUnitId
   * @param params
   */
  requestFolio(officeId, originOrganizationalUnitId: number, destinOrganizationalUnitId: number = null, params: { originOrganizationalUnit: { separatedFolioId: number|null } } ): Observable<any> {
    let url = this.cg + '/office/folio/' + officeId+'/'+originOrganizationalUnitId;

    if ( destinOrganizationalUnitId > 0 ) {
      url = this.cg + '/office/folio/' + officeId+'/'+originOrganizationalUnitId + '/' + destinOrganizationalUnitId;
    }

    return this.http.post(url, params);
  }

  /**
   *
   * @param organizationalUnitId
   */
  hierarchical(organizationalUnitId): Observable<any> {
    return this.http.get(this.url + '/hierarchical/' + organizationalUnitId, httpOptions);
  }

  /**
   *
   * @param params
   */
  getHeadsOfOrganizationalUnit(params) {
    let httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params
    };

    return this.http.get(this.office + '/managers/array', httpOptions);
  }

  /**
   *
   * @param officeId
   */
  byParticipants(officeId: number) {
    return this.http.get(this.office + '/byParticipants/' + officeId, httpOptions);
  }

  virtualDepartments() {
    return this.http.get(this.cg + '/organizationalUnit/virtualDepartments', httpOptions);
  }
}
