import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubjectFlowModel, SubjectRequestModel } from "../../_models/SubjectRequest.model";
import { Store } from "@ngrx/store";
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import * as SubjectSelector from '../../_store/selectors/subject.selector';
import {filter, takeUntil} from "rxjs/operators";
import { Subject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";

export interface DetailsModel {
  sender: string;
  recipient: string;
  step: number;
  tasks: string;
  created_at: string;
}

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, OnDestroy {
  subjectRequest: SubjectRequestModel;
  displayedColumns: string[] = ['created_at','step', 'sender', 'recipient', 'first_view'];
  dataSource: MatTableDataSource<DetailsModel>;
  destroy: Subject<void> = new Subject();
  newMethod: boolean = false;

  constructor(
      private store$: Store<SubjectReducer.State>,
  ) { }

  ngOnInit() {
    this.store$.select(SubjectSelector.getSubjectFlow)
      .pipe(
        filter(data => data !== null && data !== undefined),
        takeUntil(this.destroy))
      .subscribe(subjectFlow => {
        /** Se comprueba que todos los registros de la tabla addresseeFlow tengan recipient_id y sender_recipient_id
         * en caso de no tener entonces se usa el método antiguo para construir la gráfica la cual no considera los campos
         * recipient y senderRecipient además de que no contiene la fecha de primera apertura del asunto por parte de los recipients
         */
        if (subjectFlow.details.some(addresseeFlowEntry => addresseeFlowEntry.recipient_id == null || addresseeFlowEntry.sender_recipient_id == null)) {
          this.getDetails(subjectFlow);
        } else {
          this.getDetailsNewMethod(subjectFlow);
        }
      });

  }

  getDetails(subjectFlow) {
    console.log('building graph with old method');
    const dataSource = subjectFlow.details.map(data => {
      const created_at = data.created_at;

      const senderUserName = data.senderUser.user.full_name_prefix;

      const sender = (data.senderUser !== null) ? `<p>${senderUserName}</p><p>${data.senderOrganizationalUnit.name}</p>` : data.senderOrganizationalUnit.name;

      const recipientUserName = (data.destinUser !== null) ? data.destinUser.user.full_name_prefix : '';
      const recipientUserEmail = (data.destinUser !== null) ? data.destinUser.user.email : '';

      const recipient = (data.destinOrganizationalUnit !== null) ? `<p>${recipientUserName}</p><p>${recipientUserEmail}</p><p>${data.destinOrganizationalUnit.name}</p>`
        : `<p>${recipientUserName}</p>`;

      let tasks = '';

      if (data.destinOrganizationalUnit !== null) {
        data.destinOrganizationalUnit.tasks.map(task => {
          tasks += `<p>${task.description}</p>`;
        });
      }

      return {
        sender: sender,
        recipient: recipient,
        step: data.step,
        tasks: tasks,
        created_at: created_at,
        first_view: ''
      };
    });

    this.dataSource = new MatTableDataSource(dataSource);
  }

  getDetailsNewMethod(subjectFlow) {
    console.log('building graph with new method');

    this.displayedColumns = ['created_at','step', 'sender', 'type_recipient', 'recipient', 'first_view'];
    this.newMethod = true;

    const dataSource = subjectFlow.details.map(data => {
      const created_at = data.created_at;

      const senderUserName = data.senderUser.user.full_name_prefix;
      const recipientUserEmail = data.destinUser.user.email;
      const sender = (data.senderUser !== null) ? `<p>${data.senderUser.role.name} - ${senderUserName}</p><p>${data.senderOrganizationalUnit.name}</p>` : data.senderOrganizationalUnit.name;

      const recipientUserName = data.destinUser.user.full_name_prefix;

      const recipient = (data.destinOrganizationalUnit !== null) ? `<p>${data.destinUser.role.name} - ${recipientUserName}</p>
            <p>${recipientUserEmail}</p>
            <p>${data.destinOrganizationalUnit.name}</p>`
          : `<p>${data.destinUser.role.name} - ${recipientUserName}</p>`;

      let tasks = '';

      if (data.destinOrganizationalUnit !== null) {
        data.destinOrganizationalUnit.tasks.map(task => {
          tasks += `<p>${task.description}</p>`;
        });
      }

      return {
        sender: sender,
        recipient: recipient,
        step: data.step,
        // tasks: tasks,
        created_at: created_at,
        first_view: data.recipient.first_view,
        type_recipient: data.receptionType
      };
    });

    this.dataSource = new MatTableDataSource(dataSource);
  }


  ngOnDestroy(): void {
    this.destroy.next();
  }
}
