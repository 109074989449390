import { Component, HostListener, OnInit } from '@angular/core';
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { UserModel } from "../../_models/user.model";
import { Observable, Subject } from "rxjs";
import { Title } from "@angular/platform-browser";
import { LocalstorageService } from "../../_services/Localstorage.service";
import { UsersService } from "../../_services/users.service";
import { Store } from "@ngrx/store";
import * as NotificationReducer from "../../_store/reducers/notification.reducer";
import { ProcessService } from "../../_services/process.service";
import { SystemInitService } from "../../system-init/SystemInitService";
import { SystemSettingsService } from "../../_services/system-settings.service";
import { LogoutService } from "../../_services/logout.service";
import * as EditorNotificationReducer from "../../../../projects/editor/src/app/_store/reducers/notification.reducer";
import * as NotificationSelector from "../../_store/selectors/notification.selector";
import * as EditorNotificationSelector
  from "../../../../projects/editor/src/app/_store/selectors/notification.selector";
import { ProcessModel } from "../../_models/process.model";
import { OrganizationalUnitModel } from "../../_models/OrganizationalUnit.model";
import * as ProcessReducer from "../../_store/reducers/process.reducer";
import { MatDialog } from "@angular/material/dialog";
import { AuthClass } from "../../auth/Auth.class";
import { Router } from "@angular/router";
import * as ProcessSelector from "../../_store/selectors/process.selector";
import { takeUntil } from "rxjs/operators";
import * as ProcessActions from "../../_store/actions/process.actions";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {
  faBars = faBars;
  userPicture = faUser;
  showPanel: boolean;
  windowWidth: number;
  panelMode: string;
  hasBackdrop: boolean;
  userData: UserModel;
  faSignOut = faSignOutAlt;
  organizationalUnitsRole: Array<any>;
  processes: ProcessModel[];
  organizationalUnit: Array<OrganizationalUnitModel> = [];
  destroy: Subject<void> = new Subject();
  systemSettings: Object = {};
  totalNewNotifications: Observable<any>;
  totalNewEditorNotifications: Observable<any>;
  showEditorOption: boolean = false;

  constructor(
    private title: Title,
    private LocalStorage: LocalstorageService,
    public userService: UsersService,
    private store$: Store<ProcessReducer.State>,
    private notification$: Store<NotificationReducer.State>,
    private editorNotification$: Store<EditorNotificationReducer.State>,
    private processService: ProcessService,
    private systemInitService: SystemInitService,
    private dialog: MatDialog,
    private auth: AuthClass,
    private localStorage: LocalstorageService,
    private router: Router,
    private process$: Store<ProcessReducer.State>,
    public systemSettingsService: SystemSettingsService,
    private logoutService: LogoutService
  ) {
    this.onResize(null);
  }

  ngOnInit() {
    this.systemSettings = this.systemSettingsService.getLoginSettings();

    this.showEditorOption = this.systemSettingsService.isEditorActive();

    this.showPanel = true;

    this.title.setTitle('Control de Gestión');

    this.systemInitService.init();

    this.setOpenStatus();

    this.userData = this.getUserData();

    this.setUserData();

    this.getProcesses();

    this.totalNewNotifications = this.notification$.select(NotificationSelector.getTotalUnreadNotifications);

    this.totalNewEditorNotifications = this.editorNotification$.select(EditorNotificationSelector.getTotalUnreadNotifications);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;

    if (this.windowWidth > 978) {
      this.panelMode = "side";
      this.hasBackdrop = false;
    } else {
      this.panelMode = "over";
      this.hasBackdrop = true;
    }

  }

  setOpenStatus() {
    this.showPanel = (window.innerWidth > 1200) ? true : false;
  }

  getUserData() {
    return this.userService.userData;
  }

  setUserData() {
    if (this.userData === null)
      return;

    this.organizationalUnitsRole = this.userData.organizationalUnits;
  }

  closeStart() {
    this.showPanel = false;
  }

  openStart() {
    this.showPanel = true;

  }

  closed() {

  }

  opened() {

  }

  ngAfterViewInit(): void {

  }

  getProcesses() {
    this.process$.select(ProcessSelector.getProcesses)
      .pipe(takeUntil(this.destroy))
      .subscribe(processes => {
        if (processes !== null) {
          this.processes = processes;
          this.organizationalUnit = this.userService.userData.organizationalUnits as Array<OrganizationalUnitModel>;
        }
      });

    this.processService.getProcessesWithMetadata().subscribe(
      response => {
        if (response['status']) {
          this.store$.dispatch(new ProcessActions.AddProcessesActions(response['processes']));
        } else {
          console.error(response);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  logout() {
    this.logoutService.execute();
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
