import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule             } from "../material.module"
import { SharedModule } from "../shared.module"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ProcessTreeComponent } from "./process-tree.component";
import { TreeModule } from "angular-tree-component";
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FontAwesomeModule,
        SharedModule,
        TreeModule.forRoot(),
        MatTooltipModule
    ],
    exports: [
        ProcessTreeComponent
    ],
    declarations: [
        ProcessTreeComponent]
    ,
    entryComponents: [
        // DialogNewMetadataComponent,
    ]
})
export class ProcessTreePanelModule { }
