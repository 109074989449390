import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArchivalGuard } from '../../../../src/app/_guards/archival.guard';
import { AuthGuard } from '../../../../src/app/_guards/auth.guard';

// TODO AQUI SE VAN DEFINIENDO LAS NUEVAS RUTAS QUE IRAN DEBAJO DE /archivistica
const routes: Routes = [
  {
    canActivate: [ ArchivalGuard, AuthGuard ],
    path: 'archivistica',
    children: [
      {
        path: '', loadChildren: () => import('./archivist/archivist.module').then(m => m.ArchivistModule)
      },
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
