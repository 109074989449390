import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import * as NewSubjectReducer from "../../_store/reducers/new-subject.reducer";
import * as NewSubjectSelector from "../../_store/selectors/new-subject.selector";
import { Subject} from "rxjs";
import { UsersService } from "../../_services/users.service";
import { filter, take, takeUntil } from 'rxjs/operators';
import { UserRole } from "../../_constants/CatUserRoleConstants";
import * as MailboxReducer from '../../_store/reducers/mailbox.reducer';

@Component({
  selector: 'app-subject-settings',
  templateUrl: './subject-settings.component.html',
  styleUrls: ['./subject-settings.component.css']
})
export class SubjectSettingsComponent implements OnInit, OnDestroy {
  formGroup : FormGroup;
  managers: any; //UserRoleOrganizationalUnitModel
  currentOrganizationalUnitId: number = 0;
  destroy: Subject<void> = new Subject();
  currentUser: any;
  copAssistant: boolean;
  nameCopAssistant: string;

  private = 'private';
  assistant = 'assistant';
  constructor(
    private fb : FormBuilder,
    private newSubject$: Store<NewSubjectReducer.State>,
    public userService: UsersService,
    private store$: Store<MailboxReducer.State>,
  ) { }

  ngOnInit() {
    this.currentUser = this.userService.userData;

    this.formGroup = this.fb.group({
      onlyTitular: new FormControl(false, []),
      copyAssistant: new FormControl(false, []),
      copyAssistantAfter: new FormControl(false, []),
      sender_user_id: new FormControl(false, [])
    });


    /**
     * Se obtienen los miembros de la unidad organizacional actual.
     */
    this.newSubject$.select(NewSubjectSelector.getCurrentOrganizationalUnitIdAndMembers)
      .pipe(
        filter(data => data != null && typeof data.users == 'object' && data.users.length > 0 && data.currentOrganizationalUnitId != null),
        take(1))
      .subscribe(newSubjectState => {
        /**
         * Se obtiene el id de la unida organizacional actual desde donde se creará el asunto.
         */
        this.currentOrganizationalUnitId = newSubjectState.currentOrganizationalUnitId;
        this.getAssistant()
        this.managers = newSubjectState.users.filter(user => user.role.code == UserRole.TITULAR || user.user.id == this.currentUser.id);
        const titular = this.managers[this.managers.findIndex(item => item.role.code == UserRole.TITULAR)]; //primer titular

        this.managers.push( { user : this.currentUser}); //agrega al final el asistente

        if ( this.formGroup.get('sender_user_id')) {
          if ( this.userService.isAsistente(this.currentOrganizationalUnitId) ) {
            this.formGroup.get('sender_user_id').setValue(titular.user.id);
          } else {
            this.formGroup.get('sender_user_id').setValue(this.currentUser.id);
          }
        }
    });

    this.store$.select(NewSubjectSelector.getSubjectSettings)
      .pipe(
        filter(data => data !== null),
        takeUntil(this.destroy)
      )
      .subscribe(data => {
        this.form.copyAssistant.setValue(data.copyAssistant);
      });
  }

  getAssistant() {
    const isTitular = this.userService.isTitular(this.currentOrganizationalUnitId);
    this.userService.getAssistant(this.currentOrganizationalUnitId).subscribe((res) => {
      if (res && isTitular) {
        res.length > 1 ? this.nameCopAssistant = 'asistentes' : this.nameCopAssistant = 'asistente';
        this.copAssistant = true;
        this.form.copyAssistant.setValue(true);
        this.form.copyAssistantAfter.setValue(true);
      }
      if (res && !isTitular) {
        res.length == 1 ? this.copAssistant = false : this.copAssistant = true;
        res.length > 1 ? this.nameCopAssistant = 'asistentes' : this.nameCopAssistant = 'asistente';
        this.form.copyAssistant.setValue(true);
        this.form.copyAssistantAfter.setValue(true);
      }
    });
  }

  get form () {
    return this.formGroup.controls;
  }

  changeValue(e, type) {
    if (type === this.private && e.checked) {
      this.form.copyAssistant.setValue(false);
    }
    if (type == this.assistant && e.checked) {
      this.form.onlyTitular.setValue(false);
    }
    if (type == this.assistant) {
      this.form.copyAssistantAfter.setValue(e.checked);
    }
  }

  getFormValues() {
    return this.formGroup.value;
  }

  disabled(): void {
    if (this.formGroup === undefined) {
      return;
    }

    this.formGroup.disable();
  }

  enabled(): void {
    if (this.formGroup === undefined) {
      return;
    }

    this.formGroup.enable();
  }

  setDataValue(data): void {
    if ( !this.formGroup ) {
      return;
    }

    Object.keys(data).map(fieldName => {
      const control = this.formGroup.get(fieldName);

      if (control !== null) {
        control.setValue(data[fieldName]);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

}
