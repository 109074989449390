import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { faBan, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubjectService } from '../../_services/subject.service';
import { ErrorMessageComponent } from '../../messages/error-message/error-message.component';
import { CAT_ANSWER_TYPE } from '../../_constants/CatAnswerTypeConstant';

@Component({
  selector: 'app-solve-subject',
  templateUrl: './solve-subject.component.html',
  styleUrls: ['./solve-subject.component.css']
})
export class SolveSubjectComponent implements OnInit {
  @ViewChild( ErrorMessageComponent, { static: true } ) errorMessage;
  solvedForm: FormGroup;
  title: string;
  textContent: string;
  faCancel = faBan;
  faConfirm = faExclamationCircle;
  submitted: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private subjectService: SubjectService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private matDialogRef: MatDialogRef<SolveSubjectComponent>,
  ) {
    this.title = this.dialogData.title;
    this.textContent = this.dialogData.textContent;
  }

  ngOnInit(): void {
    this.solvedForm = this.formBuilder.group({
      commentSolved: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10000)]]
    });
    console.log(this.dialogData.recipient);
  }

  get form() {
    return this.solvedForm.controls;
  }

  confirm(): any {
    this.submitted = true;

    if (this.solvedForm.invalid) {
      return false;
    }
    console.log(this.form.commentSolved.value);
    this.submitted = false;

    const params = {
      answerType_id: CAT_ANSWER_TYPE.TYPE_RESOLVED,
      answer: this.form.commentSolved.value,
      subjectRequest_id: this.dialogData.recipient.subjectRequest_id,
      user_id: this.dialogData.recipient.user_id,
      organizationalUnitId: this.dialogData.recipient.organizationalUnit_id,
      step: this.dialogData.recipient.step
    };

    this.subjectService.marcarResuelto(this.dialogData.recipient.id, 1).subscribe( response => {
      console.log(response);
        if (response.status) {
          //this.subjectRequest.destinatario.resuelto = true;

          this.subjectService.addAnswerType(params, this.dialogData.recipient.id).subscribe(x => {
            console.log(x.status);
            if (x.status) {
              this.matDialogRef.close(x);
            }
          });

        } else {
          this.errorMessage.setErrorMessage(response);
        }
      },
      error => {
        this.errorMessage.setErrorMessage(error);
      }
    );

  }

}
