import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { COLOR_SOLVED } from '../_constants/SystemSettingsConstants';
import { SystemSettingsService } from '../_services/system-settings.service';
import { finished } from '../subject-flow/graph/graph.component';

@Directive({
  selector: '[appSolvedGraph]'
})
export class SolvedGraphDirective implements OnInit {
  @Input() solved: boolean;
  @Input() state: string;
  value: any;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private systemSettingsService: SystemSettingsService
  ) { }

  ngOnInit() {
    this.value = this.systemSettingsService.getSettingValue(COLOR_SOLVED);
    if (this.solved) {
      this.el.nativeElement.style.backgroundColor = this.value;
    }
    if (this.state === finished) {
      this.el.nativeElement.style.backgroundColor = '#dc3545';
    } else {
      this.el.nativeElement.classList.add('color-card');
    }
  }
}
