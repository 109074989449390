import { Component, Input, OnInit } from '@angular/core';
import { OfficeNotificationService } from '../../_services/office-notification.service';
import { Store } from '@ngrx/store';
import * as NotificationReducer from '../../_store/reducers/notification.reducer';
import * as NotificationSelector from '../../_store/selectors/notification.selector';
import * as NotificationAction from '../../_store/actions/notification.action';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Observable } from 'rxjs';
import { NotificationInterface } from '../../../../../../src/app/_models/notification.interface';
import { LocalstorageService } from '../../_services/Localstorage.service';

@Component({
  selector: 'app-editor-notifications',
  templateUrl: './editor-notifications.component.html',
  styleUrls: ['./editor-notifications.component.css']
})
export class EditorNotificationsComponent implements OnInit {
  faTrash = faTrashAlt;
  faEye = faEye;
  unreadNotifications: Observable<number>;
  editorNotifications: Observable<Array<NotificationInterface>>;
  LookEditorNotif:any = null;
  constructor( public localStorage: LocalstorageService,
               private officeNotificationService: OfficeNotificationService,
               private notification$: Store<NotificationReducer.State>) { }

  ngOnInit(): void {
    this.getNotifications();
    this.timeRefresh(60000 * 3);

    this.unreadNotifications = this.notification$.select(NotificationSelector.getTotalUnreadNotifications);
    this.editorNotifications = this.notification$.select(NotificationSelector.getNotifications);
  }
  /**
   *
   * @private
   */
  private getNotifications() {
    const activesection = this.localStorage.getToken();
    if (activesection === null) {
      this.closeNotificate();
    } else {
    this.officeNotificationService.get().subscribe(
      response => {
        if (response['status']) {
          this.notification$.dispatch(new NotificationAction.StoreNotifications(response['notifications']));
        } else {

        }
      }, error => {
        console.error(error);
      }
    );
    }
  }

  /**
   *
   * @param time
   * @private
   */
  private timeRefresh(time) {
    if (this.LookEditorNotif === null) {
      this.LookEditorNotif = setInterval(() => { this.getNotifications(); }, time);
    }
  }

  /**
   * Remove all user notifications
   * @private
   */
  private removeAllElements() {
    this.officeNotificationService.removeAll().subscribe(response => {
      if (response['status']) {
        this.notification$.dispatch(new NotificationAction.StoreNotifications([]));
      } else {
        console.error(response);
      }
    }, error => {
      console.log(error);
    });
  }

  private markAllAsOpen() {
    this.officeNotificationService.markAllAsOpen().subscribe(response => {
      if (response['status']) {
        this.notification$.dispatch(new NotificationAction.MarkAllNotificationsAsOpen());
      } else {
        console.error(response);
      }
    }, error => {
      console.log(error);
    });
  }

  closeNotificate() {
    clearInterval(this.LookEditorNotif);
    this.LookEditorNotif = null;
  }

}
