import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { EXTERNAL_SUBJECT_LABEL, INTERNAL_SUBJECT_LABEL } from '../_constants/SubjectTypeConstants';

@Directive({
  selector: '[appSubjectTypeDirective]'
})
export class SubjectTypeDirectiveDirective implements OnInit {
  @Input() subjectType: boolean;
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    if (this.subjectType == true ) {
      const b = this.renderer.createElement('b');
      b.innerText = EXTERNAL_SUBJECT_LABEL;
      this.renderer.appendChild(this.el.nativeElement, b);
    } else {
      const b = this.renderer.createElement('b');
      b.innerText = INTERNAL_SUBJECT_LABEL;
      this.renderer.appendChild(this.el.nativeElement, b);
    }
  }
}
