import { SubjectRequestModel } from '../_models/SubjectRequest.model';
import * as ReceptionType from '../_constants/ReceptionType.constants';
import { CANCELLED, CLOSED, NUEVO } from '../_constants/SubjectStatus.constants';
import { SystemSettingsService } from '../_services/system-settings.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as NewOfficeReducer from '../../../projects/editor/src/app/_store/reducers/new-document.reducer';
import * as NewOfficeAction from '../../../projects/editor/src/app/_store/actions/new-document.actions';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { AddresseeModel } from '../_models/addresseeModel';
import { CANCELED_DAYS, CC_RESPONSE_ENABLED, CCC_RESPONSE_ENABLED } from '../_constants/SystemSettingsConstants';
import * as SubjectActions from '../_store/actions/subject.actions';
import { SendCopyDialogComponent } from '../subject/send-copy-dialog/send-copy-dialog.component';
import * as SubjectReducer from '../_store/reducers/subject.reducer';
import { ElementRef, ViewChild } from '@angular/core';
import { NotifierComponent } from '../notifier/notifier.component';
import { UsersService } from '../_services/users.service';
import { SubjectRequestService } from '../_services/subject-request.service';
import { ErrorMessageComponent } from '../messages/error-message/error-message.component';
import { DynamicFormComponent } from '../components/dynamic-form/dynamic-form.component';
import { Observable } from 'rxjs';
import {
  MAILBOX_HISTORY,
  MAILBOX_HISTORY_EXTERNAL,
  MAILBOX_INBOX_CODE,
  MAILBOX_INBOX_CODE_EXTERNAL,
  MAILBOX_INBOXOUT_CODE,
  MAILBOX_INBOXOUT_CODE_EXTERNAL
} from '../process-tree-panel/_models/MailboxNodes';
import { EXTERNAL_SUBJECT_CODE, INTERNAL_SUBJECT_CODE } from '../_constants/SubjectTypeConstants';
import { MAILBOX_STATUS_HISTORICAL, MAILBOX_TYPE_INBOX, MAILBOX_TYPE_INBOXOUT } from '../_constants/MailboxConstants';
import * as moment from 'moment';
import { UserRole } from '../_constants/CatUserRoleConstants';

export abstract class Preview {
  @ViewChild(NotifierComponent, {static: true}) notifier;
  @ViewChild(ErrorMessageComponent, {static: true}) errorMessage;
  @ViewChild('dynamicFormComponent', {static: true}) dynamicForm: DynamicFormComponent;
  @ViewChild('dialogContent') myScrollContainer: ElementRef;

  isLoading$: Observable<boolean>;
  loading: boolean;
  subjectRequest: SubjectRequestModel = null;
  organizationalUnitId: number = null;
  officeIcon = faFileAlt;
  valueCC: number;
  valueCCC: number;

  mailboxType: string;
  buttonResponseSubject: boolean;
  buttonResponseOffice: boolean;
  buttonSolved: boolean;
  buttonAdvance: boolean;
  buttonCC: boolean;
  buttonFinishSubject: boolean;
  buttonCancelSubject: boolean;
  buttonReject: boolean;

  protected constructor(
    public systemSettingsService: SystemSettingsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<any>,
    public newOffice$: Store<NewOfficeReducer.State>,
    public subject$: Store<SubjectReducer.State>,
    public dialog: MatDialog,
    public userService: UsersService,
    public subjectRequestService: SubjectRequestService
  ) {
  }

  abstract buildSubjectForm();

  abstract openSubjectAnswerDialog();

  abstract marcarResuelto(recipient);

  abstract finalizarAsunto();

  abstract avanzar();

  scrollToElement(): void {
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scroll({
        top: this.myScrollContainer.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }, 100);
    this.subject$.dispatch(new SubjectActions.SetIsLoadingFlowChart(false));
  }

  /**
   *
   * @param recipient
   */
  getReceptionType(recipient): string {
    if (recipient === null || recipient === undefined) {
      return '';
    }

    if (recipient.TURNAR === true || recipient.TURNAR === 1) {
      return ReceptionType.TURNAR;
    }

    if (recipient.CC === true || recipient.CC === 1) {
      return ReceptionType.CC;
    }

    if (recipient.CCC === true || recipient.CCC === 1) {
      return ReceptionType.CCC;
    }

    return '';
  }

  /**
   *
   * @param subjectRequest
   */
  public allowAdvance(subjectRequest: SubjectRequestModel): boolean {
    if (!subjectRequest) {
      return false;
    }

    return !subjectRequest.recipient.rejected && !subjectRequest.onlyTitular;
  }

  /**
   *
   * @param subjectRequest
   */
  public allowResponseSubject(subjectRequest: SubjectRequestModel): boolean {
    if (!subjectRequest) {
      return false;
    }

    return !subjectRequest.recipient.rejected && subjectRequest.status.code === NUEVO;
  }

  /**
   *
   * @param subjectRequest
   */
  public allowResolve(subjectRequest: SubjectRequestModel): boolean {
    if (!subjectRequest) {
      return false;
    }

    return !subjectRequest.recipient.rejected && (subjectRequest.recipient.TURNAR || subjectRequest.recipient.CC) && !subjectRequest.recipient.resuelto;
  }

  /**
   *
   * @param recipient
   */
  public allowRespondWithOffice(recipient: AddresseeModel): boolean {
    if (!recipient) {
      return false;
    }

    return this.systemSettingsService.isEditorActive() && !recipient.responding_with_office;
  }

  /**
   *
   * @param subjectRequest
   */
  public respondWithOffice(subjectRequest: SubjectRequestModel) {
    this.dialogRef.close();
    const navigationExtras: NavigationExtras = {
      queryParams: {mailboxUrl: this.router.url}
    };

    this.newOffice$.dispatch(new NewOfficeAction.StoreRespondSubject(subjectRequest));
    // TODO store in redux mailbox url and subjectRequest and go to respond-subject-with-office component
    this.router.navigate(['/editor/new-office/' + this.organizationalUnitId + '/' + subjectRequest.recipient.id], navigationExtras);
  }

  /**
   *
   * @param recipient
   */
  public disableResponse(recipient): boolean {
    if (!recipient) {
      return false;
    }
    this.valueCC = this.systemSettingsService.getSettingValue(CC_RESPONSE_ENABLED);
    this.valueCCC = this.systemSettingsService.getSettingValue(CCC_RESPONSE_ENABLED);

    if ( recipient.isSender ) {
      return true;
    }

    if ( recipient.CC == 1 ) { return this.valueCC == 1; } else if ( recipient.CCC == 1 ) { return this.valueCCC == 1; } else { return recipient.TURNAR == 1; }
  }

  /**
   * @param subjectRequest
   */
  allowCC(subjectRequest: SubjectRequestModel): boolean {
    if (subjectRequest) {
      return true;
    }
    return false;
  }

  /**
   * Se muestra un modal con todas las unidades organizacionales disponibles únicamente para CC y CCC.
   * @param subjectRequest
   */
  openCcDialog(subjectRequest: SubjectRequestModel): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.maxWidth = '800px';
    dialogConfig.minWidth = '600px';
    // dialogConfig.maxHeight = '500px';
    // dialogConfig.panelClass = 'dialog-confirmation-class';

    this.subject$.dispatch(new SubjectActions.SetIsLoadingDialog(true));

    const dialog = this.dialog.open(SendCopyDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(callback => {
      if (callback !== null && callback !== undefined) {
        this.notifier.show({
          message: 'Asunto enviado en copia.',
          type: 'success'
        });
      }
    });
  }

  showTasksTab(): boolean {
    if (!this.subjectRequest || this.subjectRequest.tasks == null  || !this.organizationalUnitId) {
      return false;
    }
    if (this.subjectRequest.tasks.filter(task => task.sender_user_id === this.userService.userData.id).length > 0) {
      return true;
    } else if (this.userService.isTitular(this.organizationalUnitId) || this.userService.isAsistente(this.organizationalUnitId)) {
      return this.subjectRequest.tasks.length > 0;
    } else {
      return this.subjectRequest.tasks.filter(task => task.userAssigned !== null && task.userAssigned.id === this.userService.userData.id).length > 0;
    }
  }

  /**
   * Se muestra un modal con todas las unidades organizacionales disponibles únicamente para CC y CCC.
   * @param subjectRequest
   */
  canAddAttachments(subjectRequest: SubjectRequestModel): boolean {
    if (!subjectRequest) {
      return false;
    }

    return subjectRequest.destinatario.isSender == true
            && subjectRequest.isExternal == false
            && subjectRequest.mailbox.status.code === MAILBOX_HISTORY
            && subjectRequest?.archivado?.archivistica_id === null;
  }

  /**
   * Only the sender is allowed to cancel the subject
   * @param subjectRequest
   * @param mailboxType
   */
  allowFinishSubject(subjectRequest: SubjectRequestModel, mailboxType): boolean {
    const user_id = this.userService.userData.id;
    if (subjectRequest) {
      const isAsistente = this.userService.userData.organizationalUnits.find(
        organizationalUnit => organizationalUnit.id === subjectRequest.organizationalUnit_id
          && organizationalUnit.role.code === UserRole.ASISTENTE
      );
      if (mailboxType === MAILBOX_INBOX_CODE_EXTERNAL) {
        const close = subjectRequest.user_id === user_id || subjectRequest.recipient.allow_close_subject || subjectRequest.destinatario.allow_close_subject || isAsistente;
        return subjectRequest.status.code === NUEVO && close;
      } else {
        return subjectRequest.status.code === NUEVO && (subjectRequest.user_id === user_id || isAsistente);
      }
    }
  }

  canCancelSubject(recipient) {
    return recipient?.status.code == CANCELLED || recipient?.status.code == CLOSED;
  }

  cancelDaysSubject(subjectRequest): boolean {
    const createSubject = moment(subjectRequest.created_at);
    const untilSubject = moment(subjectRequest.untilDate);
    const document = !(subjectRequest.documents.length > 0);

    const diff = untilSubject.diff(createSubject, 'days');
    const canceled_days = Number(this.systemSettingsService.getSettingValue(CANCELED_DAYS));

    return (diff >= canceled_days) && document;
  }

  buttonIsPrint(recipient): boolean {
    return recipient?.isExternal == true && recipient?.mailbox.status.code == MAILBOX_HISTORY;
  }

  mailboxValidation(): any {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['subjectType'] === INTERNAL_SUBJECT_CODE) {
        switch (params['mailboxType']) {
          case MAILBOX_TYPE_INBOX:
            this.mailboxType =  MAILBOX_INBOX_CODE;
            break;
          case MAILBOX_TYPE_INBOXOUT:
            this.mailboxType =  MAILBOX_INBOXOUT_CODE;
            break;
          case MAILBOX_STATUS_HISTORICAL:
            this.mailboxType =  MAILBOX_HISTORY;
            break;
        }
      }
      if (params['subjectType'] === EXTERNAL_SUBJECT_CODE) {
        switch (params['mailboxType']) {
          case MAILBOX_TYPE_INBOX:
            this.mailboxType =  MAILBOX_INBOX_CODE_EXTERNAL;
            break;
          case MAILBOX_TYPE_INBOXOUT:
            this.mailboxType =  MAILBOX_INBOXOUT_CODE_EXTERNAL;
            break;
          case MAILBOX_STATUS_HISTORICAL:
            this.mailboxType =  MAILBOX_HISTORY_EXTERNAL;
            break;
        }
      }
    });
    return this.mailboxType;
  }

  mailbox(mailboxType) {
    if (mailboxType == MAILBOX_INBOX_CODE || mailboxType == MAILBOX_INBOX_CODE_EXTERNAL ) {
      this.buttonResponseSubject = true;
      this.buttonResponseOffice = true;
      this.buttonFinishSubject = true;
      this.buttonSolved = true;
      this.buttonAdvance = true;
      this.buttonReject = true;
      this.buttonCC = true;
    }
    if (mailboxType == MAILBOX_INBOXOUT_CODE || mailboxType == MAILBOX_INBOXOUT_CODE_EXTERNAL ) {
      this.buttonResponseSubject = true;
      this.buttonAdvance = true;
      this.buttonCC = true;
      this.buttonFinishSubject = true;
      this.buttonCancelSubject = true;
    }
  }

  mailboxButtonValidator() {
    this.mailbox(this.mailboxValidation());
  }

  canSeeSubjectReviewed(): boolean {
    return true;
  }
}
