import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FieldConfig } from "../../_models/field.interface"
import {MetadataModel} from "../../_models/metadata.model";
import {DynamicFormBuilder} from "../DynamicFormBuilder";

/**
    Json example configuration that you can use for create dynamic forms
    check muck.ts
 */

@Component({
  selector: 'dynamic-form',
    templateUrl: './dynamic-form.component.html',
  styles: []
})
export class DynamicFormComponent implements OnInit {
    /**
     * fields of type FieldConfig that accept a configuration array from parent component.
     * @type {Array}
     */
    @Input() fields: FieldConfig[] = [];

    /**
     * submit of type EventEmitter<any> it will notify the parent component when the form is submitted.
     * @type {EventEmitter<any>}
     */
    @Output() submit: EventEmitter<any> = new EventEmitter<any>();
    /**
     * form of type FormGroup it aggregates the values of each child FormControl .
     */
    form: FormGroup;

  constructor(
      private fb: FormBuilder,
      private formBuilder: DynamicFormBuilder,
  ) { }

  ngOnInit() {

  }

  /**
   * Create dynamic form controls
   * @param metadata
   */
  public createControls( metadata: MetadataModel[] ) {
    this.fields.splice(0,this.fields.length)

    const controls = this.formBuilder.buildForm([], metadata);

    this.form = this.addControls(controls);
  }

    get value() {

        return this.form.value;

    }

    public isValid(): boolean {
      if (!this.form) {
        return false;
      }
      return this.form.valid;
    }

    /**
     *  It loops through the configuration fields and creates a control for each field with validations and then
     *  add these dynamically created controls to the form group.
     * @returns {FormGroup}
     */

  private addControls(fields: FieldConfig[] = null) {
    const group = this.fb.group({});

    this.fields = fields;

    fields.forEach(field => {

      if (field.type === "button") return;

      let fieldValue = (field.type === 'date') ? ((field.value === null || field.value === undefined) || field.value == '') ? '' : new Date(field.value).toISOString() : field.value;

      const control = this.fb.control(

        fieldValue,

        this.bindValidations(field.validations || [])

      );

      group.addControl(field.name, control);

      if (field.readonly != undefined && field.readonly == true) {
        control.disable();
      }
    });

    return group;

  }
    /**
     * add validations to dynamic control.
     * @param validations
     * @returns {any}
     */
    private bindValidations(validations: any) {

        if (validations.length > 0) {

            const validList = [];

            validations.forEach(valid => {

                validList.push(valid.validator);

            });

            return Validators.compose(validList);

        }

        return null;

    }

    public markFieldsAsTouched() {

        Object.keys(this.form.controls).forEach(field => {

            const control = this.form.get(field);

            control.markAsTouched({ onlySelf: true });

        });

    }

    public getFieldsAndValues(){
        let self = this;
        let fields = [];
        this.fields.forEach(function(field, index){

            if(field.type === 'button')
                return;

            const value = self.form.get(field.name).value;

            if (field.type === 'textarea' && ((value === null || value === undefined) || value == '')) {
                self.form.get(field.name).setValue(' ');
            }

            let data = {
                id: field.id,
                value: value
            };

            fields.push(data);
        });

        return fields;
    }

  public getNameAndValues(){
    let self = this;
    let fields = {};
    this.fields.forEach(function(field, index){

      if(field.type === 'button')
        return;

      const value = self.form.get(field.name).value;

      if (field.type === 'textarea' && ((value === null || value === undefined) || value == '')) {
        self.form.get(field.name).setValue(' ');
      }

      fields[field.name] = value

    });

    return fields;
  }

    public empty() {
        this.form = this.fb.group({});
        this.fields = [];
    }

    public reset(){
        if(this.form !== undefined)
            this.form.reset();
    }

    public disableForm(): void {
      this.form.disable();
    }

    public enableForm(): void {
      this.form.enable();
    }

}
