import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { isObject } from 'rxjs/internal-compatibility';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { ViewerService } from './viewer.service';
import { ErrorMessageComponent } from '../messages/error-message/error-message.component';

@Component({
  selector: 'app-viewer-dialog',
  templateUrl: './viewer-pdf-dialog.component.html',
  styleUrls: ['./viewer-pdf-dialog.component.css']
})
export class ViewerPdfDialogComponent implements OnInit {
    @ViewChild(ErrorMessageComponent, { static: true }) errorMessageComponent: ErrorMessageComponent;
    @ViewChild('pdfJsViewerComponent') pdfJsViewerComponent;

    @Input() documentTitle = '';

    pdfjsAssetsPath = environment.pdfjsAssetsPath;
    config: any;
    error: any;
    src: Uint8Array;
    pdf: any;
    document;
    loading = true;

    constructor(
            public dialogRef: MatDialogRef<ViewerPdfDialogComponent>,
            private viewerService: ViewerService,
            @Inject(MAT_DIALOG_DATA) public dialogData
    ) { }

    ngOnInit() {
        this.document = this.dialogData;

        this.loading = true;

        this.config = ( this.dialogData.config === undefined || this.dialogData.config === null )
                ? null : ( isObject(this.dialogData.config) ) ? this.dialogData.config : JSON.parse(this.dialogData.config);

        if (this.dialogData.hasOwnProperty('filename')) {
            this.documentTitle = this.dialogData.filename;
        }

        this.get64AndOpenPdf();

    }

    base64ToArrayBuffer(base64) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer['Uint8Array'];
    }

    get64AndOpenPdf() {
        this.config = ( this.document.config === undefined || this.document.config === null )
                ? null : ( isObject(this.document.config) ) ? this.document.config : JSON.parse(this.document.config);

        this.viewerService.getPdf64(this.document.id, this.document.repositoryType).subscribe(response => {
            this.loading = false;

            if (response.status) {
                this.src = Uint8Array.from(atob(response.document64), c => c.charCodeAt(0)); // works
            } else {
                this.errorMessageComponent.setErrorMessage(response.error);
            }

        }, error => {
            this.loading = false;
            this.errorMessageComponent.setErrorMessage(error);
        });
    }

    onDocumentLoad() {
        /**
         * ACTIVA O DESACTIVA LA SELECCIÓN DE TEXTO
         */
        const iframDoc = this.pdfJsViewerComponent.iframe.nativeElement.contentWindow.document;

        const content = iframDoc.getElementById('mainContainer');

        if (content && !this.canCopyText()) {
            content.classList.add('no-select-text');
        }
    }

    canCopyText(): boolean {
        return this.document.canCopyText == true;
    }

    canDownload(): boolean {
        return this.document.canDownload == true;
    }

    canPrint(): boolean {
        return false;
    }
}
