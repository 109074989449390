import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalstorageService } from "../_services/Localstorage.service";
import { CAT_SYSTEM_USER_ROLE } from "../_constants/CatSystemUserRoleConstants";
import { UsersService } from "../_services/users.service";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(
      private sessionStorage: LocalstorageService,
      private userService: UsersService,
      private router: Router
  ) {

  }
  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      try {
        if ( this.userService.userData.systemRole.code === CAT_SYSTEM_USER_ROLE.ADMIN) {
          return true;
        }

        this.router.navigate(['/panel']);

        return false;
      } catch {
        console.error('ERROR: "systemRole" no ha sido encontrado, porfavor inicie sesion');
        this.router.navigate(['/login']);
        return false;
      }
  }

  canActivateChild(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(route, state);
  }
}
