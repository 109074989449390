import { Action } from '@ngrx/store';

export const CLEAR_ALL_DATA = '[Form] Clear Data';
export const SET_OPENING_DATE_EXP = '[Form] Set Opening Date Exp';
export const SET_YEAR_IN_TRAM = '[Form] Set Year In Tram';

export class SetOpeningDateExp implements Action {
    readonly type = SET_OPENING_DATE_EXP;

    constructor(public payload: string) {}
}

export class SetYearInTram implements Action {
    readonly type = SET_YEAR_IN_TRAM;

    constructor(public payload: number) {}
}

export class ClearAll implements Action {
    readonly type = CLEAR_ALL_DATA;

    constructor() {}
}

export type Actions = ClearAll
        | SetOpeningDateExp
        | SetYearInTram;
