import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "../reducers";


export const notificationFeature = createFeatureSelector<State>('editor');

const notificationState = createSelector(notificationFeature, state => {
    return state.notification;
});

export const getTotalUnreadNotifications = createSelector(
  notificationState,
    state => {
        return state.notifications.filter(notification => !notification.open).length;
    }
);

export const getNotifications = createSelector(
  notificationState,
  state => {
    return state.notifications;
  }
);
