import * as FormAction from '../actions/Form.actions';

export interface State {
    opening_date_expedient: string | null;
    yearInTram: number | null;
}

const initialState: State = {
    opening_date_expedient: null,
    yearInTram: null,
};

export function reducer(state = initialState, action: FormAction.Actions): any {

    switch (action.type) {
        case FormAction.SET_OPENING_DATE_EXP:
            return { ...state, opening_date_expedient: action.payload };

        case FormAction.SET_YEAR_IN_TRAM:
            return { ...state, yearInTram: action.payload };

        case FormAction.CLEAR_ALL_DATA:
            return { ...state, ...initialState };

        default:
            return state;
    }
}
