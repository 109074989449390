import { Action } from '@ngrx/store'
import * as MailboxActions from './../actions/mailbox.actions'
import { ProcessModel } from "../../_models/process.model";
import { MailboxModel } from "../../mailboxes/_model/Mailbox.model";

export interface State {
  process: Array<ProcessModel>,
  columnFiltersSelected: Array<any>,
  mailboxSubjects: Array<any>,
  mailboxUnread: Array<MailboxModel>,
  currentOrganizationalUnitId: number,
  isLoadedUnreadOffices: boolean,
  isCurrentEditorRoute: boolean
}

const initialState: State = {
  process: [],
  columnFiltersSelected: [],
  mailboxSubjects: [],
  mailboxUnread: null,
  currentOrganizationalUnitId: null,
  isLoadedUnreadOffices: false,
  isCurrentEditorRoute: false
};

export function reducer(state = initialState, action: MailboxActions.Actions) : any {

    switch(action.type) {
        case MailboxActions.STORE_PROCESS:
            return {...state, process: action.payload};
        case MailboxActions.CLEAR_ALL_PROCESS:
            return {...state, process: []};
        case MailboxActions.STORE_COLUMN_FILTERS_SELECTED:
            return {...state, columnFiltersSelected: action.payload};
        case MailboxActions.GET_COLUMN_FILTERS_SELECTED:
            return {...state.columnFiltersSelected};
        case MailboxActions.CLEAR_COLUMN_FILTERS_SELECTED:
            return {...state, columnFiltersSelected: []};
        case MailboxActions.STORE_MAILBOX_SUBJECTS:
            return {...state, mailboxSubjects: action.payload};
        case MailboxActions.GET_MAILBOX_SUBJECTS:
            return {...state.mailboxSubjects};
        case MailboxActions.CLEAR_MAILBOX_SUBJECTS:
            return {...state, mailboxSubjects: []};
        case MailboxActions.SUBTRACT_COUNT_UNREAD_MAILBOX: {
          const mailboxState = state.mailboxUnread.map(mailbox => {
            if ( mailbox.code == action.code
              && mailbox.mailboxType == action.mailboxType
              && mailbox.organizationalUnitId == action.organizationalUnitId ) {

              let totalUnread = mailbox.mailbox.filter(participant => participant.was_read == false && participant.office_id != action.office_id).length;
              let total_ = ((totalUnread - 1) < 0 ) ? 0 : totalUnread - 1;

              return {...mailbox, total: total_}
            }

            return mailbox;
          });
          return {...state, mailboxUnread: mailboxState};
        }
        case MailboxActions.SET_CURRENT_ORGANIZATIONALUNITID:
            return {...state, currentOrganizationalUnitId: action.organizationalUnitId};
      case MailboxActions.GET_UNREAD_OFFICE_MAILBOX:
            return {...state.mailboxUnread };
      case MailboxActions.STORE_UNREAD_OFFICE_MAILBOX:
            return {...state, mailboxUnread: action.payload};
      case MailboxActions.SET_IS_CURRENT_EDITOR_ROUTE: {
        return { ...state, isCurrentEditorRoute: action.isEditorRoute }
      }
      case MailboxActions.ALREADY_LOADED_UNREAD_OFFICES:
        return {...state, isLoadedUnreadOffices: action.isLoaded};
        default:
            return {...state};
    }
}


