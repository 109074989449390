import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, Output } from '@angular/core';
import { UsersService } from "../../_services/users.service";
import { ErrorMessageComponent } from "../../messages/error-message/error-message.component";
import { MembersDialogComponent } from "../members/members-dialog.component";
import { TaskService } from "../../_services/task.service";
import { TaskAnswerDialogComponent } from "../task-answer/task-answer-dialog.component";
import { faReply, faUser, faRedo, faClock, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { WarningConfirmationDialogComponent } from "../../dialog/warning-confirmation-dialog/warning-confirmation-dialog.component";
import { TaskModel } from "../_models/task.model";
import * as MailboxSelector from "../../_store/selectors/mailbox.selector";
import { filter, take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as MailboxReducer from "../../_store/reducers/mailbox.reducer";
import * as SubjectActions from '../../_store/actions/subject.actions';
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import * as SubjectSelector from '../../_store/selectors/subject.selector';
import { Observable } from "rxjs";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { isObject } from "rxjs/internal-compatibility";
import { UserModel} from "../../_models/user.model";
import { AddresseeModel } from '../../_models/addresseeModel';

@Component({
  selector: 'app-subject-tasks',
  templateUrl: './subject-tasks.component.html',
  styleUrls: ['./subject-tasks.component.css']
})
export class SubjectTasksComponent implements OnInit, OnChanges {
    @Output() canDisplayTasks: boolean = false;
    @Input() recipient: AddresseeModel;
    @ViewChild(ErrorMessageComponent) errorMessage;
    faOrganizationalUnit = faBuilding;
    faClock = faClock;

    tasks: Observable<Array<TaskModel>>;
    subjectRequestId;
    faReopen = faRedo;
    faUser = faUser;
    organizationalUnitUsers;
    answerIcon = faReply;
    organizationalUnitId: number = null;

    constructor(
        public userService: UsersService,
        private dialog: MatDialog,
        private taskService: TaskService,
        private store$: Store<MailboxReducer.State>,
        private subject$: Store<SubjectReducer.State>
    ) {
    }

    ngOnInit() {
        this.store$.select(MailboxSelector.getCurrentOrganizationalUnitId)
            .pipe(take(1))
            .subscribe(
                response => {
                    this.organizationalUnitId = response;

                    if(this.userService.isTitular(this.organizationalUnitId) || this.userService.isAsistente(this.organizationalUnitId)) {
                        this.getOperadoresByUnidadOrganizacional();
                    }
                }
            );

        this.subject$.select(SubjectSelector.getCurrentSubject).pipe(
            take(1),
            filter(data => data !== null)
        )
            .subscribe(subject => {
                this.subjectRequestId = subject.id;
        });

        this.tasks = this.subject$.select(SubjectSelector.getSubjectTasks);
    }
    private getOperadoresByUnidadOrganizacional(){
      this.userService.getOperadoresByUnidadOrganizacional(this.organizationalUnitId).subscribe(
          response => {
            if( response['status'] ){
              this.organizationalUnitUsers = response['users'];
            }else{
              this.errorMessage.setErrorMessage(response);
            }
          },
          error => {
              this.errorMessage.setErrorMessage(error);
          }
      );
    }

    /**
     *
     * @param task
     */
    canSeeTask(task): boolean {
        return ((this.userService.isTitular(this.organizationalUnitId) || this.userService.isAsistente(this.organizationalUnitId))
            || task.userAssigned !== null && task.userAssigned.id === this.userService.userData.id);
    }

    /**
     *
     * @param task
     */
    canAssignTask(task: TaskModel): boolean {
        if ((this.userService.isTitular(this.organizationalUnitId) || this.userService.isAsistente(this.organizationalUnitId) ) && task.completed == false ) {
           if (task.sender_user_id === this.userService.userData.id) {
               return false;
           } else {
               return true;
           }
        } else if (task.completed == false ) {
            if (task.userAssigned.id === this.userService.userData.id) {
                return false;
            } else {
                return false;
            }
        }
    }

    /**
     *
     * @param task
     */
    canFinishTask(task): boolean {
        if ((this.userService.isTitular(this.organizationalUnitId) || this.userService.isAsistente(this.organizationalUnitId) ) && task.completed == false ) {
            if (task.sender_user_id === this.userService.userData.id) {
                return true;
            } else {
                return true;
            }
        } else if (task.completed == false ) {
            if (task.userAssigned.id === this.userService.userData.id) {
                return true;
            } else {
                return false;
            }
        }
    }

    /**
     *
     * @param task
     */
    canReopenTask(task): boolean {
        return ((this.userService.isTitular(this.organizationalUnitId) || this.userService.isAsistente(this.organizationalUnitId))
            && task.completed == true);
    }

    showUsersDialog(task){
        let dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {users: this.organizationalUnitUsers};
        dialogConfig.width = '500px';
        dialogConfig.height = '250px';
        // dialogConfig.maxHeight = '700px';
        // dialogConfig.panelClass = 'dialog-confirmation-class';

        const dialog = this.dialog.open(MembersDialogComponent, dialogConfig);

        dialog.afterClosed().subscribe(userSelected => {
            if(isObject(userSelected)){

                let params = {
                    taskId: task.id,
                    userId: userSelected.id,
                    subjectRequestId: this.subjectRequestId
                };

                this.taskService.assignUser(params).subscribe(
                    response => {
                        if(response['status']){

                            /**
                             * Link user with subject this will show the assigned user in the interface
                             */
                            // task = { ...task, userAssigned: userSelected, addressee_id: userSelected.id };
                            this.subject$.dispatch(new SubjectActions.AsignTaskToUser(task, userSelected));

                            // this.tasks = this.tasks.map(data => {
                            //
                            //     if (data.id === task.id) {
                            //         return task;
                            //     }
                            //
                            //     return data;
                            // });

                        }else{
                            this.errorMessage.setErrorMessage(response);
                        }
                    },
                    error => {
                        console.error(error);
                        this.errorMessage.setErrorMessage(error);
                    }
                );
            }
        });
    }

    openTaskAnswerDialog(task) {
      let dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        task: task,
        recipient: this.recipient
      };
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '800px';

      const dialog = this.dialog.open(TaskAnswerDialogComponent, dialogConfig);

      dialog.afterClosed().subscribe(answer => {

        if (isObject(answer)) {
          this.subject$.dispatch(new SubjectActions.AddTaskAnswer(task, answer));
        }
      });

    }

    /**
     *
     * @param task
     */
    finishTask(task) {
        let dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {title: 'Confirmación', textContent: 'Esta a punto de marcar esta tarea como completada.'};
        dialogConfig.maxWidth = '600px';
        dialogConfig.width = '400px';
        dialogConfig.panelClass = 'dialog-confirmation-class';

        const dialog = this.dialog.open(WarningConfirmationDialogComponent, dialogConfig);

        dialog.afterClosed().subscribe(userConfirmation => {
            if (userConfirmation !== null && userConfirmation !== undefined && userConfirmation.confirmation) {
                this.taskService.markAsCompleted(task.id).subscribe(
                    response => {
                        if(response['status']) {
                            // task = {...task, completed: true}
                            this.subject$.dispatch(new SubjectActions.UpdateTaskStatus(task, true));
                        } else {
                            this.errorMessage.setErrorMessage(response);
                        }
                    },
                    error => {
                        console.error(error);
                        this.errorMessage.setErrorMessage(error);
                    }
                );
            }
        });
    }

    /**
     *
     * @param task
     */
    reopenTask(task: TaskModel) {
        let dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {title: 'Confirmación', textContent: 'Esta a punto de reabrir esta tarea. ¿Desea continuar?'};
        dialogConfig.maxWidth = '600px';
        dialogConfig.width = '400px';
        // dialogConfig.maxHeight = '700px';
        dialogConfig.panelClass = 'dialog-confirmation-class';

        const dialog = this.dialog.open(WarningConfirmationDialogComponent, dialogConfig);

        dialog.afterClosed().subscribe(userConfirmation => {
            if (userConfirmation !== null && userConfirmation !== undefined && userConfirmation.confirmation) {
                this.taskService.reopenTask(task.id).subscribe(
                    response => {
                        if(response['status']) {
                            // task = {...task, completed: false}
                            this.subject$.dispatch(new SubjectActions.UpdateTaskStatus(task, false));
                        } else {
                            this.errorMessage.setErrorMessage(response);
                        }
                    },
                    error => {
                        console.error(error);
                        this.errorMessage.setErrorMessage(error);
                    }
                );
            }
        });
    }

    userNameDisplay(user: UserModel): string {
        return user.name + " " + user.last_name + ' ' + ((user.mothers_last_name !== null) ? user.mothers_last_name : '');
    }



    ngOnChanges(changes: SimpleChanges): void {

    }

}
