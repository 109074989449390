import * as ArchivalActions from '../actions/archival.actions';
import { SubjectRequestModel } from "../../_models/SubjectRequest.model";
import {RepositoryModel} from "../../../../projects/archivistica/src/app/_core/_models/Repository.model";
import {
  DocumentaryDispositionModel
} from "../../../../projects/archivistica/src/app/_core/_models/DocumentaryDisposition.model";
import {
  OrganizationalUnitModel
} from "../../../../projects/archivistica/src/app/_core/_models/OrganizationalUnit.model";

export interface State {
  subjectRequest: SubjectRequestModel,
  isLoading: boolean,
  isNewExpedient: boolean,
  isValidSerie: boolean,
  isValidTemplate: boolean,
  templateMetadata: Array<any>,
  templateMetadataValue: Array<any>, /** Valores de los metadatos ingresados por el usuario */
  serieData: {
    instanceName: string
    repository: RepositoryModel,
    organizationalUnit: OrganizationalUnitModel,
    serie: DocumentaryDispositionModel,
    legajoName: string
  },
}

const initialState: State = {
  subjectRequest: null,
  isLoading: false,
  isNewExpedient: true,
  isValidSerie: false,
  isValidTemplate: false,
  templateMetadata: null,
  templateMetadataValue: null,
  serieData: null,
};

export function reducer(state = initialState, action: ArchivalActions.Actions) : any {

    switch(action.type) {
        case ArchivalActions.STORE_SUBJECT_REQUEST:
            return {...state, subjectRequest: action.subjectRequest};
        case ArchivalActions.SET_IS_NEW_EXPEDIENT:
          return {...state, isNewExpedient: action.isNewExpedient};
        case ArchivalActions.STORE_TEMPLATE_METADATA:
          return {...state, templateMetadata: action.templateMetadata};
        case ArchivalActions.STORE_TEMPLATE_METADATA_VALUE:
          return {...state, templateMetadataValue: action.templateMetadata};
        case ArchivalActions.SET_IS_VALID_SERIE:
          return {...state, isValidSerie: action.isValidSerie};
        case ArchivalActions.SET_IS_VALID_TEMPLATE:
          return {...state, isValidTemplate: action.isValidTemplate};
        case ArchivalActions.SET_LOADING_STATE:
          return {...state, isLoading: action.setLoading};
        case ArchivalActions.STORE_SERIE_DATA:
          return {...state, serieData: action.serieData};
        case ArchivalActions.CLEAR_ALL:
          return {...initialState};
        default:
            return state;
    }
}


