import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MANUAL, SYSTEM_MANUAL } from '../_constants/SystemSettingsConstants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ViewerPdfDialogComponent } from './viewer-pdf-dialog.component';
import { SystemSettingsService } from '../_services/system-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {
  @Output() open: EventEmitter<boolean> = new EventEmitter();

  constructor(
          private http: HttpClient,
          public systemSettingsService: SystemSettingsService,
          private dialog: MatDialog,
  ) { }

  url = environment.apiUrl + '/api/viewer';

  openDocument(repositoryType, document) {
    console.log(repositoryType);
    console.log(document);
    document = { ...document, repositoryType: repositoryType };
    this.open.emit(document);
  }

  getPdf64(documentId, repositoryType): Observable<any> {
    return this.http.get(this.url + '/document/pdf/base64/' + documentId + '/' + repositoryType);
  }

  public openSystemManual() {
    const value = this.systemSettingsService.systemSettings.find(setting => setting.code === SYSTEM_MANUAL);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = '100%';
    dialogConfig.maxHeight = '95%';
    dialogConfig.width = '90%';
    dialogConfig.height = '95%';
    dialogConfig.panelClass = 'viewer-dialog';
    dialogConfig.data = {
      filename: value.title,
      config: undefined,
      id: 0,
      repositoryType: MANUAL
    };

    this.dialog.open(ViewerPdfDialogComponent, dialogConfig);
  }
}
