import { Injectable } from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {LocalstorageService} from '../_services/Localstorage.service';
import {EDITOR_ACTIVATED} from '../_constants/SystemSettingsConstants';
import {SystemSettingsService} from '../_services/system-settings.service';

@Injectable({
  providedIn: 'root'
})
export class EditorGuard implements CanActivate, CanActivateChild {
  constructor(
    private sessionStorage: LocalstorageService,
    private systemSettings: SystemSettingsService,
    private router: Router
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(this.systemSettings.isEditorActive()){
      return true;
    }
    this.router.navigate(['/panel']);
    return false;
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

}
