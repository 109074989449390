import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PanelComponent }       from './panel/panel.component';
import { NewUserComponent } from "./register/new-user.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./forgot-password/reset-password.component";
import { AuthGuard } from "./_guards/auth.guard";
import { AdminGuard } from "./_guards/admin.guard";
import { HasTitularRoleGuard } from "./_guards/HasTitularRole.guard";
import { EditorProject } from "../../projects/editor/src/app/editor-app.module";
import { EditorGuard } from './_guards/editor.guard';
import { ArchivalProject } from "../../projects/archivistica/src/app/archival-app.module";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'panel',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: 'register/newUser',
    component: NewUserComponent},
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent},
  {
    path: 'resetPassword/:token/:email',
    component: ResetPasswordComponent
  },
  {
    path: 'panel',
    component: PanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        // canActivateChild: [AuthGuard],
        children: [
          {
            path: '',
            redirectTo: 'inbox',
            pathMatch: 'full'
          },
          {
            canActivate: [AdminGuard],
            path: 'users',
            pathMatch: 'prefix',
            loadChildren: './admin-users/admin-users.module#AdminUsersModule'
          },
          {
            canActivate: [AdminGuard],
            path: 'companies',
            loadChildren: './admin-companies/admin-companies.module#AdminCompaniesModule'
          },
          {
            canActivate: [AdminGuard],
            path: 'process',
            loadChildren: './process-module/process.module#ProcessModule'
          },
          {
            canActivate: [AdminGuard],
            path: 'organizationalUnit',
            loadChildren: './organizational-unit/organizational-unit.module#OrganizationalUnitModule',
          },
          {
            path: 'newSubject',
            loadChildren: './new-subject/new-subject.module#NewSubjectModule',
          },
          {
            path: 'newSubject/:process_id/:organizationalUnitId',
            loadChildren: './new-subject/new-subject.module#NewSubjectModule',
          },
          {
            path: 'mailbox',
            pathMatch: 'prefix',
            loadChildren: './mailboxes/mailboxes.module#MailboxesModule'
          },
        ]
      }
    ]
  },
  {
    path: 'userProfile',
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
  },
  {
    canActivate: [HasTitularRoleGuard],
    path: 'archivistica',
    loadChildren: () => import('../../projects/archivistica/src/app/archival-app.module').then(m => m.ArchivalAppModule)
  },
  {
    canActivate: [AdminGuard],
    loadChildren: './system-settings/system-settings.module#SystemSettingsModule',
    path: 'settings',
    // component: SystemSettingsComponent
  },
  {
    canActivate: [HasTitularRoleGuard],
    loadChildren: './reports/reports.module#ReportsModule',
    path: 'reports',
    // component: SystemSettingsComponent
  },
  {
    canActivate: [EditorGuard],
    path: 'editor',
    loadChildren: '../../projects/editor/src/app/editor-app.module#EditorAppModule'
    //loadChildren: () => import('../../projects/editor/src/app/editor-app.module').then(m => m.EditorAppModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search-engine/search-engine.module').then(m => m.SearchEngineModule)
  },
  {
    path: 'requestFolios',
    loadChildren: () => import('./request-folios/request-folios.module').then(m => m.RequestFoliosModule),
  },
  {
    path: 'requestFoliosEnvio',
    loadChildren: () => import('./request-folios/request-folios.module').then(m => m.RequestFoliosModule),
  },
  {
    path: 'requestFoliosCancel',
    loadChildren: () => import('./request-folios/request-folios.module').then(m => m.RequestFoliosModule),
  },
  {
    path: 'requestFoliosOffice',
    loadChildren: () => import('./request-folios/request-folios.module').then(m => m.RequestFoliosModule),
  },
];

@NgModule({
    imports: [
      RouterModule.forRoot(routes),
      EditorProject.forRoot(),
      ArchivalProject.forRoot(),
    ],
    exports: [ RouterModule ]
})

export class AppRoutingModule {

}
