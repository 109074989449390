import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { InboxComponent } from './inbox/inbox.component';
// import { InboxoutComponent } from './inboxout/inboxout.component';
import { RouterModule, Routes } from '@angular/router';
// import { MailboxComponent } from './mailbox/mailbox.component';
// import { PanelComponent } from './panel/panel.component';
// import { MailboxTreeComponent } from './mailbox-tree/mailbox-tree.component';
// import { PanelNavbarComponent } from './panel/panel-navbar/panel-navbar.component';
// import { TreeModule } from 'angular-tree-component';
// import { SharedModule } from "../../../../../src/app/shared.module";

export const ROUTES: Routes = [
    // {
    //   path: '',
    //   component: PanelComponent,
    //   children: [
    //     {
    //       path: 'inbox/:mailboxStatus/:organizationalUnitId',
    //       component: InboxComponent
    //     },
    //     {
    //       path: 'inboxout/:mailboxStatus/:organizationalUnitId',
    //       component: InboxoutComponent
    //     }
    //   ]
    // },
];

@NgModule({
    declarations: [
      // InboxComponent,
      // InboxoutComponent,
      // MailboxComponent,
      // PanelComponent,
      // MailboxTreeComponent,
      // PanelNavbarComponent
    ],
  imports: [
    // CommonModule,
    // RouterModule.forChild(ROUTES),
    // SharedModule,
    // TreeModule
  ],
  exports: [
    // MailboxTreeComponent
  ]
})
export class MailboxesModule {
}
