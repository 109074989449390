import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalstorageService } from "../_services/Localstorage.service";
import { UsersService } from "../_services/users.service";

@Injectable({
  providedIn: 'root'
})
export class HasTitularRoleGuard implements CanActivate, CanActivateChild {
  constructor(
      private sessionStorage: LocalstorageService,
      private userService: UsersService,
      private router: Router
  ) {

  }
  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if ( this.userService.isAdmin() || (this.userService.hasRoleTitular() || this.userService.hasAssistantRole())) {
      return true;
    }

    this.router.navigate(['/panel']);

    return false;
  }

  canActivateChild(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(route, state);
  }
}
