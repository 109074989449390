import * as TreeExpedientAction from '../actions/TreeExpedient.actions';

export interface State {
  treeExpedient: any;
  treeSeries: any;
  expedientOnSelected: any;
}

const initialState: State = {
  treeExpedient: null,
  treeSeries: null,
  expedientOnSelected: {
    nodes: [],
    state: '',
    unit_id: null,
    serie_id: null
  },
};

export function reducer(state = initialState, action: TreeExpedientAction.Actions): any {

  switch (action.type) {
    case TreeExpedientAction.STORE_DATA:
      return {...state, treeExpedient: action.payload};
    case TreeExpedientAction.STORE_SERIES:
      return {...state, treeSeries: action.payload};
    case TreeExpedientAction.SELECTED_DATA:
      return {...state, expedientOnSelected: action.payload};
    case TreeExpedientAction.CLEAR_DATA:
      return [];
    default:
      return state;
  }
}
