import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewerPdfDialogComponent } from "./viewer-pdf-dialog.component";
import { ViewerService } from "./viewer.service";
import { ErrorMessageComponent } from "../messages/error-message/error-message.component";
import { UsersService } from "../_services/users.service";
import { isObject } from "rxjs/internal-compatibility";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from "@angular/material/dialog";


@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit, OnDestroy {
  @ViewChild(ErrorMessageComponent) errorMessage;
  @Input() documentSrc;

  subscription: any;

  constructor(
      private dialog: MatDialog,
      private viewerService: ViewerService,
      private userService: UsersService,
      @Inject(MAT_DIALOG_DATA) public subject
  ) { }

  ngOnInit() {
    // console.log("ngOnInit document viewer");
    // console.log("subscribing");

    this.subscription = this.viewerService.open.subscribe(document => { /** each time users open documents */
        console.log("open document...");
        console.log(document);


      if(this.isPdf(document.filename)){
          this.openPdf(document);
      }

    });
  }

  openPdf(document){

    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = document;
    dialogConfig.maxWidth = '100%';
    dialogConfig.maxHeight = '100%';
    dialogConfig.width = '90%';
    dialogConfig.height = '100%';
    dialogConfig.panelClass = 'viewer-dialog';

    const dialog = this.dialog.open(ViewerPdfDialogComponent, dialogConfig);
  }

  fileExtension(filename): string{
    let extn = filename.split(".").pop();

    return extn;
  }

  isPdf(filename): boolean{
    return (this.fileExtension(filename).toUpperCase() === 'PDF')
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

}
