import { UserModel } from "./user.model";
import { OrganizationalUnitModel } from "./SubjectRequest.model";
import { SubjectMessageInterface } from "./SubjectMessage.interface";

export class AddresseeModel {
    id: number;
    addressee_id: number;
    addresseeType: string;
    CC: boolean;
    CCC: boolean;
    TURNAR: boolean;
    allow_close_subject: boolean;
    subjectRequest_id: number;
    cat_subjectStatus_id: number;
    isSender: boolean;
    resuelto: boolean;
    resuelto_datetime: string;
    public wasRead: boolean;
    user_id: number;
    organizationalUnit_id: number;
    created_at: string;
    updated_at: string;
    mailbox?: Array<any>;
    userTasks?: Array<any>
    organizationalUnitTasks? : Array<any>;
    organizationalUnit?: OrganizationalUnitModel;
    last_seen: string;
    user?: UserModel;
    step: number;
    sender_organizationalUnit_id: number;
    sender_user_id: number;
    rejected: boolean;
    subjectMessage: Array<SubjectMessageInterface>;
    subjectRequest: object;
    process: Array<any>;
    metadataValue: Array<any>;
    first_view: string;
    responding_with_office: boolean;
    office_id: number;
}
