import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appDivPrivateSubject]'
})
export class DivPrivateSubjectDirective {
  @Input() titularOnly: boolean;
  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(){
    if(this.titularOnly){
      this.el.nativeElement.classList.add('private-p');
      let b = this.renderer.createElement('b');
      b.innerText = "*Este es un Asunto Privado";
      this.renderer.appendChild(this.el.nativeElement, b);
    }else{
      this.el.nativeElement.remove();
    }
  }

}
