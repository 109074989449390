import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FieldConfig } from "../../_models/field.interface";
import { DynamicFormBuilder } from "../../components/DynamicFormBuilder";
import { ActivatedRoute, Router } from "@angular/router";
import { ProcessService } from "../../_services/process.service";
import { UsersService } from "../../_services/users.service";
import { faUserSecret} from "@fortawesome/free-solid-svg-icons";
import { SubjectService } from "../../_services/subject.service";
import * as MailboxSelector from "../../_store/selectors/mailbox.selector";
import { filter, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as MailboxReducer from "../../_store/reducers/mailbox.reducer";
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import * as SubjectSelector from '../../_store/selectors/subject.selector';
import * as SubjectActions from '../../_store/actions/subject.actions';
import { SubjectRequestModel } from "../../_models/SubjectRequest.model";
import { Observable, Subject } from 'rxjs';
import { Preview } from "../../mailboxes/Preview";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SystemSettingsService } from "../../_services/system-settings.service";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { LocalstorageService } from "../../_services/Localstorage.service";
import { GeneratepdfService } from "../../_services/generatepdf.service";
import * as NewOfficeReducer from "../../../../projects/editor/src/app/_store/reducers/new-document.reducer";
import { saveAs } from '@progress/kendo-file-saver';
import { SubjectRequestService } from "../../_services/subject-request.service";
import { MAILBOX_INBOX_CODE } from '../../../../projects/editor/src/app/editor/panel/MailboxesNode';

@Component({
  selector: 'app-inbox-preview-dialog',
  templateUrl: './inbox-preview-dialog.component.html',
  styleUrls: ['./inbox-preview-dialog.component.css']
})
export class InboxPreviewDialogComponent extends Preview implements OnInit, OnDestroy {
    destroy: Subject<void> = new Subject();
    mailboxType = MAILBOX_INBOX_CODE;
    faUserSecret = faUserSecret;
    icoPdf = faFilePdf;
    formConfig: FieldConfig[] = [];
    organizationalUnitId: number = null;
    fullMetadatos: any;
    systemSettings: Object = {};
    disabled: boolean;
    showLoading$: Observable<boolean>;
    TaskUser = { onTask : false, onUserTask : false,total: 0, colorbk: 'red' };

  constructor(
      private _GeneratepdfService: GeneratepdfService,
      private sessionStorage: LocalstorageService,
      public systemSettingsService: SystemSettingsService,
      public subjectRequestService: SubjectRequestService,
      private processService: ProcessService,
      public activatedRoute: ActivatedRoute,
      private formBuilder: DynamicFormBuilder,
      public userService: UsersService,
      public dialog: MatDialog,
      private store$: Store<MailboxReducer.State>,
      public dialogRef: MatDialogRef<InboxPreviewDialogComponent>,
      private subjectService: SubjectService,
      public subject$: Store<SubjectReducer.State>,
      public router: Router,
      public newOffice$: Store<NewOfficeReducer.State>,
      @Inject(MAT_DIALOG_DATA) public dialogData: { subjectRequest: SubjectRequestModel }
  ) {
      super(systemSettingsService, router, activatedRoute, dialogRef, newOffice$, subject$, dialog, userService, subjectRequestService);
  }

  ngOnInit() {
    this.systemSettings = this.systemSettingsService.getLoginSettings();
    this.store$.select(MailboxSelector.getCurrentOrganizationalUnitId)
        .pipe(
            takeUntil(this.destroy),
            filter(data => data !== null)
        )
        .subscribe(
            response => {
                this.organizationalUnitId = response;
            }
        );

    this.subjectService.getCurrentSubject().pipe(
      takeUntil(this.destroy),
      filter(response => response !== null && response !== undefined)
    ).subscribe(response => {
      this.subjectRequest = response;
      this.mapFieldsAndBuildForm(response);
    });

    this.showLoading$ = this.subject$.select(SubjectSelector.getIsLoadingPDF);
    this.isLoading$ = this.subject$.select(SubjectSelector.getIsLoading);

    this.subject$.select(SubjectSelector.getIschangeTask).pipe(
      takeUntil(this.destroy),
      filter(data => data !== null)
      ).subscribe(subject => {
        if (subject.ischange === true) {
          this.TaskUser = this.TaskUserOpened(subject);
        }
      });

      this.subject$.dispatch(new SubjectActions.SetIsChangeTask(true));

      this.subject$.select(SubjectSelector.getIsLoadingFlowChart).pipe(takeUntil(this.destroy),
              filter(data => data !== null)).subscribe(loading => {
          if (loading) {
              this.scrollToElement();
          }
      });
  }

  private TaskUserOpened(subject): any {
    let color = 'red';
    const TaskOpened: any[] = subject.tasks.filter(task => task.completed == false);
    const UserTask: any[] = subject.tasks.filter(task =>
      {
        if (task.userAssigned === null) {
          return false;
        }
        return task.userAssigned.id === this.userService.userData.id && task.completed == false
      });

    const UserTaskAnswer: any[] = subject.tasks.filter(task =>
        {
          if (task.answers === null || task.answers.length === 0) {
            return false;
          }
          return true;
        });

    if (UserTaskAnswer.length > 0) {
      color = 'orangered';
    }
    this.subject$.dispatch(new SubjectActions.SetIsChangeTask(false));
    return {
      onTask : TaskOpened.length > 0,
      onUserTask : UserTask.length > 0,
      total: UserTask.length,
      colorbk: color
    };
  }

  buildSubjectForm() {
  }

  mapFieldsAndBuildForm(subjectRequest: SubjectRequestModel){
        let metadata = [];
        let metadataValue = subjectRequest.metadataValue;

        this.subjectRequest.process.metadata.forEach(function(field, index){
            let metadataFieldValue = metadataValue.find(metadataValueField => metadataValueField.metadata_id === field.id);

            if(metadataFieldValue === null || metadataFieldValue === undefined)
                return;

            field = {...field, value: metadataFieldValue.value};
            metadata.push(field);
        });
        this.fullMetadatos = metadata;
        this.dynamicForm.createControls(metadata);
        this.dynamicForm.disableForm();
    }

  openSubjectAnswerDialog() {
  }

  marcarResuelto(recipient) {
  }

  finalizarAsunto() {
  }

  avanzar() {
  }

  generatePdf(){
      this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(true));
      const params = {
        title: [this.subjectRequest.title],
        subjectRequest:  this.subjectRequest,
        fullMetadata:  this.fullMetadatos,
      };
      const nameFile = 'Asunto_' + this.subjectRequest.folio;
      this._GeneratepdfService.subjectReport(params).subscribe(response => {
        saveAs(response, nameFile);
        this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(false));
      }, error => {
        this.errorMessage.setErrorMessage(error);
        this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(true));
      });
    }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
