import { ModuleWithProviders, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { SharedArchivalModule } from './shared-archival.module';

// Redux configuration
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { metaReducers, reducers } from './_core/_store/reducers';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    StoreModule.forFeature('archival', reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),

    SharedArchivalModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class ArchivalAppModule { }

@NgModule({})
export class ArchivalProject {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ArchivalAppModule,
      providers: []
    };
  }
}
