export const INTERNAL_SUBJECT = 1;
export const EXTERNAL_SUBJECT = 2;
export const INTERNAL_SUBJECT_CODE = 'internal';
export const EXTERNAL_SUBJECT_CODE = 'external';
export const INTERNAL_SUBJECT_LABEL = 'Interno';
export const EXTERNAL_SUBJECT_LABEL = 'Externo';
export const TYPE_MAIL = {
  'input': 'entrada',
  'output': 'salida'
}
