import { Pipe, PipeTransform } from '@angular/core';
/*
 * Usage:
 *   value | substract : n
*/
@Pipe({name: 'semaforo'})
export class SemaforoPipe implements PipeTransform {
  transform(value: string): string {
  let resp = 'en tiempo';
    if (value === '<div class="subject-status-out-time almost-finished"></div>') {
       resp = 'por vencer'
    } else if (value === '<div class="subject-status-out-time outtime"></div>') {
      resp = 'vencido'
    } else if (value === '<div class="subject-status-out-time undefined-subject-date"></div>') {
      resp = 'indefinido'
    }
  return resp;
  }
}
