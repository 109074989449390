import * as SubjectTransferAction from '../actions/SubjectTransfer.actions';

export interface State {
    subject: any;
    series: any;
    organizationalUnitId: number;
    subjectTypeCode: string | null;
    paramsForMailbox: {
        organizationalUnitId: number
        subjectTypeCode: string | null
    };
}

const initialState: State = {
    subject: {},
    series: {},
    organizationalUnitId: 0,
    subjectTypeCode: null,
    paramsForMailbox: {
        organizationalUnitId: 0,
        subjectTypeCode: null
    }
};

export function reducer(state = initialState, action: SubjectTransferAction.Actions): any {

    switch (action.type) {
        case SubjectTransferAction.STORE_DATA:
            return { ...state, subject: action.payload };
        case SubjectTransferAction.SET_ORG_ID:
            return { ...state, organizationalUnitId: action.payload };
        case SubjectTransferAction.SET_SERIES:
            return { ...state, series: action.payload };
        case SubjectTransferAction.SET_SUBJECT_TYPE_CODE: {
            return { ...state, subjectTypeCode: action.subjectTypeCode };
        }
        case SubjectTransferAction.SET_SUBJECT__CODE_ORG_ID: {
            return { ...state, paramsForMailbox: action.payload };
        }
        case SubjectTransferAction.CLEAR_DATA:
            return { ...initialState };
        default:
            return state;
    }
}
