import * as NewDocumentActions from './../actions/new-document.actions'
import { UserRoleOrganizationalUnitModel } from '../../_models/UserRoleOrganizationalUnit.model';
import { OfficeModelInterface } from '../../_models/OfficeModelInterface';
import { CatParticipantActionsModel } from '../../_models/CatParticipantActions.model';
import { SubjectRequestModel } from "../../../../../../src/app/_models/SubjectRequest.model";

export interface State {
  participants: Array<UserRoleOrganizationalUnitModel>,
  metadata: Array<any>,
  documentFlowType: number,
  validParticipants: boolean,
  validDocumentMetadata: boolean,
  document: OfficeModelInterface,
  isLoading: boolean,
  catParticipantActions: Array<CatParticipantActionsModel>,
  respondSubject: { //Usado para responder asunto con oficio
    subjectRequest: SubjectRequestModel
  }
}

const initialState: State = {
  participants: null,
  metadata: null,
  documentFlowType: null,
  validParticipants: false,
  validDocumentMetadata: false,
  document: null,
  isLoading: false,
  catParticipantActions: null,
  respondSubject: {
    subjectRequest: null
  }
};

export function reducer(state = initialState, action: NewDocumentActions.Actions) : any  {

    switch(action.type) {
      case NewDocumentActions.CLEAR_ALL:
        return initialState;
      case NewDocumentActions.SET_VALID_PARTICIPANTS:
        return {...state, validParticipants: action.validParticipants };
      case NewDocumentActions.SET_VALID_DOCUMENT_METADATA:
        return {...state, validDocumentMetadata: action.documentMetadata };
      case NewDocumentActions.REMOVE_PARTICIPANT:
        return {...state, participants: state.participants.filter(participant => participant.id != action.participant.id) };
      case NewDocumentActions.UPDATE_PARTICIPANT:
        const participants = state.participants.map(participant => {
          if (participant.id == action.participant.id) {
            return action.participant;
          }
          return participant;
        });

        return {...state, participants: participants };
      case NewDocumentActions.ADD_PARTICIPANTS:
        const newParticipants = state.participants === null ? action.participants : action.participants.filter(newParticipant => {
          return state.participants.find(participant =>  participant.user.id == newParticipant.user.id) == undefined;
        });
        let currentParticipants = state.participants !== null ? [...state.participants] : [];
        return {...state, participants: currentParticipants.concat(newParticipants)};
      case NewDocumentActions.STORE_METADATA:
        return {...state, metadata: action.metadata};
      case NewDocumentActions.SET_DOCUMENT_FLOW_TYPE:
        return {...state, documentFlowType: action.documentFlowType};
      case NewDocumentActions.STORE_DOCUMENT:
        return { ...state, document: action.document };
      case NewDocumentActions.SET_IS_LOADING:
        return { ...state, isLoading: action.status };
      case NewDocumentActions.STORE_PARTICIPANT_ACTIONS:
        return { ...state, catParticipantActions: action.catParticipantActions }
      case NewDocumentActions.STORE_RESPOND_SUBJECT: {
        return { ...state, respondSubject: { subjectRequest: action.subjectRequest } }
      }
      default:
        return {...state};
    }
}


