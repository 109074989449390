import { Action } from '@ngrx/store';
import { SubjectSeverityModel } from "../../_models/SubjectSeverityModel";
import { DepartmentFolioModel } from "../../_models/DepartmentFolio.model";
import { RequestFolioModel } from "../../../../../../src/app/_models/RequestFolio.model";

/**
 * Clase que contiene todas las actions necesarias para el proceso de envío de un oficio a control de gestión (Stepper).
 */

export const ADD_RECIPIENTS             = '[New Subject] Add Recipients';
export const CLEAR_ALL_RECIPIENTS       = '[New Subject] Clear all recipientOptions';
export const STORE_ORGANIZATIONAL_UNITS = '[New Subject] Store Organizational Units';
export const STORE_USERS                = '[New Subject] Store users';
export const CLEAR_ALL                  = '[New Subject] Clear all';
export const STORE_SEVERITIES           = '[New Subject] Store Severities';
export const ADD_TO_SUBJECT_REQUEST_DATA ='[New subject] Add data to subject request entry';
export const CLEAR_SUBJECT_REQUEST_DATA = '[New subject] Clear subject request data';
export const STORE_METADATA             = '[New subject] Store Metadata';
export const STORE_DOCUMENT_SETTINGS    = '[New subject] Store Document Settings';
export const ADD_SERIE_EFIRMA           = '[New subject] Add serie efirma';
export const STORE_SUBJECT_TYPE         = '[New subject] Store SubjectType';
export const SET_CURRENT_ORGANIZATIONALUNITID = '[New subject] Store Current Organizational UnitID';
export const CLEAR_CREATING_SUBJECT_DATA= '[New subject] Clear Creating Subject Data';
export const SET_CURRENT_PROCESS_ID     = '[New subject] Set Current Process Id';
export const SET_RECIPIENT_TYPE         = '[New subject] Set Recipient Type';
export const STORE_SUBJECT_SETTINGS     = '[New subject] Store Subject Settings';
export const SET_ISLOADING              = '[New subject] Set Is loading';
export const STORE_ORIGIN_DEPARTMENT_FOLIO = '[New subject] Store Origin Department of Folio';
export const STORE_DESTIN_DEPARTMENT_FOLIO = '[New subject] Store Destin Department of Folio';
export const REMOVE_DESTIN_DEPARTMENT_FOLIO = '[New subject] Remove Destin Department Folio';
export const REMOVE_ORIGIN_DEPARTMENT_FOLIO = '[New subject] Remove Origin Department Folio';
export const SET_SEPARATED_FOLIO    = '[New subject] Set Use Separated Folio';

export class AddRecipientActions implements Action {
    readonly type = ADD_RECIPIENTS;

    constructor(public nodesObject, public nodesTask ,public nodesData) {}
}

export class ClearAllRecipientsAction implements Action {
    readonly type = CLEAR_ALL_RECIPIENTS;

    constructor() {}
}

export class StoreOrganizationalUnits implements Action {
    readonly type = STORE_ORGANIZATIONAL_UNITS;

    constructor(public payload) {}
}

export class StoreUsers implements  Action {
    readonly type = STORE_USERS;

    constructor(public payload) {}
}

export class ClearAll implements  Action {
    readonly type = CLEAR_ALL;

    constructor() {}
}

export class ClearCreatingSubjectData implements Action {
    readonly type = CLEAR_CREATING_SUBJECT_DATA;
    constructor() {}
}

export class StoreSubjectType implements Action {
    readonly type = STORE_SUBJECT_TYPE;
    constructor(public subjectType: string) {}
}

export class StoreMetadata implements Action {
    readonly type = STORE_METADATA;

    constructor(public processFields: any, public processFieldsIdValue, public defaultFields: any, public senderFields: any) {}
}

export class SetCurrentOrganizationalUnitId implements  Action {
    readonly type = SET_CURRENT_ORGANIZATIONALUNITID;

    constructor(public organizationalUnitId: number) {}
}

export class SetCurrentProcessId implements Action {
    readonly type = SET_CURRENT_PROCESS_ID;
    constructor(public processId){}
}

export class SetRecipientType implements Action {
    readonly type = SET_RECIPIENT_TYPE;
    constructor(public recipientType: string){}
}

export class StoreSubjectSettings implements Action {
    readonly type = STORE_SUBJECT_SETTINGS;
    constructor(public settings: any){}
}
export class StoreSeverities implements  Action {
    readonly type = STORE_SEVERITIES;

    constructor(public severities: Array<SubjectSeverityModel>) {}
}

export class AddToSubjectRequestData implements Action {
    readonly type = ADD_TO_SUBJECT_REQUEST_DATA;

    constructor(public payload: any) {}
}

export class ClearSubjectRequestData implements Action {
    readonly type = CLEAR_SUBJECT_REQUEST_DATA;

    constructor() {}
}

export class AddEfirma implements Action {
    readonly type = ADD_SERIE_EFIRMA;

    constructor(public payload) {}
}

export class SetIsLoading implements Action {
  readonly type = SET_ISLOADING;
  constructor(public isLoading: boolean) {
  }
}

export class StoreOriginDepartmentFolio implements Action {
  readonly type = STORE_ORIGIN_DEPARTMENT_FOLIO;
  constructor(public data: DepartmentFolioModel) {}
}

export class StoreDestinDepartmentFolio implements Action {
  readonly type = STORE_DESTIN_DEPARTMENT_FOLIO;
  constructor(public data: DepartmentFolioModel) {}
}

export class RemoveDestinDepartment implements Action {
  readonly type = REMOVE_DESTIN_DEPARTMENT_FOLIO;
  constructor() {}
}

export class RemoveOriginDepartment implements Action {
  readonly type = REMOVE_ORIGIN_DEPARTMENT_FOLIO;
  constructor() {}
}

/**
 * Folio separado seleccionado en paso 2
 */
export class SetSeparatedFolio implements Action {
  readonly type = SET_SEPARATED_FOLIO;
  constructor(public folio: RequestFolioModel | null) {
  }
}

export type Actions = AddRecipientActions
    | ClearAllRecipientsAction
    | StoreOrganizationalUnits
    | StoreUsers
    | ClearAll
    | StoreSeverities
    | AddToSubjectRequestData
    | ClearSubjectRequestData
    | AddEfirma
    | StoreSubjectType
    | StoreMetadata
    | SetCurrentOrganizationalUnitId
    | ClearCreatingSubjectData
    | SetCurrentProcessId
    | SetRecipientType
    | StoreSubjectSettings
    | SetIsLoading
    | StoreOriginDepartmentFolio
    | StoreDestinDepartmentFolio
    | RemoveDestinDepartment
    | RemoveOriginDepartment
    | SetSeparatedFolio;
