import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ErrorMessageComponent } from '../../messages/error-message/error-message.component';
import { DynamicFormComponent } from '../../components/dynamic-form/dynamic-form.component';
import { NotifierComponent } from '../../notifier/notifier.component';
import { Observable, Subject } from 'rxjs';
import { SubjectRequestModel } from '../../_models/SubjectRequest.model';
import { SubjectRequestService } from '../../_services/subject-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../_services/users.service';
import { SubjectService } from '../../_services/subject.service';
import { Store } from '@ngrx/store';
import * as MailboxReducer from '../../_store/reducers/mailbox.reducer';
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import * as MailboxSelector from '../../_store/selectors/mailbox.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { SubjectAnswerDialogComponent } from '../../share-components/subject-answer/subject-answer-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SubjectDialogDataService } from '../../_services/subject-dialog-data.service';
import { SystemSettingsService } from '../../_services/system-settings.service';
import * as SubjectSelector from '../../_store/selectors/subject.selector';
import { MAILBOX_HISTORY_EXTERNAL } from '../../process-tree-panel/_models/MailboxNodes';
import * as NewOfficeReducer from '../../../../projects/editor/src/app/_store/reducers/new-document.reducer';
import { Preview } from '../../mailboxes/Preview';

@Component({
  selector: 'app-external-finished-preview',
  templateUrl: './external-finished-preview.component.html',
  styleUrls: [ './external-finished-preview.component.css' ]
})
export class ExternalFinishedPreviewComponent extends Preview implements OnInit {
  @ViewChild(ErrorMessageComponent, { static: true }) errorMessage;
  @ViewChild('dynamicFormComponent', { static: true }) dynamicForm: DynamicFormComponent;
  @ViewChild(NotifierComponent, { static: true }) notifier;

  destroy: Subject<void> = new Subject();
  mailboxType = MAILBOX_HISTORY_EXTERNAL;

  subjectRequest: SubjectRequestModel;
  // tasks;
  organizationalUnitId: number = null;
  fullMetadatos: any;
  disabled: boolean;
  showLoading$: Observable<boolean>;

  constructor(
          public systemSettingsService: SystemSettingsService,
          public subjectRequestService: SubjectRequestService,
          public activatedRoute: ActivatedRoute,
          public userService: UsersService,
          public dialog: MatDialog,
          public dialogRef: MatDialogRef<ExternalFinishedPreviewComponent>,
          public subjectService: SubjectService,
          public store$: Store<MailboxReducer.State>,
          public subject$: Store<SubjectReducer.State>,
          public subjectDialogDataService: SubjectDialogDataService,
          public router: Router,
          public newOffice$: Store<NewOfficeReducer.State>,
          @Inject(MAT_DIALOG_DATA) public dialogData
  ) {
    super(systemSettingsService, router, activatedRoute, dialogRef, newOffice$, subject$, dialog, userService, subjectRequestService);
  }

  ngOnInit() {
    this.store$.select(MailboxSelector.getCurrentOrganizationalUnitId)
    .pipe(( takeUntil(this.destroy) ),
            filter(response => response !== null && response > 0))
    .subscribe(
            response => {
              this.organizationalUnitId = response;
              this.destroy.next();
            }
    );

    this.buildSubjectForm();
    this.showLoading$ = this.subject$.select(SubjectSelector.getIsLoadingPDF);

    this.subject$.select(SubjectSelector.getIsLoadingFlowChart).pipe(takeUntil(this.destroy),
            filter(data => data !== null)).subscribe(loading => {
      if (loading) {
        this.scrollToElement();
      }
    });
  }

  buildSubjectForm() {
    const addresseeId = this.subjectDialogDataService.recipientId;

    this.subjectService.getCurrentSubject()
    .pipe(
            takeUntil(this.destroy),
            filter(data => data !== null && data !== undefined)
    ).subscribe(
            subjectRequest => {
              this.subjectRequest = subjectRequest;
              this.mapFieldsAndBuildForm();
            },
            error => {
              console.error(error);
            }
    );
  }

  mapFieldsAndBuildForm() {
    const metadata = [];
    const metadataValue = this.subjectRequest.metadataValue;

    this.subjectRequest.process.metadata.forEach(function (field, index) {
      const metadataFieldValue = metadataValue.find(metadataValueField => metadataValueField.metadata_id === field.id);

      if (metadataFieldValue === null || metadataFieldValue === undefined) {
        return;
      }

      field = { ...field, value: metadataFieldValue.value };
      metadata.push(field);
    });
    this.fullMetadatos = metadata;
    this.dynamicForm.createControls(metadata);
    this.dynamicForm.disableForm();
  }

  openSubjectAnswerDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.subjectRequest;
    dialogConfig.maxWidth = '1000px';
    dialogConfig.width = '800px';
    // dialogConfig.maxHeight = '700px';
    // dialogConfig.panelClass = 'dialog-confirmation-class';

    const dialog = this.dialog.open(SubjectAnswerDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(answer => {

    });

  }

  isTitular() {
    return this.userService.isTitular(this.organizationalUnitId);
  }

  marcarResuelto(recipient) {
  }

  finalizarAsunto() {
  }

  avanzar() {
  }

}
