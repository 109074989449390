import { Component, Input, OnInit } from '@angular/core';
import { faTrashAlt, faCopy, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from 'ng2-file-upload';

export interface FileInterface {
  name: string;
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {
  @Input('set-config') setConfig: boolean = false;
  @Input() isMultiple: boolean = true;
  @Input() buttonText: string;
  @Input('acceptsString') acceptsString: string;
  @Input() docsLimit: number;

  faTrash = faTrashAlt;
  faCopy = faCopy;
  faDownload = faDownload;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  node: any = [];
  permissions: any = [];
  constructor() { }

  ngOnInit() {
    this.setButtonText();
    this.fileUpload();
  }

  fileUpload() {
    this.uploader = new FileUploader({
      autoUpload: true,
      queueLimit: this.docsLimit,
      disableMultipart: true,
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        this.arrayCopy();
        let file = {
          canCopyText: false,
          canDownload: false,
        };
        this.node.push(item.some);
        this.permissions.push(file);
      }
    });

    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.arrayCopy();
  }

  arrayCopy() {
    this.node = Object.assign([], this.node);
    this.permissions = Object.assign([], this.permissions);
  }

  toggleCopy($event, i) {
    this.permissions[i].canCopyText = $event.checked;
  }

  toggleDownload($event, i) {
    this.permissions[i].canDownload = $event.checked;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  private setButtonText() {
    if (this.buttonText === null || this.buttonText === undefined) {
      this.buttonText = '';
    }
  }

  remove(i) {
    this.arrayCopy();
    this.node.splice(i, 1);
    this.permissions.splice(i, 1);
  }

  allRemove() {
    this.arrayCopy();
    this.node.splice(0, this.node.length);
    this.permissions.splice(0, this.permissions.length);
  }

  getFilesForm(): FormData {
    const formData: FormData = new FormData();
    if (this.isMultiple) {
      for (let item of this.node) {
        formData.append('file[]', item);
      }
    } else {
      formData.append('file', this.node[0]);
    }

    if (this.setConfig === true) {
      formData.append('permissions', JSON.stringify(this.permissions));
    }

    return formData;
  }

  private getConfigValues() {
  }

  public getDocumentsList(): Array<any> {
    return this.node;
  }
}
