import { Action } from '@ngrx/store';
import {UnreadMailboxModel} from '../../_models/UnreadMailboxModel';

export const STORE_PROCESS                 = '[Mailbox] Store process';
export const CLEAR_ALL_PROCESS             = '[Mailbox] Clear all process';
export const STORE_COLUMN_FILTERS_SELECTED = '[Mailbox] Store column filters selected';
export const GET_COLUMN_FILTERS_SELECTED   = '[Mailbox] Get column filters selected';
export const CLEAR_COLUMN_FILTERS_SELECTED = '[Mailbox] Clear column filters selected';
export const CLEAR_MAILBOX_SUBJECTS        = '[Mailbox] Clear Mailbox Subjects';
export const STORE_MAILBOX_SUBJECTS        = '[Mailbox] Store Mailbox Subjects';
export const GET_MAILBOX_SUBJECTS          = '[Mailbox] GET Mailbox Subjects';
export const STORE_UNREAD_MAILBOX          = '[Mailbox] Store unread mailbox';
export const GET_UNREAD_MAILBOX            = '[Mailbox] Get unread mailbox';
export const ADD_COUNT_UNREAD_MAILBOX      = '[Mailbox] Add Count to unread mailbox';
export const SUBTRACT_COUNT_UNREAD_MAILBOX = '[Mailbox] Subtract Count to unread mailbox';
export const SET_CURRENT_ORGANIZATIONALUNITID = '[Mailbox] Set current organizational unit id';
export const SET_SUBJECT_TYPE              = '[Mailbox] Set Subject Type';
export const SET_LAST_MAILBOX_DATETIME     = '[MailBox] set last mailbox datetime access';
export const SET_SUBJECT_TYPE_CODE         = '[MailBox] Set Subject Type Code';
export const SET_MAILBOX_TYPE_CODE         = '[MailBox] Set MAILBOX TYPE Type Code';
export const UPDATE_UNREAD_MAILBOX     = '[MailBox] Update unread mailbox';
export const SET_IS_LOADING                = '[MailBox] Set Is Loading';

export class StoreProcess implements  Action {
    readonly type = STORE_PROCESS;

    constructor(public payload) {}
}

export class ClearAllProcess implements  Action {
    readonly type = CLEAR_ALL_PROCESS;

    constructor() {}
}

export class setIsLoading implements Action {
  readonly type = SET_IS_LOADING;
  constructor(public isLoading: boolean) {}
}

export class StoreColumnFiltersSelected implements  Action {
    readonly type = STORE_COLUMN_FILTERS_SELECTED;

    constructor(public payload) {}
}

export class GetColumnFiltersSelected implements Action {
    readonly type = GET_COLUMN_FILTERS_SELECTED;

    constructor() {}
}

export class ClearColumnFiltersSelected implements Action {
    readonly type = CLEAR_COLUMN_FILTERS_SELECTED;

    constructor() {}
}

export class StoreMailboxSubjects implements Action {
    readonly type = STORE_MAILBOX_SUBJECTS;

    constructor(public payload) {}
}

export class GetMailboxSubjects implements Action {
    readonly type = GET_MAILBOX_SUBJECTS;

    constructor() {}
}

export class ClearMailboxSubjects implements Action {
    readonly type = CLEAR_MAILBOX_SUBJECTS;

    constructor() {}
}

export class StoreUnreadMailbox implements Action {
    readonly type = STORE_UNREAD_MAILBOX;

    constructor(public payload) {}
}

export class GetUnreadMailbox implements Action {
    readonly type = GET_UNREAD_MAILBOX;

    constructor() {}
}

export class SubtractUnreadMailbox implements Action {
    readonly type = SUBTRACT_COUNT_UNREAD_MAILBOX;

    constructor(public code:string, public subjectType: string, public organizationalUnitId: number) {}
}

export class SetCurrentOrganizationalUnitId implements Action {
    readonly type = SET_CURRENT_ORGANIZATIONALUNITID;

    constructor(public organizationalUnitId: number) {}
}

export class SetSubjectType implements Action {
  readonly type = SET_SUBJECT_TYPE;
  constructor(public subjectType: number) {
  }
}

export class SetSubjectTypeCode implements Action {
  readonly type = SET_SUBJECT_TYPE_CODE;
  constructor(public subjectTypeCode: string) {
  }
}

export class SetMailboxTypeCode implements Action {
  readonly type = SET_MAILBOX_TYPE_CODE;
  constructor(public mailboxTypeCode: string) {
  }
}

export class SetLastAccess implements Action {
  readonly type = SET_LAST_MAILBOX_DATETIME;
  constructor(public lastAccess: any) {
  }
}
export class UpdateUnreadMailbox implements Action {
  readonly type = UPDATE_UNREAD_MAILBOX;
  constructor(public unreadMailbox: UnreadMailboxModel) {
  }
}
export type Actions = StoreProcess
    | ClearAllProcess
    | StoreColumnFiltersSelected
    | GetColumnFiltersSelected
    | ClearColumnFiltersSelected
    | ClearMailboxSubjects
    | StoreMailboxSubjects
    | GetMailboxSubjects
    | StoreUnreadMailbox
    | GetUnreadMailbox
    | SubtractUnreadMailbox
    | SetCurrentOrganizationalUnitId
    | SetSubjectType
    | SetLastAccess
    | setIsLoading
    | UpdateUnreadMailbox
    | SetSubjectTypeCode
    | SetMailboxTypeCode;
