import { Component, OnInit } from '@angular/core';

import { FormGroup } from '@angular/forms';

import { FieldConfig } from '../../_models/field.interface';

@Component({

    selector: 'app-checkbox',

    template: `
      <div [formGroup]="group">
        <p> {{field.label}}: </p>
        <div class="flex">
          <div class="slide">
            <p [class.activate]="!checked"> No </p>
            <mat-slide-toggle [formControlName]="field.name" (change)="toggle($event)"></mat-slide-toggle>
            <p [class.activate]="checked"> Si </p>
          </div>
          <mat-icon *ngIf="field.tooltip" matSuffix matTooltip="{{field.tooltip}}"> help_outline
          </mat-icon>
        </div>
      </div>
`,

  styleUrls: ['./checkbox.component.css']

})

export class CheckboxComponent implements OnInit {
    checked = false;
    field: FieldConfig;

    group: FormGroup;

    constructor() {}

    ngOnInit() {
      this.checked = this.group.get(this.field.name).value;
    }

    toggle(e) { this.checked = e.checked; }
}
