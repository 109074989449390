import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SubjectDocumentService } from '../../_services/subject-document.service';
import { ViewerService } from '../../document-viewer/viewer.service';
import { File } from '../../_helpers/file';
import { UsersService } from '../../_services/users.service';
import { Store } from '@ngrx/store';
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import * as SubjectSelector from '../../_store/selectors/subject.selector';
import { Observable, Subject } from 'rxjs';
import { DocumentModel } from '../../_models/Document.model';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { UserModel } from '../../_models/user.model';
import { saveAs} from 'file-saver';
import { SubjectRequestService } from 'src/app/_services/subject-request.service';
import * as SubjectActions from 'src/app/_store/actions/subject.actions';
import { SubjectService } from 'src/app/_services/subject.service';
import { SubjectRequestModel } from '../../_models/SubjectRequest.model';
import { faDownload, faPaperclip, faTrashAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { NotifierComponent } from '../../notifier/notifier.component';

@Component({
  selector: 'app-subject-documents',
  templateUrl: './subject-documents.component.html',
  styleUrls: ['./subject-documents.component.css']
})
export class SubjectDocumentsComponent implements OnInit {
    @Input() fileNameDownload: string = new Date().toISOString();
    @Input() repositoryType: string; // tasks || subject || answer
    @ViewChild( NotifierComponent ) notifier;
    faClose = faWindowClose;
    faDownload = faDownload;
    faTrash = faTrashAlt;
    faAttachments = faPaperclip;

    loading = false;
    documents: Observable<Array<DocumentModel>>;
    answer;
    showError = false;
    errorMessage: string = null;
    destroy: Subject<void> = new Subject();
    subjectRequestM: SubjectRequestModel;
    CanDownloads = false;
    CanDownloadAnswer = false;
    currentDate = moment().format('YYYY-MM-DD');

    constructor(
        private subjectDocumentServices: SubjectDocumentService,
        private viewerService: ViewerService,
        public userService: UsersService,
        private subject$: Store<SubjectReducer.State>,
        private subjectService: SubjectService,
        private subjectRequest: SubjectRequestService
    ) {
    }

    ngOnInit() {
        this.documents = this.subject$.select(SubjectSelector.getSubjectDocuments);
        this.answer = this.subject$.select(SubjectSelector.getSubjectAnswers);
        this.subjectService.getCurrentSubject().pipe(
          filter(response => response !== null && response !== undefined)
        ).subscribe(response => {
          this.subjectRequestM = response;
        });
      this.canDownloadAll();
    }

    downloadAllWithResponses() {
        const dataTodownload = {documents: [], flow: {}, subject: {}};

        this.documents.pipe( take(1), filter(data => data !== null)).subscribe(documents => {
                const data = {};
                data['repositoryType'] = 'subject';
                data['documents'] = [];
                const documentItem =  { id: 0, email: 'asunto', documentIds: []};
                documents.map(document => {
                if ( this.isDownloableDocument(document) ) {
                    documentItem['documentIds'].push(document.id);
                }
            });
            data['documents'].push(documentItem);
            dataTodownload.documents.push(data);
        });

        this.answer.pipe( take(1), filter(data => data !== null)).subscribe(answers => {
            const data = {};
            data['repositoryType'] = 'subjectAnswer';
            data['documents'] = [];
            answers.map(answer => {
                const documents = {id: answer.id, email: answer.user.email, documentIds: []};
                // data['documents'].push({email: answer.user.email, documentIds: []});
                answer.documents.map(answerDocument => {
                    if (this.isDownloableDocument(answerDocument)) {
                        documents['documentIds'].push(answerDocument.id);
                    }
                });
                data['documents'].push(documents);
            });
            dataTodownload.documents.push(data);
        });
        /* detalle subject*/
        dataTodownload.subject = {
          'folio': this.subjectRequestM.folio,
          'title': this.subjectRequestM.title,
          'seririty': this.subjectRequestM.severity.name,
          'unit': this.subjectRequestM.organizationalUnit.name,
          'process': this.subjectRequestM.process.process_name,
          'create_at': this.subjectRequestM.created_at,
          'fromDate': this.subjectRequestM.fromDate,
          'untilDate': this.subjectRequestM.untilDate
        };

        /* flujo */
        this.subject$.select(SubjectSelector.getCurrentSubject).pipe(
          filter(data => data !== null && data !== undefined),
          take(1)
        ).subscribe(subjectRequest => {
            this.subjectRequest.getSubjectFlow(subjectRequest.id).subscribe(
                response => {
                    if (response['status']) {
                        this.subject$.dispatch(new SubjectActions.StoreSubjectFlow(response.subjectFlow, response.documents));
                        this.subject$.select(SubjectSelector.getSubjectFlow)
                        .pipe(
                          filter(data => data !== null && data !== undefined),
                          takeUntil(this.destroy))
                        .subscribe(subjectFlow => {
                           const data = {};
                           data['repositoryType'] = 'subjectFlow';
                           data['documents'] = [];
                          if (subjectFlow.details.some(addresseeFlowEntry => addresseeFlowEntry.recipient_id == null || addresseeFlowEntry.sender_recipient_id == null)) {
                            data['documents'].push(this.getDetails(subjectFlow));
                          } else {
                            data['documents'].push(this.getDetailsNewMethod(subjectFlow));
                          }
                          dataTodownload.flow = data;

                        });
                        console.log(dataTodownload);

                          this.downloadAllSubject(dataTodownload);

                    } else {
                        console.error(response);
                    }
                }
            );
        });

    }

    getDetails(subjectFlow): any {
      console.log('building graph with old method');
      return subjectFlow.details.map(data => {
        const created_at = data.created_at;
        const senderUserName = data.senderUser.full_name_prefix;
        const sender = senderUserName + String.fromCharCode(10) + ', ' + data.senderOrganizationalUnit.name;
        const recipientUserName = (data.destinUser !== null) ? data.destinUser.full_name_prefix : '';
        const recipient = (data.destinOrganizationalUnit !== null) ? data.ReceptionType + ' -' + recipientUserName + String.fromCharCode(10) + ', ' + data.destinOrganizationalUnit.name : recipientUserName;
        let tasks = '';
        if (data.destinOrganizationalUnit !== null) {
          data.destinOrganizationalUnit.tasks.map(task => {
            tasks += task.description;
          });
        }

        return {
          sender: sender,
          recipient: recipient,
          step: data.step,
          tasks: tasks,
          created_at: created_at,
          first_view: ''
        };
      });
    }

    getDetailsNewMethod(subjectFlow): any {
      return subjectFlow.details.map(data => {
        const created_at = data.created_at;
        const senderUserName = data.senderRecipient.user.full_name_prefix;
        const sender = (data.senderUser !== null) ? senderUserName + ', ' + data.senderOrganizationalUnit.name : data.senderOrganizationalUnit.name;
        const recipientUserName = data.recipient.user.full_name_prefix;
        const recipient = (data.destinOrganizationalUnit !== null) ? data.receptionType + ' -' + recipientUserName + ', ' + data.destinOrganizationalUnit.name : recipientUserName;
        let tasks = '';
        if (data.destinOrganizationalUnit !== null) {
          data.destinOrganizationalUnit.tasks.map(task => {
            tasks += task.description;
          });
        }
        return {
          sender: sender,
          recipient: recipient,
          step: data.step,
          // tasks: tasks,
          created_at: created_at,
          first_view: data.recipient.first_view
        };
      });
    }

    downloadAllSubjectDocuments() {

    }

    downloadAll() {
        const documentIds = [];

        this.documents.pipe(
            take(1),
            filter(data => data !== null)
        ).subscribe(documents => {
                console.log(documents);
            documents.map(document => {
                if ( this.isDownloableDocument(document) ) {
                    documentIds.push(document.id);
                }
            });
            console.log(documentIds);
        });

        console.log('downloading...');

        if (documentIds.length > 0) {
            this.download(documentIds, null);
        }
    }

    downloadSingleFile(document: DocumentModel) {
        if (this.isDownloableDocument(document)) {
            this.download([document.id], document.filename);
        }
    }

    download(documentIds: Array<any>, fileNameDownload) {
        console.log('documentsId...', documentIds);
        console.log('filename...', fileNameDownload);
        this.loading = true;

        this.resetError();

        const request = {
            documentIds: documentIds,
            repositoryType: this.repositoryType
        };

        this.subjectDocumentServices.download(request).subscribe(
            response => {
                this.loading = false;
                const filename = this.fileNameDownload.replace('.', '_');
                saveAs(response, fileNameDownload);
            },
            error => {
                this.loading = false;
                if (error.status == 420) {
                    this.displayError('No tiene permisos de descarga de documentos');
                } else {
                    this.displayError('Error en la descarga de documentos');
                }

            }
        );
    }

  removeDocument(document) {
    this.subjectDocumentServices.deleteDocument(document).subscribe(
      response => {
        if (response.status) {
          this.remove(document);
          this.notify(response.message, 'success');
        } else {
          this.notify(response.message, 'error');
        }
      },
      error => {
        if (error.status == 420) {
          this.displayError('No tiene permisos de eliminar este documento');
        } else {
          this.displayError('Error al eliminar este documento');
        }
      }
    );
  }

    notify(message, type) {
      this.notifier.show({
        type: type,
        message: message
      });
    }

    remove(deleteDoc) {
      this.subject$.select(SubjectSelector.getSubjectDocuments)
        .pipe(take(1), filter(data => data !== null))
        .subscribe(documents => {
          let doc = documents;
          const deleteDocument =  documents.indexOf(documents.find(item => item.id == deleteDoc.id));
          doc = Object.assign([], doc);
          doc.splice(deleteDocument, 1);
          this.subject$.dispatch(new SubjectActions.UpdateSubjectDocuments(doc));
        });
    }

    downloadAllSubject(dataToDownload) {
        this.loading = true;

        this.resetError();

        this.subjectDocumentServices.downloadAllSubject(dataToDownload).subscribe(
            response => {
                this.loading = false;
                const blob = new Blob([response], {type: response.type});

                const filename = this.fileNameDownload.replace('.', '_');
                saveAs(response, filename);
            },
            error => {
                this.loading = false;
                if (error.status == 420) {
                    this.displayError('No tiene permisos de descarga de documentos');
                } else {
                    this.displayError('Error en la descarga de documentos');
                }

            }
        );
    }

    openDocument(document) {
        this.viewerService.openDocument(this.repositoryType, document);
    }

    resetError() {
        this.showError = false;
        this.errorMessage = '';
    }

    displayError(errorMessage) {
        this.showError = true;
        this.errorMessage = errorMessage;
    }

    icon(filename: string) {
        return File.icon(filename);
    }

    canDownloadAll(): boolean {
        let status = false;

        this.documents.pipe(
            take(1),
            map(docs => {
                return docs.filter(doc => {
                    return this.isDownloableDocument(doc);
                });
            })
            ).subscribe(doc => {
                status = doc.length > 0;
                this.CanDownloads = status;
                let status2 = false;
                this.answer.pipe( take(1), filter(data => data !== null)).subscribe(answers => {
                  answers.map(answer => {
                      answer.documents.map(answerDocument => {
                          if (this.isDownloableDocument(answerDocument)) {
                             status2 = true;
                          }
                      });
                  });
                  this.CanDownloadAnswer = status2;
                });
            });

        return status;
    }

    /**
     *
     * @param document
     */
    isDownloableDocument(document: DocumentModel): boolean {
        // return (this.userService.userData.id == document.createdByUserId) ? true : (document.canDownload == true) ? true : false;
        return document.canDownload == true;
    }

    userName(user: UserModel): string {
        return user.name + ' ' + user.last_name + ' ' + (user.mothers_last_name !== null ? user.mothers_last_name : '' );
    }

  allow_delete(document): boolean {
    const isAsistente = this.userService.isAsistente(document.subjectRequest.organizationalUnit_id);
    const subjectRequestUser = this.userService.userData.id == document.subjectRequest.user_id;
    const date = this.currentDate == moment(document.created_at).format('YYYY-MM-DD');
    return document?.allow_remove && date && (isAsistente || subjectRequestUser);
  }
}
