import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import * as SubjectSelec from '../../_store/selectors/subject.selector';
import { Observable, Subject } from "rxjs";
import { SubjectAnswerModel } from "../../_models/SubjectRequest.model";
import { faBuilding, faUser, faClock, faCommentDots, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { UsersService } from "../../_services/users.service";
import { SystemSettingsService } from '../../_services/system-settings.service';
import { CAT_ANSWER_TYPE } from '../../_constants/CatAnswerTypeConstant';
import { COLOR_ANSWER, COLOR_RESOLVED } from '../../_constants/SystemSettingsConstants';

@Component({
  selector: 'app-subject-answers',
  templateUrl: './subject-answers.component.html',
  styleUrls: ['./subject-answers.component.css']
})
export class SubjectAnswersComponent implements OnInit, OnDestroy {
  faOrganizationalUnit = faBuilding;
  faUser = faUser;
  answers: Observable<Array<SubjectAnswerModel>>;
  faClock = faClock;
  Check = faCheckCircle;
  Comment = faCommentDots;
  dataSettingsAnswer: any;
  dataSettingsSolved: any;
  answer_type = CAT_ANSWER_TYPE;

  constructor(
      private subject$: Store<SubjectReducer.State>,
      private userService: UsersService,
      public systemSettings: SystemSettingsService
  ) { }

  ngOnInit() {
    this.answers = this.subject$.select(SubjectSelec.getSubjectAnswers);
    const settingsAnswer = this.systemSettings.systemSettings;

    for (let responseData of settingsAnswer) {
      if (responseData.code == COLOR_ANSWER) {
        this.dataSettingsAnswer = JSON.parse(responseData.value);
      }

      if (responseData.code == COLOR_RESOLVED) {
        this.dataSettingsSolved = JSON.parse(responseData.value);
      }
    }

  }

  ngOnDestroy(): void {

  }

  userNameDisplay(answer: SubjectAnswerModel): string {
    return answer.user.name + " " + answer.user.last_name + ' ' + ((answer.user.mothers_last_name !== null) ? answer.user.mothers_last_name : '');
  }

}
