import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment'; // Angular CLI environment
import { storeFreeze } from 'ngrx-store-freeze';
import * as NewSubjectReducer  from '../reducers/new-subject.reducer';
import * as NewDocumentReducer  from '../reducers/new-document.reducer';
import * as ProcessReducer  from './process.reducer';
import * as UserDataReducer  from '../reducers/userData.reducer';
import * as MailboxReducer  from '../reducers/mailbox.reducer';
import * as RecipientsTreeReducer  from '../reducers/recipients-tree.reducer';
import * as DocumentReducer  from './office.reducer';
import * as NotificationReducer from './notification.reducer';
import * as AssignParticipants from './assign-participants.reducer';

export interface State {
  newSubject: NewSubjectReducer.State,
  process: ProcessReducer.State,
  userData: UserDataReducer.State,
  mailbox : MailboxReducer.State,
  newDocument: NewDocumentReducer.State,
  recipientsTree: RecipientsTreeReducer.State,
  office: DocumentReducer.State,
  notification: NotificationReducer.State,
  assignParticipants: AssignParticipants.State
}

export const reducers: ActionReducerMap<any> = {
  newSubject: NewSubjectReducer.reducer,
  process: ProcessReducer.reducer,
  userData: UserDataReducer.reducer,
  mailbox: MailboxReducer.reducer,
  newDocument: NewDocumentReducer.reducer,
  recipientsTree: RecipientsTreeReducer.reducer,
  office: DocumentReducer.reducer,
  notification: NotificationReducer.reducer,
  assignParticipants: AssignParticipants.reducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return function(state: State, action: any): State {
        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger, storeFreeze] : [];
