import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ErrorMessageComponent } from "../../messages/error-message/error-message.component";
import { MatCheckbox } from "@angular/material/checkbox";
import { Observable, Subject } from "rxjs";
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions, TreeModel, TreeNode, TreeComponent } from 'angular-tree-component';
import { faTasks } from "@fortawesome/free-solid-svg-icons/faTasks";
import { MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import * as MailboxReducer from "../../_store/reducers/mailbox.reducer";
import * as NewSubjectReducer from "../../_store/reducers/new-subject.reducer";
import * as MailboxSelector from "../../_store/selectors/mailbox.selector";
import { filter, takeUntil } from "rxjs/operators";
import { OrganizationalUnitModel } from "../../_models/OrganizationalUnit.model";
import { RecipientNodeModel, TaskNodeModel } from "../../new-subject/addressee-tree/addressee-tree.component";
import { faEdit, faTrashAlt, faSitemap, faBan, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons/faSearchPlus";
import * as SubjectActions from "../../_store/actions/subject.actions";
import { getIsLoadingDialog, getCurrentSubject } from "../../_store/selectors/subject.selector";
import * as SubjectReducer from "../../_store/reducers/subject.reducer";
import { OrganizationalUnitService } from "../../_services/organizational-unit.service";
import { CompanyModel } from "../../_models/Company.model";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { SubjectService } from "../../_services/subject.service";
import { SubjectRequestModel } from "../../_models/SubjectRequest.model";
import { ORGANIZATIONAL_UNIT } from "../../_constants/RecipientTypeConstants";
import { UpdateSubjectAttributes, SetIsLoadingDialog } from "../../_store/actions/subject.actions";

const ORGANIZATIONAL_UNIT_NODE = "organizationalUnit";

export type NodeInterface = {
  checked: boolean;
  type: string;
  disabled: boolean;
}

export interface DepartmentNodeInterface extends OrganizationalUnitModel {
  checked: boolean;
  type: string;
}

export interface CompanyNodeInterface extends CompanyModel {
  checked: boolean;
  type: string;
  disabled: boolean;
}

export const PREFIX_COMPANY = 'COMPANY_';

@Component({
  selector: 'app-send-copy-dialog',
  templateUrl: './send-copy-dialog.component.html',
  styleUrls: ['./send-copy-dialog.component.css']
})
export class SendCopyDialogComponent implements OnInit {
  @ViewChild('tree', { static: false } ) treeComponent;
  @ViewChild(ErrorMessageComponent, { static: true } ) errorMessage;
  @Input() data: { addresseeType: string, data: Array<any> };
  @ViewChildren('FCheckboxOU') checkboxF: QueryList<MatCheckbox>;
  @ViewChildren('checkboxCC') checkboxCC: QueryList<MatCheckbox>;

  destroy: Subject<void> = new Subject();

  currentOrganizationalUnitId: number;

  faEdit = faEdit;
  faDelete = faTrashAlt;
  faTask = faTasks;
  faTrash = faTrash;
  faSearch = faSearchPlus;
  showSpinner: Observable<boolean>;
  faCancel = faBan;

  subjectRequest: SubjectRequestModel;

  nodes: any[] = [];

  options: ITreeOptions = {
    // useCheckbox: true,
    // useTriState: false,
    useVirtualScroll: false,
    // actionMapping,  //Actions over the tree
    // scrollContainer: <HTMLElement>document.body.parentElement
  };

  constructor(
    private store$: Store<MailboxReducer.State>,
    private newSubject$: Store<NewSubjectReducer.State>,
    private subject$: Store<SubjectReducer.State>,
    private organizationalUnitService: OrganizationalUnitService,
    private subjectService: SubjectService,
    private dialofRef: MatDialogRef<SendCopyDialogComponent>
  ) {
    this.showSpinner = this.subject$.select(getIsLoadingDialog);
  }

  ngOnInit() {
    this.organizationalUnitService.getByCompanies().subscribe(response => {

      if ( response['status']) {
        this.buildTree(response['companies']);
      } else {
        this.errorMessage.setErrorMessage(response);
      }
      this.subject$.dispatch(new SetIsLoadingDialog(false));
    }, error => {
      this.errorMessage.setErrorMessage(error);
      this.subject$.dispatch(new SetIsLoadingDialog(false));
    });

    this.store$.select(MailboxSelector.getCurrentOrganizationalUnitId)
      .pipe(
        takeUntil(this.destroy),
        filter(data => data !== null)
      )
      .subscribe(response => {
        this.currentOrganizationalUnitId = response;
      });
  }

  /**
   *
   * @param companies
   */
  buildTree(companies: Array<CompanyModel>): void {
    try {
      this.nodes = companies.map(company => {
        let dataCompany = { ...company, id: PREFIX_COMPANY + company.id, children: company.departments };
        delete dataCompany.departments;
        return dataCompany;
      });

    } catch (e) {
      console.error(e);
    }
  }

  updateTree() {
    this.treeComponent.treeModel.update();
  }

  treeModel(): TreeModel {
    return this.treeComponent.treeModel;
  }

  organizationalUnitNodeData(organizationalUnitObject: OrganizationalUnitModel){
    return {
      id: organizationalUnitObject.id,
      name: organizationalUnitObject.name,
      description: organizationalUnitObject.description,
      parent_id: organizationalUnitObject.parent_id,
      nodeType: ORGANIZATIONAL_UNIT_NODE,
      icon:  faSitemap,
      CC: false,
      TURNAR:  false,
      canReceiveSubjects: organizationalUnitObject.canReceiveSubjects,
      children: organizationalUnitObject.children,
      hidden: (organizationalUnitObject.hasOwnProperty('hidden')) ? organizationalUnitObject.hidden : false,
      titular: organizationalUnitObject.titular
    };
  }

  /**
   *
   * @param node
   */
  getIcon(node): IconDefinition {
    return node.data.hasOwnProperty('business_key') ? faBuilding: faSitemap;
  }

  /**
   *
   * @param node
   * @private
   */
  private isCompany(node): boolean {
    return node.data.hasOwnProperty('business_key');
  }

  /**
   *  Desactiva las unidades participantes actuales en el asunto para evitar que se le envié copia más de una vez.
   */
  disableSubjectParticipants(): void {
    this.subject$.select(getCurrentSubject)
      .pipe(takeUntil(this.destroy))
      .subscribe(subjectRequest => {
      this.subjectRequest = subjectRequest;

        subjectRequest.recipients.map(participant => {
          let node = this.treeComponent.treeModel.getNodeBy((node: TreeNode) => {
            return node.id == participant.organizationalUnit_id;
          });

          if ( node ) {
            node.data.disabled = true;
          }
        });
    });
  }

  mapRecipientNode(node) : RecipientNodeModel{

    if (node.nodeType === ORGANIZATIONAL_UNIT_NODE){
      return {
        addressee_id: node.id,
        recipientType: node.nodeType,
        CC: node.CC,
        CCC: node.CCC,
        TURNAR: node.TURNAR,
        F: node.F == true,
        user_id: null,
        organizationalUnit_id: node.id
      };
    }else { /** if is user */
      return {
        addressee_id: node.id,
        recipientType: node.nodeType,
        user_id: node.user_id,
        CC: node.CC,
        CCC: node.CCC,
        TURNAR: node.TURNAR,
        organizationalUnit_id: node.organizationalUnitId
      }
    }

  }

  isCurrentOrganizationalUnit(node): boolean {
    return node.data.id == this.currentOrganizationalUnitId;
  }

  setValueCheckboxF(id, status: boolean) {
    const idElement = 'FCheckboxOU' + id;

    let check = this.checkboxF.find(element =>  element.id == idElement);

    if (check) {
      check.checked = status;
    }
  }

  setValueCheckboxCC(id, status: boolean) {
    const idElement = 'checkboxCC' + id;

    let check = this.checkboxCC.find(element =>  element.id == idElement);

    if (check) {
      check.checked = status;
    }
  }

  ID () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  /**
   *
   * @param text
   */
  filterNodes(text) {
    if(text.trim().length > 0)
      this.treeComponent.treeModel.filterNodes(text.trim(), true);
    else
      this.treeComponent.treeModel.clearFilter();
  }

  expandRoots(){
    let roots = this.treeComponent.treeModel.roots;
    roots.forEach(function(root, cont){
      root.expand();
    });
  }

  sendRecipients(): void {
    let checkedNodes = this.checkboxCC.filter(element => {
      return element.checked && !element.disabled;
    });

    if ( checkedNodes.length == 0 ) {
      return;
    }

    let selectedRecipients: Array<RecipientNodeModel> = [];

    checkedNodes.map(checkbox => {
      let node = this.treeModel().getNodeBy(node => {
        return node.data.id == checkbox.id;
      })

      if ( node ) {
        selectedRecipients.push(this.mapSelectedRecipient(node));
      }
    });

    let formData: FormData = new FormData();
    formData.append('recipientType', ORGANIZATIONAL_UNIT)
    formData.append('recipients', JSON.stringify(selectedRecipients))

    this.subject$.dispatch(new SetIsLoadingDialog(true));

    this.subjectService.avanzar(this.subjectRequest.id, this.currentOrganizationalUnitId, formData).subscribe(
      response => {

        if(response['status']) {
          this.subject$.dispatch(new UpdateSubjectAttributes({ recipients: response['recipients'] }));
          this.dialofRef.close({
            'status': 'success'
          });
        }else{
          this.errorMessage.setErrorMessage(response);
        }

        this.subject$.dispatch(new SetIsLoadingDialog(false));

      },
      error => {
        console.error(error);
        this.subject$.dispatch(new SetIsLoadingDialog(false));
        this.errorMessage.setErrorMessage(error);
      }
    );
  }

  mapSelectedRecipient(node): RecipientNodeModel {
    return {
      CC: true,
      CCC: false,
      TURNAR: false,
      F: node.F == true,
      user_id: null,
      organizationalUnit_id: node.id,
      recipientType: ORGANIZATIONAL_UNIT
    };
  }

  hasSelectedRecipients(): boolean {
    return this.checkboxCC == undefined || this.checkboxCC.some(element => element.checked && !element.disabled);
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
