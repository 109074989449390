import * as ExpedientAction from '../actions/Expedient.actions';

export interface State {
  expedient: any;
  expedientOpened: any;
}

const initialState: State = {
  expedient: null,
  expedientOpened: null,
};

export function reducer(state = initialState, action: ExpedientAction.Actions): any {

  switch (action.type) {
    case ExpedientAction.STORE_DATA:
      return {...state, expedient: action.payload};
    case ExpedientAction.STORE_OPENED:
      return {...state, expedientOpened: action.payload};
    case ExpedientAction.CLEAR_DATA:
      return {...state, expedient: []};
    case ExpedientAction.CLEAR_OPENED:
      return {...state, expedientOpened: []};
    default:
      return state;
  }
}
