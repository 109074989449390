import { NgModule                   } from '@angular/core';
import { AppComponent               } from './app.component';
import { AppRoutingModule           } from './app-routing.module';
import { HttpClientModule           } from '@angular/common/http';
import { FormsModule                } from '@angular/forms';
import { ReactiveFormsModule        } from '@angular/forms';
import { HTTP_INTERCEPTORS          } from '@angular/common/http';
import { AuthInterceptor            } from "./_interceptor/Auth.interceptor";
import { BrowserAnimationsModule    } from '@angular/platform-browser/animations';
import { PanelComponent             } from './panel/panel.component';
import { PanelNavbarComponent       } from './panel/panel-navbar/panel-navbar.component';
import { MaterialModule             } from './material.module';
import { FontAwesomeModule          } from '@fortawesome/angular-fontawesome';
import { SharedModule               } from "./shared.module";
import { ProcessTreePanelModule } from "./process-tree-panel/process-tree-panel.module";
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ViewerPdfDialogComponent } from './document-viewer/viewer-pdf-dialog.component';
import { NewUserComponent } from './register/new-user.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password.component';
import { DefaultNavbarComponent } from './default-navbar/default-navbar.component';
import { reducers } from "./_store/reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreModule } from '@ngrx/store';
import { metaReducers } from './_store/reducers';
import { environment } from '../environments/environment';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { SubjectNotificationsComponent } from './notifications-menu/subject-notifications/subject-notifications.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UserProfilePictureComponent } from './user-profile/user-profile-picture/user-profile-picture.component';
import { EditorProject } from "../../projects/editor/src/app/editor-app.module";
import { MailboxesModule } from '../../projects/editor/src/app/mailboxes/mailboxes.module';
import { UserProfileModule } from "./user-profile/user-profile.module";
import { FileUploadModule } from 'ng2-file-upload';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorCustomComponent } from './share-components/paginator-custom';
import { ArchivalProject } from "../../projects/archivistica/src/app/archival-app.module";
import {NgDragDropModule} from 'ng-drag-drop';

@NgModule({
  declarations: [
      AppComponent,
      PanelComponent,
      PanelNavbarComponent,
      UserProfileComponent,
      ViewerPdfDialogComponent,
      NewUserComponent,
      ForgotPasswordComponent,
      ResetPasswordComponent,
      DefaultNavbarComponent,
      UserProfilePictureComponent,
  ],
    imports: [
        AppRoutingModule,
        NgDragDropModule.forRoot(),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MaterialModule,
        FontAwesomeModule,
        SharedModule,
        ProcessTreePanelModule,
        StoreModule.forFeature('newSubject', reducers, {metaReducers}),
        StoreModule.forRoot({}),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        PdfJsViewerModule,
        ImageCropperModule,
        EditorProject.forRoot(),
        ArchivalProject.forRoot(),
        MailboxesModule,
        UserProfileModule,
        FileUploadModule
    ],
    exports: [
    ],
  providers: [
      {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
      },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorCustomComponent,
    }
  ],
  bootstrap: [AppComponent],
    entryComponents: [
        ViewerPdfDialogComponent,
        SubjectNotificationsComponent,
        UserProfilePictureComponent
    ]
})
export class AppModule { }
