import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TreeComponent, TreeModel } from 'angular-tree-component';
import { Router } from '@angular/router';
import { getMailboxId, MailboxNodes, PREFIX_ORGANIZATIONAL_UNID, PROCESS_NODE } from './_models/MailboxNodes';
import { MailboxService } from '../_services/mailbox.service';
import * as MailboxActions from '../_store/actions/mailbox.actions';
import { Store } from '@ngrx/store';
import * as MailboxReducer from '../_store/reducers/mailbox.reducer';
import * as MailboxSelector from '../_store/selectors/mailbox.selector';
import { Observable, Subject } from 'rxjs';
import { UsersService } from '../_services/users.service';
import { filter, takeUntil } from 'rxjs/operators';
import { EXTERNAL_SUBJECT_CODE, INTERNAL_SUBJECT_CODE } from '../_constants/SubjectTypeConstants';
import { UnreadMailboxModel } from '../_models/UnreadMailboxModel';
import { OrganizationalUnitModel } from '../_models/OrganizationalUnit.model';
import { ArchiveSubjectService } from '../../../projects/archivistica/src/app/_core/_services/archive-subject.service';

const archival = 'Archivado';

@Component({
  selector: 'app-process-tree',
  templateUrl: './process-tree.component.html',
  styleUrls: ['./process-tree.component.css']
})
export class ProcessTreeComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('tree', { static: true }) treeComponent: TreeComponent;
    showLoading$: Observable<boolean>;
    destroy: Subject<void> = new Subject();

    processes: any[] = [];

    treeModel: TreeModel;

    nodes: any[] = [];

    treeOptions: any = {
        animateExpand: true,
        scrollOnActivate: true,
        animateSpeed: 30,
        animateAcceleration: 1.2,
    };
    LookTree: any = null;

    constructor(
            private archiveSubjectService: ArchiveSubjectService,
            private router: Router,
            private mailboxService: MailboxService,
            private store$: Store<MailboxReducer.State>,
            private userService: UsersService
    ) {

    }

    ngOnInit() {
        this.buildMailboxNodes();

        this.store$.select(MailboxSelector.getMailboxUnread)
        .pipe(
                takeUntil(this.destroy),
                filter(data => data !== null && data !== undefined && data.length > 0)
        )
        .subscribe(
                response => {
                    this.setBadgeUnreadMailbox(response);
                }
        );
        this.showLoading$ = this.store$.select(MailboxSelector.isLoading);
    }

    /**
     * build mailboxes
     *    Bandeja de entrada Internos
     *       Nuevo
     *       Avanzado
     *       Resuelto
     *       Reactivado
     *    Bandeja de Salida
     *       Nuevo
     *       Avanzado
     *       Resuelto
     *       Reactivado
     *
     */
    buildMailboxNodes(): void {
        const activateArchival = this.archiveSubjectService.isModelActivated();

        if (this.userService.userData === null) {
            return;
        }

        const self = this;
        const organizationalUnits = this.userService.userData.organizationalUnits as Array<OrganizationalUnitModel>;

        organizationalUnits.forEach(function (organizationalUnit) {
            if (!organizationalUnit) {
                return;
            }

            self.getMailboxes(organizationalUnit).forEach(function (mailbox) {
                if (!activateArchival) {
                    mailbox.children = mailbox.children.filter(val => val.name !== archival);
                    self.nodes.push(mailbox);
                } else {
                    self.nodes.push(mailbox);
                }
            });

            self.treeComponent.treeModel.update();

            const organizationalUnitNode = self.nodes.find((node) => node.id === PREFIX_ORGANIZATIONAL_UNID + organizationalUnit.id);

            if (organizationalUnitNode === null || organizationalUnitNode === undefined) {
                return;
            }

        });

        this.treeComponent.treeModel.update();

        this.getUnreadMailbox();
        if (this.LookTree === null) {
            this.LookTree = setInterval(() => { this.getUnreadMailbox(); }, 240000);
        }
    }

    get processNodeName() {
        return PROCESS_NODE;
    }

    getMailboxes(organizationalUnitId) {
        return MailboxNodes(organizationalUnitId);
    }

    onClickNode(node) {
        if (node.data.route !== null && node.data.route !== undefined) {
            this.router.navigate(node.data.route);
        }
    }

    activateNode(): void {
        if (this.getUrlType === 'newSubject') {
            return;
        }

        this.store$.select(MailboxSelector.getCurrentOrganizationalUnitId)
        .pipe(
                takeUntil(this.destroy),
                filter(data => data !== null)
        )
        .subscribe(organizationalUnitId => {
            if (this.urlArray[3] == 'external') {
                // TODO replace this after added subject type in the url
                const nodeId = getMailboxId(EXTERNAL_SUBJECT_CODE, this.urlArray[4], this.urlArray[5]);

                if (nodeId === null || nodeId === undefined) {
                    return;
                }

                this.activateNodeById(nodeId + organizationalUnitId);
            } else {
                // TODO replace this after added subject type in the url
                const nodeId = getMailboxId(INTERNAL_SUBJECT_CODE, this.urlArray[3], this.urlArray[4]);

                if (nodeId === null || nodeId === undefined) {
                    return;
                }

                this.activateNodeById(nodeId + organizationalUnitId);
            }
        });

    }

    get getUrlType() {
        return this.urlArray[3];
    }

    get urlArray() {
        return this.router.url.split('/');
    }

    activateNodeById(idNode: string) {
        const node = this.treeComponent.treeModel.getNodeBy((node) => node.data.id === idNode);

        if (node !== null && node !== undefined) {
            node.setActiveAndVisible(true);
        }
    }

    setBadgeUnreadMailbox(unreadMailbox: Array<UnreadMailboxModel>): void {
        if (this.nodes.length === 0) {
            return;
        }

        unreadMailbox.map(unreadData => {

            let nodeId = null;

            if (unreadData.subjectType == INTERNAL_SUBJECT_CODE) {
                nodeId = getMailboxId(unreadData.subjectType, 'inbox', unreadData.code) + unreadData.organizationalUnitId;

            } else {
                nodeId = getMailboxId(unreadData.subjectType, 'inbox', unreadData.code) + unreadData.organizationalUnitId;
            }

            if (nodeId == null || nodeId == undefined) {
                return;
            }

            const mailboxNode = this.treeComponent.treeModel.getNodeBy((node) => node.id == nodeId);

            if (mailboxNode !== null && mailboxNode !== undefined) {
                mailboxNode.data.unread = unreadData.total;
            }

        });

        this.treeComponent.treeModel.update();
    }

    getUnreadMailbox() {
        this.mailboxService.unreadMailbox().subscribe(
                response => {
                    if (response['status']) {
                        this.store$.dispatch(new MailboxActions.StoreUnreadMailbox(response['unreadMailbox']));
                    }
                },
                error => {
                    console.error(error);
                }
        );
    }

    ngAfterViewInit() {
        this.activateNode();
    }

    ngOnDestroy() {
        this.destroy.next();
        clearInterval(this.LookTree);
        this.LookTree = null;
    }
}
