import { Action } from '@ngrx/store';

export const STORE_DATA = '[Subject Transfer Data] Subject Transfer Data';
export const SET_ORG_ID = '[Subject Transfer Data] Set OrganizationalUnit Data';
export const SET_SERIES = '[Subject Transfer Data] Set Series Data';
export const SET_SUBJECT_TYPE_CODE         = '[Subject Transfer Data] Set Subject Type Code';
export const SET_SUBJECT__CODE_ORG_ID         = '[Subject Transfer Data] Set Subject Code Org ID';
export const CLEAR_DATA = '[Subject Transfer Data] Clear Data';

export class StoreSubjectTransfer implements Action {
    readonly type = STORE_DATA;

    constructor(public payload) {}
}

export class SetOrganizationalUnitId implements Action {
    readonly type = SET_ORG_ID;

    constructor(public payload: number) {}
}

export class SetSeries implements Action {
    readonly type = SET_SERIES;

    constructor(public payload: number) {}
}

export class ClearDataAction implements Action {
    readonly type = CLEAR_DATA;

    constructor() {}
}

export class SetSubjectTypeCode implements Action {
    readonly type = SET_SUBJECT_TYPE_CODE;
    constructor(public subjectTypeCode: string) {
    }
}

export class SetSubjectCodeAndOrg implements Action {
    readonly type = SET_SUBJECT__CODE_ORG_ID;
    constructor(public payload: any) {
    }
}

export type Actions = StoreSubjectTransfer
        | SetSeries
        | SetSubjectTypeCode
        | SetSubjectCodeAndOrg
        | SetOrganizationalUnitId
        | ClearDataAction;
