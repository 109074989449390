import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { faEdit, faTrashAlt, faSitemap, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions, TreeModel, TreeNode, TreeComponent } from 'angular-tree-component';
import { faTasks } from "@fortawesome/free-solid-svg-icons/faTasks";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons/faSearchPlus";
import * as RecipientsTreeReducer from '../../_store/reducers/recipients-tree.reducer';
import * as RecipientsTreeActions from '../../_store/actions/recipients-tree.actions';

import { Store } from "@ngrx/store";
import { ErrorMessageComponent } from "../../../../../../src/app/messages/error-message/error-message.component";
import { MatDialog } from '@angular/material/dialog';
import { OrganizationalUnitService } from '../../_services/organizational-unit.service';
import { MatCheckbox } from '@angular/material/checkbox';
import {OrganizationalUnitModel} from "../../../../../../src/app/_models/OrganizationalUnit.model";


const USER_NODE = "user";
const TASK_NODE = "task";
const ORGANIZATIONAL_UNIT_NODE = "organizationalUnit";

export interface TaskNodeModel {
    // addresseeType: node.addresseeType, //organizationalUnit_id con el cual se asigna relación entre una tarea y un área.
    // addressee_id: node.addressee_id,
    organizationalUnit_id: number;
    description: string;
    user_id: number;
}

export interface AddresseeNodeModel {
    addressee_id: number;
    addresseeType: string;
    CC: boolean;
    CCC: boolean;
    TURNAR: boolean;
}

/**
 *  Overwrite actions of the tree component
 * @type {{mouse: {click: ((treeModel, node, $event)); checkboxClick: ((treeModel, node, $event))}; keys: {}}}
 */
const actionMapping:IActionMapping = {
    mouse: {
        click: function(treeModel,node,$event) {
          console.log($event);
          node.setIsSelected(true);
        },
        checkboxClick: function(treeModel,node,$event){

        },
    },
    keys: {
        // [KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.name}`)
    }
}

/**
 * This class builds the recipientOptions tree (Users and Organizational Unit) and it allows to select them and add tasks
 */

@Component({
    selector: 'app-select-organizationalUnit-tree',
    templateUrl: './select-organizational-unit-tree.component.html',
    styleUrls: ['./select-organizational-unit-tree.component.css']
})
export class SelectOrganizationalUnitTreeComponent implements OnInit, OnChanges {
    @ViewChild('tree', { static: true }) treeComponent: TreeComponent;
    @ViewChild(ErrorMessageComponent, { static: true }) errorMessage;
    @ViewChildren('turnarCheckboxUser') checkboxT: QueryList<MatCheckbox>;
    @Input() data: { addresseeType: string, data: Array<any> };

    faEdit = faEdit;
    faDelete = faTrashAlt;
    faTask = faTasks;
    faTrash = faTrash;
    faSearch = faSearchPlus;

    addresseType;

    users = [];
    // organizationalUnit = [];

    nodes = [];
    options: ITreeOptions = {
        // useCheckbox: true,
        // useTriState: false,
        useVirtualScroll: true,
        // actionMapping,  //Actions over the tree
        // scrollContainer: <HTMLElement>document.body.parentElement
    };



    constructor(
        private dialog: MatDialog,
        private store$: Store<RecipientsTreeReducer.State>,
        private organizationalUnitService: OrganizationalUnitService
    ) {
    }

    ngOnInit() {

    }

    buildTree(data: Array<OrganizationalUnitModel>) {
      this.refreshTree();

      if(data.length > 0) {
        this.buildOrganizationalUnitTree(data);
      }
    }

    refreshTree(){
        let roots = this.treeComponent.treeModel.roots;

        if ( roots !== undefined && roots !== null ) {
            roots.forEach(function(root){
                if (root.hasOwnProperty('data') && root.data.hasOwnProperty('children')) {
                  root.parent.data.children.splice(root.parent.data.children.indexOf(root.data), 1)
                }
            });
        }

        this.updateTree();
    }

    updateTree() {
        this.treeComponent.treeModel.update();
    }

    buildOrganizationalUnitTree(organizationalUnitObject){
      let self = this;
      // this.organizationalUnit = organizationalUnitObject;
      let organizationalUnits = organizationalUnitObject;

      organizationalUnits.forEach(function(organizationalUnit, cont) {
        organizationalUnits[cont] = self.organizationalUnitNodeData(organizationalUnits[cont]);

            if(organizationalUnits[cont].children !== null && organizationalUnits[cont].children !== undefined && organizationalUnits[cont].hasOwnProperty('children')) {
              organizationalUnits[cont].children = self.buildChildrenNode(organizationalUnits[cont].children);
            }

            self.nodes.push(organizationalUnits[cont]);

        });

        this.updateTree();
    }

    buildChildrenNode(childrenNodes : Array<any>) {
        let self = this;

        let children = childrenNodes.map(data => {
            return Object.assign({}, data);
        });

        children.forEach(function(node, index) {
            children[index] = self.organizationalUnitNodeData(children[index]);

            if(children[index].hasOwnProperty('children')) {
                children[index].children = self.buildChildrenNode(children[index].children);
            }
        });

        return children;
    }

    organizationalUnitNodeData(organizationalUnitObject: OrganizationalUnitModel){
        return {
            id: organizationalUnitObject.id,
            name: organizationalUnitObject.name,
            description: organizationalUnitObject.description,
            parent_id: organizationalUnitObject.parent_id,
            nodeType: ORGANIZATIONAL_UNIT_NODE,
            icon:  faSitemap,
            CC: false,
            TURNAR:  false,
            children: organizationalUnitObject.children,
            hidden: (organizationalUnitObject.hasOwnProperty('hidden')) ? organizationalUnitObject.hidden : false,
        };
    }

    getUsers(node) {
      this.organizationalUnitService.getMembers(node.data.id).subscribe(response => {
        if (response['status']) {
          this.store$.dispatch(new RecipientsTreeActions.StoreRecipients(response['users'], {
            id: node.data.id,
            name: node.data.name
          }));
        } else {

        }
      });
    }

    /**
     *
     * @param text
     */
    filterNodes(text) {
        if(text.trim().length > 0)
            this.treeComponent.treeModel.filterNodes(text.trim(), true);
        else
            this.treeComponent.treeModel.clearFilter();
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

}
