import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { EXTERNAL_SUBJECT_CODE, INTERNAL_SUBJECT_CODE } from '../_constants/SubjectTypeConstants';
import {
  MAILBOX_STATUS_ARCHIVAL,
  MAILBOX_STATUS_HISTORICAL,
  MAILBOX_TYPE_INBOX,
  MAILBOX_TYPE_INBOXOUT
} from '../_constants/MailboxConstants';
import { InboxPreviewDialogComponent } from './inbox-preview/inbox-preview-dialog.component';
import { InboxoutPreviewDialogComponent } from './inboxout-preview/inboxout-preview-dialog.component';
import { ExternalPreviewDialogComponent } from './external-preview-dialog/external-preview-dialog.component';
import { ExternalInboxoutPreviewComponent } from './external-inboxout-preview/external-inboxout-preview.component';
import { ExternalFinishedPreviewComponent } from './external-finished-preview/external-finished-preview.component';
import { InternalHistoricoPreviewDialogComponent } from './internal-historico-preview/internal-historico-preview-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AdService {

  constructor(private _componentFactoryResolver: ComponentFactoryResolver) {}

  getSubjectPreviewComponent(subjectType: string, mailboxType) {
    if ( subjectType === INTERNAL_SUBJECT_CODE) {
      switch (mailboxType) {
        case MAILBOX_TYPE_INBOX:
          return InboxPreviewDialogComponent;
        case MAILBOX_TYPE_INBOXOUT:
          return InboxoutPreviewDialogComponent;
        case MAILBOX_STATUS_HISTORICAL:
          return InternalHistoricoPreviewDialogComponent;
        case MAILBOX_STATUS_ARCHIVAL:
          return InternalHistoricoPreviewDialogComponent;
        // return new AdItem(
        //   this._componentFactoryResolver.resolveComponentFactory(InboxPreviewDialogComponent),
        //   { headline: 'Openings in all departments', body: 'Apply today' }
        // );
      }
    }

    if ( subjectType === EXTERNAL_SUBJECT_CODE) {
      /** Cuando es un asunto externo  */
      switch (mailboxType) {
        case MAILBOX_TYPE_INBOX:
          return ExternalPreviewDialogComponent;
        case MAILBOX_TYPE_INBOXOUT:
          return ExternalInboxoutPreviewComponent;
        case MAILBOX_STATUS_HISTORICAL:
          return ExternalFinishedPreviewComponent;
        case MAILBOX_STATUS_ARCHIVAL:
          return InternalHistoricoPreviewDialogComponent;
      }
    }

    return null;
  }
}
