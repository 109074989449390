import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { Helper } from "../_helpers/Helper";
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};
@Injectable({
  providedIn: 'root'
})
export class SignatureService {
  url = environment.apiUrl + '/api/signature';

  constructor(
    private http: HttpClient, helper: Helper,

  ) { }

  /**
   *
   * @param params
   */
  public uploadPfx(params): Observable<any> {
    return this.http.post(this.url , params );
  }

  /**
   *
   */
  public getUserSignatureMethods() {
    return this.http.get(this.url + '/userSignatureMethods' , httpOptions );
  }

  /**
   *
   * @param params
   */
  public signatureAuthorization(params: { password: string, participant_id: number, user_signature_method_id: number } ) {
    return this.http.post(this.url + '/signatureAuthorization' , params );
  }

  /**
   *
   * @param params
   */
  public uploadSatFiles(params): Observable<any> {
    return this.http.post(this.url + '/sat' , params );
  }
}
