import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LocalstorageService } from './Localstorage.service';
import { CAT_SYSTEM_USER_ROLE } from '../_constants/CatSystemUserRoleConstants';
import { UserRole } from '../_constants/CatUserRoleConstants';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root'
})
export class UsersService {

    constructor(
        private http: HttpClient,
        private LocalStorage: LocalstorageService
        ) { }

    url = environment.apiUrl + '/api/user';

    /**
     *
     * @param {any} idUser
     * @returns {Observable<any>}
     */
    getUsers(idUser = null): Observable<any> {
        const url = (idUser === null) ? this.url :  this.url + '/' + idUser;
        return this.http.get(url, httpOptions);
    }

    getUsersByOrganizationalUnit(params): Observable<any> {
        httpOptions['params'] = params;
        return this.http.get(this.url + '/getUsersByOrganizationalUnit', httpOptions);
    }

    getInactiveUsersByOrganizationalUnit(params): Observable<any> {
      httpOptions['params'] = params;
      return this.http.get(this.url + '/getInactiveUsersByOrganizationalUnit', httpOptions);
    }

    /**
     * add a new user from admin console
     * @returns {Observable<any>}
     */
    public newUser(userData): Observable<any> {
        return this.http.post(this.url + '/register', userData );
    }

    /**
     * signup from login interface
     * @param userData
     */
    public signUp(userData): Observable<any> {
        return this.http.post(this.url + '/signup', userData );
    }

    /**
     *
     * @param {number} idUser
     * @param userData
     * @returns {Observable<any>}
     */
    public updateUser(idUser: number, userData): Observable<any> {
        return this.http.put(this.url + '/' + idUser, userData);
    }

  /**
   *
   * @param {number} idUser
   * @param password
   * @returns {Observable<any>}
   */
  public resetPassword(idUser: number, password: string): Observable<any> {
    return this.http.put(this.url + '/reset/' + idUser, password);
  }

    /**
     *
     * @param {number} idUser
     * @returns {Observable<any>}
     */
    public deleteUser(idUser: number): Observable<any> {
        return this.http.delete(this.url + '/' + idUser, httpOptions);
    }

  /**
   *
   * @param {number} idUser
   * @returns {Observable<any>}
   */
  public enableUser(idUser: number): Observable<any> {
    return this.http.put(this.url + '/enable/' + idUser, httpOptions);
  }

    /**
     *
     * @param params
     */
    public checkPassword(params): Observable<any> {
        return this.http.post(this.url + '/checkpassword', params);
    }

    /**
     *
     * @param params
     */
    public changePassword(params): Observable<any> {
        return this.http.post(this.url + '/changepassword', params);
    }

    /**
     *
     */
    public getOperadoresByUnidadOrganizacional(organizationalUnitId) {
        return this.http.get(this.url + '/operadores/ByUnidadOrganizacional/' + organizationalUnitId, httpOptions);
    }

    /**
     *
     */
    public getUserProfile() {
        return this.http.get(this.url + '/signed/profile', httpOptions);
    }

    /**
     *
     * @param data
     */
    public uploadFirel(data) {
        return this.http.post(this.url + '/upload/firel', data);
    }

    /**
     *
     * @param data
     */
    public updateProfile(data) {
        return this.http.post(this.url + '/update/profile', data);
    }

    get userData(): any {
        return this.LocalStorage.getUserData();
    }

    public hasRoleTitular(): boolean {
        return this.userData?.organizationalUnits?.some(organizationalUnit => organizationalUnit.role.code === UserRole.TITULAR);
    }

    public hasAssistantRole(): boolean {
      return this.userData?.organizationalUnits?.some(organizationalUnit => organizationalUnit.role.code === UserRole.ASISTENTE);
    }

    /**
     *
     * @param organizationalUnitId
     */
    public isTitular(organizationalUnitId): boolean {
      if (!this.userData.organizationalUnits) {
        return false;
      }

      const organizationalUnit = this.userData.organizationalUnits.find(role => role.id === organizationalUnitId);

      if (!organizationalUnit) {
        return false;
      }

      if (!organizationalUnit.role) {
        return false;
      }

      return organizationalUnit.role.code === UserRole.TITULAR;
    }

    /**
     * returns the asistente status flag of user
     */
    public isAsistente(organizationalUnitId: number): boolean {
      if (!this.userData.organizationalUnits) {
        return false;
      }

      const organizationalUnit = this.userData.organizationalUnits.find(role => role.id === organizationalUnitId);

      if (!organizationalUnit) {
        return false;
      }

      if (!organizationalUnit.role) {
        return false;
      }

      return organizationalUnit.role.code === UserRole.ASISTENTE;
    }

    /**
     * returns the operador status flag of user
     */
    public isOperador(organizationalUnitId): boolean {
      if (!this.userData.organizationalUnits) {
        return false;
      }

      const organizationalUnit = this.userData.organizationalUnits.find(role => role.id === organizationalUnitId);

      if (!organizationalUnit) {
        return false;
      }

      if (!organizationalUnit.role) {
        return false;
      }

      return organizationalUnit.role.code === UserRole.OPERADOR;
    }

  /**
   *
   */
    public isRoot(): boolean {
        const userData = this.userData;
        return userData !== undefined && userData !== null && typeof userData == 'object' && userData.id === 1;
    }

    /**
     * returns the operador status flag of user
     */
    public isAdmin(): boolean {
        const userData = this.userData;

        if (userData === undefined || userData === null || !userData.hasOwnProperty('systemRole')) {
            return false;
        }

        return userData.systemRole.code === CAT_SYSTEM_USER_ROLE.ADMIN;
    }

  /**
   *
   * @param string
   */
    public getAvatarInitials(string: string): string {
      let names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 2) {
        initials += ' ' + names[names.length - 2].substring(0, 1).toUpperCase();
      } else {
        if (names.length > 1) {
          initials += ' ' + names[names.length - 1].substring(0, 1).toUpperCase();
        }
      }

      return initials;
    }

    public addUserPicture(data): any {
      return this.http.post(this.url + '/addProfilePicture', data);
    }

    public deleteProfile(data): any {
      return this.http.post(this.url + '/deleteProfilePicture', data, httpOptions);
    }

    public profileSetting(data): any {
      return this.http.get(this.url + '/profileSetting/' + data, httpOptions);
    }

    public getProfile(data): any {
      return this.http.get(this.url + '/getProfilePicture/' + data + '/original_', {responseType: 'blob'});
    }

  /**
   * @returns Observable
   * @param params
   */
  public getAssistant(params): Observable<any> {
    return this.http.get(this.url + '/getAssistantFromOrganizationalUnit/' + params);
  }
}
