import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { CAT_DATA_TYPE } from '../../_core/constants/CatDataType';
import { MetadataModel } from '../../_core/_models/Metadata.model';


@Injectable({
    providedIn: 'root'
})
export class DynamicFormBuilder {

  static returnFormField(field) {
    let field_name;
    if ( field.hasOwnProperty ( 'cat_data_type' )) {
      field_name = field.cat_data_type.field_name.toLowerCase();
    } else {
      field_name = field.name.toLowerCase();
    }
    switch (field_name) {
      case CAT_DATA_TYPE.STRING:
        return DynamicFormBuilder.stringField(field);
      case CAT_DATA_TYPE.TEXT:
        return DynamicFormBuilder.textField(field);
      case CAT_DATA_TYPE.DATE_TIME:
        return DynamicFormBuilder.dateTimeField(field);
      case CAT_DATA_TYPE.CATALOG:
        return DynamicFormBuilder.catalogField(field);
      case CAT_DATA_TYPE.BOOLEAN:
        return DynamicFormBuilder.booleanField(field);
      case CAT_DATA_TYPE.INTEGER:
        return DynamicFormBuilder.numberField(field);
      case 'section':
        return DynamicFormBuilder.sectionField(field);
      default:
        break;
    }
  }

  static buildFormArch(form, metadata) {
    for (const field of metadata) {
      let field_name;
      if ( field.hasOwnProperty ( 'cat_data_type' )) {
        field_name = field.cat_data_type.field_name.toLowerCase();
      } else {
        field_name = field.name.toLowerCase();
      }

      switch (field_name) {
        case CAT_DATA_TYPE.STRING:
          form.push(DynamicFormBuilder.stringField(field));
          break;
        case CAT_DATA_TYPE.TEXT:
          form.push(DynamicFormBuilder.textField(field));
          break;
        case CAT_DATA_TYPE.DATE_TIME:
          form.push(DynamicFormBuilder.dateTimeField(field));
          break;
        case CAT_DATA_TYPE.CATALOG:
          form.push(DynamicFormBuilder.catalogField(field));
          break;
        case CAT_DATA_TYPE.BOOLEAN:
          form.push(DynamicFormBuilder.booleanField(field));
          break;
        case CAT_DATA_TYPE.INTEGER:
          form.push(DynamicFormBuilder.numberField(field));
          break;
        case CAT_DATA_TYPE.SECTION:
          form.push(DynamicFormBuilder.sectionField(field));
          break;
        default:
          form.push();
          break;
      }
    }
    return form;
  }

  private static sectionField(field?: MetadataModel) {
    return {
      type: CAT_DATA_TYPE.SECTION,
      name: CAT_DATA_TYPE.SECTION,
      field_name_text: field.field_name_text,
      system_field: field.system_field
    };
  }

  private static stringField(field: MetadataModel) {
    const validations = [];

    if (field.required) {
      validations.push(
        {
          name: 'required',
          validator: Validators.required,
          message: field.field_name_text + ' requerido'
        }
      );
    }

    if (field.max_length > 0) {
      validations.push({
        name: 'maxlength',
        validator: Validators.maxLength(field.max_length),
        message: field.field_name_text + ' requiere máximo ' + field.max_length + ' caracteres'
      });
    }

    if (field.min_length > 0) {
      validations.push({
        name: 'minlength',
        validator: Validators.minLength(field.min_length),
        message: field.field_name_text + ' requiere mínimo ' + field.min_length + ' caracteres'
      });
    }

    return {
      type: 'input',
      label: field.field_name_text,
      inputType: 'text',
      name: field.field_name,
      value: field.value,
      validations: validations,
      readonly: field.readonly,
    };
  }

  private static numberField(field: MetadataModel) {
    const validations = [];

    if (field.required) {
      validations.push(
        {
          name: 'required',
          validator: Validators.required,
          message: field.field_name_text + ' requerido'
        }
      );
    }

    if (field.max_length > 0) {
      validations.push({
          name: 'maxlength',
          validator: Validators.maxLength(field.max_length),
          message: field.field_name_text + ' requiere máximo ' + field.max_length + ' caracteres'
        },
        {
          name: 'maxlength',
          validator: Validators.pattern('^[0-9]*$'),
          message: 'El dato debe ser numérico'
        });
    }

    if (field.min_length > 0) {
      validations.push({
        name: 'minlength',
        validator: Validators.minLength(field.min_length),
        message: field.field_name_text + ' requiere mínimo ' + field.min_length + ' caracteres'
      }, {
        name: 'minlength',
        validator: Validators.pattern('^[0-9]*$'),
        message: 'El dato debe ser numérico'
      });
    }
    return {
      type: 'input',
      label: field.field_name_text,
      inputType: 'number',
      name: field.field_name,
      value: field.value,
      validations: validations,
      readonly: field.readonly,
    };
  }

  private static textField(field: MetadataModel) {
    const validations = [];

    if (field.required) {
      validations.push(
        {
          name: 'required',
          validator: Validators.required,
          message: field.field_name_text + ' requerido'
        }
      );
    }

    if (field.max_length > 0) {
      validations.push({
        name: 'maxlength',
        validator: Validators.maxLength(field.max_length),
        message: field.field_name_text + ' requiere maximo ' + field.max_length + ' caracteres'
      });
    }

    if (field.min_length > 0) {
      validations.push({
        name: 'minlength',
        validator: Validators.minLength(field.min_length),
        message: field.field_name_text + ' requiere mínimo ' + field.min_length + ' caracteres'
      });
    }

    return {
      type: 'textarea',
      label: field.field_name_text,
      inputType: 'text',
      name: field.field_name,
      value: field.value,
      validations: validations,
      readonly: field.readonly,
    };
  }

  private static dateTimeField(field: MetadataModel) {
    const validations = [];

    if (field.required) {
      validations.push(
        {
          name: 'required',
          validator: Validators.required,
          message: field.field_name_text + ' requerido'
        }
      );
    }


    return {
      type: 'date',
      label: field.field_name_text,
      name: field.field_name,
      validations: validations,
      value: field.value,
      readonly: field.readonly,
    };
  }

  private static catalogField(field: MetadataModel) {
    const validations = [];

    const value = parseInt(field.value, 10) > 0 ? parseInt(field.value, 10) : '';

    if (field.required) {
      validations.push(
        {
          name: 'required',
          validator: Validators.required,
          message: field.field_name_text + ' requerido'
        }
      );
    }

    return {
      type: 'select',
      label: field.field_name_text,
      name: field.field_name,
      options: field.catalogData,
      validations: validations,
      value: value,
      readonly: field.readonly,
    };
  }

  private static booleanField(field: MetadataModel) {
    const validations = [];

    if (field.required) {
      validations.push(
        {
          name: 'required',
          validator: Validators.required,
          message: field.field_name_text + ' requerido'
        }
      );
    }

    return {
      type: 'checkbox',
      label: field.field_name_text,
      name: field.field_name,
      validations: validations,
      value: field.value == null ? false : field.value == '1',
      readonly: field.readonly,
    };
  }

  static flatDeep(arr, d = 1) {
    return d > 0 ? arr?.reduce((acc, val) => acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val), [])
      : arr.slice();
  }
}
