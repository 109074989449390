import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs';
import { SubjectRequestService } from "./subject-request.service";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root'
})
export class GeneratepdfService {
  public acceptsString: string = 'x-png,jpeg,jpg';

  constructor(
    private http: HttpClient,
    private subjectRequest: SubjectRequestService
    ) { }

  url = environment.apiUrl + '/api/systemSettings';
  url2 = environment.apiUrl + '/api/pdf/';

  public folioReport(params): Observable<any> {
      return this.http.post(this.url2 + 'folioReport', params, { headers: httpOptions.headers, responseType: 'blob' });
  }

  public subjectReport(params): Observable<any> {
    const Tabledef = {
      'heads'  : ['Unidad Organizacional', 'Nombre y Tipo Recepción', 'Fecha'],
      'fields' : ['organizationalUnitName', 'userFull_name_prefix', 'created_at'],
      'width'  : [60,60,60],
      'align'  : ['L','l','C'],
      'font_head' : {'name' : 'Arial', 'size' : 11},
      'font_body' : {'name' : 'Arial', 'size' : 9},
      'BorderBody' : false
    };
    const content = {
      title: params.title,
      table: Tabledef,
      subjectRequest:   params.subjectRequest,
      fullMetadata: params.fullMetadata,
      orientation: 'P'
    }

    return this.http.post(this.url2 + 'subjectReport', content, { headers: httpOptions.headers, responseType: 'blob' });
  }

}
