import * as NewSubjectActions from './../actions/new-subject.actions'
import { SubjectSeverityModel } from "../../_models/SubjectSeverityModel";
import { EfirmaModel } from '../../_models/EfirmaModel';
import { DepartmentFolioModel } from "../../_models/DepartmentFolio.model";
import { RequestFolioModel } from "../../../../../../src/app/_models/RequestFolio.model";

export interface State {
    organizationalUnits: Array<any>,
    users: Array<any>,
    severities: Array<SubjectSeverityModel>,
    subjectRequest: any,
    efirma: EfirmaModel,
    recipients: {nodesObject: Array<any>, nodesTask: Array<any>, nodesData: Array<any>}
    recipientType: string;
    subjectType: string;
    currentOrganizationalUnitId: number,
    currentProcessId: number,
    subjectSettings: any,
    metadata: { defaultFields: Array<any>, processFields: Array<any>, processFieldsIdValue: Array<any>, senderFields: Array<any>},
    isLoading: boolean,
  originOrganizationalUnitFolio: DepartmentFolioModel,
  destinOrganizationalUnitFolio: DepartmentFolioModel,
  separatedFolio: RequestFolioModel|null
}

const initialState: State = {
    organizationalUnits: [],
    users: [],
    severities: [],
    subjectRequest: [],
    efirma: null,
    recipients: {nodesObject: [], nodesTask: [], nodesData: []},
    recipientType: null,
    subjectType: null,
    currentOrganizationalUnitId: null,
    currentProcessId: null,
    subjectSettings: null,
    metadata: {defaultFields: [],processFields: [], processFieldsIdValue: [], senderFields: []},
    isLoading: false,
  originOrganizationalUnitFolio: null,
  destinOrganizationalUnitFolio: null,
  separatedFolio: null
};

export function reducer(state = initialState, action: NewSubjectActions.Actions) : any  {

    switch(action.type) {
        case NewSubjectActions.ADD_RECIPIENTS:
            const recipients = {nodesObject: action.nodesObject, nodesTask: action.nodesTask, nodesData: action.nodesData};
            return {...state, recipients: recipients};
        case NewSubjectActions.CLEAR_ALL_RECIPIENTS:
            return {...state, recipients: []};
        case NewSubjectActions.STORE_SUBJECT_TYPE:
            return {...state, subjectType: action.subjectType};
        case NewSubjectActions.STORE_ORGANIZATIONAL_UNITS:
            return {...state, organizationalUnits: action.payload};
        case NewSubjectActions.STORE_USERS:
            return {...state, users: action.payload};
        case NewSubjectActions.CLEAR_ALL:
            return initialState;
        case NewSubjectActions.STORE_SEVERITIES:
            return {...state, severities: action.severities};
        case NewSubjectActions.ADD_TO_SUBJECT_REQUEST_DATA:
            let data = {...state.subjectRequest, ...action.payload};
            return {...state, subjectRequest: data};
        case NewSubjectActions.CLEAR_SUBJECT_REQUEST_DATA:
            return {...state, subjectRequest: [], recipients: [], metadata: [], documentSettings: null};
        case NewSubjectActions.SET_RECIPIENT_TYPE:
            return {...state, recipientType: action.recipientType};
        case NewSubjectActions.STORE_SUBJECT_SETTINGS:
            return {...state, subjectSettings: action.settings}
        case NewSubjectActions.CLEAR_CREATING_SUBJECT_DATA:
            let initialStateCopy = {...initialState};
            delete initialStateCopy.severities;
            delete initialStateCopy.currentOrganizationalUnitId;
            delete initialStateCopy.organizationalUnits;
            delete initialStateCopy.subjectType;
            return {...state, ...initialStateCopy};
        case NewSubjectActions.STORE_METADATA:
            const metadata = {
                defaultFields: action.defaultFields,
                processFields: action.processFields,
                processFieldsIdValue: action.processFieldsIdValue,
                senderFields: action.senderFields
            };
            return {...state, metadata: metadata}
        case NewSubjectActions.ADD_SERIE_EFIRMA:
            return {...state, efirma: action.payload};
        case NewSubjectActions.SET_CURRENT_ORGANIZATIONALUNITID:
            return {...state, currentOrganizationalUnitId: action.organizationalUnitId};
        case NewSubjectActions.SET_CURRENT_PROCESS_ID:
            return {...state, currentProcessId: action.processId}
        case NewSubjectActions.SET_ISLOADING:
            return {...state, isLoading: action.isLoading  };
        case NewSubjectActions.STORE_ORIGIN_DEPARTMENT_FOLIO: {
          return {...state, originOrganizationalUnitFolio: action.data  }
        }
        case NewSubjectActions.STORE_DESTIN_DEPARTMENT_FOLIO: {
          return {...state, destinOrganizationalUnitFolio: action.data  }
        }
        case NewSubjectActions.REMOVE_DESTIN_DEPARTMENT_FOLIO: {
          return { ...state, destinOrganizationalUnitFolio: null }
        }
        case NewSubjectActions.REMOVE_ORIGIN_DEPARTMENT_FOLIO: {
          return { ...state, originOrganizationalUnitFolio: null }
        }
      case NewSubjectActions.SET_SEPARATED_FOLIO: {
        return { ...state, separatedFolio: action.folio }
      }
        default:
            return state;
    }
}


