import { Action } from '@ngrx/store';
import { ParticipantModel } from '../../_models/Participant.model';
import { OfficeModelInterface } from '../../_models/OfficeModelInterface';
import { UserModel } from "../../../../../../src/app/_models/user.model";
import { RubricaModel } from "../../_models/Rubrica.model";

export const STORE_DOCUMENT_PARTICIPANTS = '[Office] Store Document Participant';
export const CLEAR_ALL = '[Office] Clear All';
export const SET_IS_LOADING = '[Office] Set Is Loading';
export const SET_IS_LOADING_DIALOG = '[Office] Set Is Loading DIALOG';
export const STORE_OFFICE = '[Office] Store Office';
export const UPDATE_OFFICE = '[Office] Update Office';
export const SET_VALID_DOCUMENT_METADATA = '[Office] Set Valid Document Metadata';
export const STORE_DOCUMENT = '[Office] Store Document';
export const ADD_OFFICE_PARTICIPANT = '[Office] Add Office Participant';
export const SET_CURRENT_ORGANIZATIONAL_UNIT_ID = '[Office] Set Current Organizational Unit Id';
export const SET_CURRENT_PARTICIPANT_ID = '[Office] Set Current Participant Id';
export const UPDATE_RUBRICA = '[Office] Update Rubrica';
export const HAS_CHANGES  = '[Office] Has Changes';
export const UPDATE_PARTICIPANT_ATTRIBUTES = '[Office] Update Participant Attribute';
export const SET_IS_OPEN_OFFICE  = '[Office] Set Is Open office';

export class SetValidDocumentMetadata implements Action {
  readonly type = SET_VALID_DOCUMENT_METADATA;
  constructor(public documentMetadata: boolean) {}
}

export class StoreDocument implements Action {
  readonly type = STORE_DOCUMENT;
  constructor(public document:OfficeModelInterface) {}
}

export class setIsLoading implements Action {
  readonly type = SET_IS_LOADING;
  constructor(public isLoading: boolean) {}
}

export class setIsLoadingDialog implements Action {
  readonly type = SET_IS_LOADING_DIALOG;
  constructor(public isLoading: boolean) {}
}

export class ClearAll implements Action{
  readonly type = CLEAR_ALL;
  constructor() {}
}

export class StoreDocumentParticipant implements Action {
  readonly type = STORE_DOCUMENT_PARTICIPANTS;
  constructor(public documentParticipant: ParticipantModel) {}
}

export class SetIsLoading implements Action {
  readonly type = SET_IS_LOADING;
  constructor(public isLoading: boolean) {}
}

export class StoreOffice implements Action {
  readonly type = STORE_OFFICE;
  constructor(public document:OfficeModelInterface) {}
}

export class UpdateOffice implements Action {
  readonly type = UPDATE_OFFICE;
  constructor(public officeAttributes: any) {
  }
}

export class AddOfficeParticipant implements Action {
  readonly type = ADD_OFFICE_PARTICIPANT;
  constructor(public participant: ParticipantModel) {
  }
}

export class SetCurrentOrganizationalUnitId implements Action {
  readonly type = SET_CURRENT_ORGANIZATIONAL_UNIT_ID;
  constructor(public organizationalUnitId: number) {
  }
}

export class SetCurrentParticipantId implements Action {
  readonly type = SET_CURRENT_PARTICIPANT_ID;
  constructor(public participant_id: number) {
  }
}

export class UpdateRubrica implements Action {
  readonly type = UPDATE_RUBRICA;
  constructor(public rubrica: RubricaModel) {
  }
}

export class SetHasChanges implements Action {
  readonly type = HAS_CHANGES;
  constructor(public hasChanges: boolean) {
  }
}

export class UpdateParticipantAttributes implements Action {
  readonly type = UPDATE_PARTICIPANT_ATTRIBUTES;
  constructor(public attributes: any) {
  }
}

export class setIsOpenOffice implements Action {
  readonly type = SET_IS_OPEN_OFFICE;
  constructor(public isOpenOffice: boolean) {}
}

export type Actions =
  | ClearAll
  | SetValidDocumentMetadata
  | StoreDocument
  | setIsLoading
  | StoreDocumentParticipant
  | SetIsLoading
  | StoreOffice
  | UpdateOffice
  | AddOfficeParticipant
  | setIsLoadingDialog
  | SetCurrentOrganizationalUnitId
  | SetCurrentParticipantId
  | UpdateRubrica
  | SetHasChanges
  | UpdateParticipantAttributes
  | setIsOpenOffice;
