import { Component, Input, OnInit } from '@angular/core';
import { SubjectNotificationService } from "../../_services/subject-notification.service";
import { Store } from "@ngrx/store";
import * as NotificationReducer from "../../_store/reducers/notification.reducer";
import * as NotificationSelector from "../../_store/selectors/notification.selector";
import * as NotificationAction from "../../_store/actions/notification.action";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { Observable } from "rxjs";
import { NotificationInterface } from "../../_models/notification.interface";
import { LocalstorageService } from '../../_services/Localstorage.service';

@Component({
  selector: 'app-subject-notifications',
  templateUrl: './subject-notifications.component.html',
  styleUrls: ['./subject-notifications.component.css']
})
export class SubjectNotificationsComponent implements OnInit {
  faTrash = faTrashAlt;
  faEye = faEye;
  unreadNotifications: Observable<number>;
  subjectNotifications: Observable<Array<NotificationInterface>>;
  LookSubjet:any = null;
  constructor(
    public localStorage: LocalstorageService,
    private subjectNotificationService: SubjectNotificationService,
    private notification$: Store<NotificationReducer.State>,
  ) { }

  ngOnInit(): void {
    this.getNotifications();
    this.timeRefresh(60000 * 3);

    this.unreadNotifications = this.notification$.select(NotificationSelector.getTotalUnreadNotifications);
    this.subjectNotifications = this.notification$.select(NotificationSelector.getNotifications);
  }

  /**
   *
   * @private
   */
  private getNotifications() {
    const activesection = this.localStorage.getToken();
    if (activesection === null) {
      this.closeNotificate();
    } else {
    this.subjectNotificationService.get().subscribe(
      response => {
        if (response['status']) {
          this.notification$.dispatch(new NotificationAction.StoreNotifications(response['notifications']));
        } else {

        }
      }, error => {
        console.error(error)
      }
    );
    }
  }

  /**
   *
   * @param time
   * @private
   */
  private timeRefresh(time){
    if (this.LookSubjet === null) {
      this.LookSubjet = setInterval(() => { this.getNotifications(); }, time);
    }
  }

  /**
   * Remove all user notifications
   * @private
   */
  private removeAllElements() {
    this.subjectNotificationService.removeAll().subscribe(response => {
      if (response['status']) {
        this.notification$.dispatch(new NotificationAction.StoreNotifications([]));
      } else {
        console.error(response);
      }
    }, error => {
        console.log(error);
    });
  }

  private markAllAsOpen() {
    this.subjectNotificationService.markAllAsOpen().subscribe(response => {
      if (response['status']) {
        this.notification$.dispatch(new NotificationAction.MarkAllNotificationsAsOpen());
      } else {
        console.error(response);
      }
    }, error => {
      console.log(error);
    });
  }

  closeNotificate() {
    clearInterval(this.LookSubjet);
  }

}
