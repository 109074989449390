import { Component, OnInit } from '@angular/core';
import { UsersService } from "../_services/users.service";
import { environment } from "../../environments/environment";
import { UserModel } from "../_models/user.model";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { UserProfilePictureComponent } from "../user-profile/user-profile-picture/user-profile-picture.component";
import { isObject } from "rxjs/internal-compatibility";

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit {
  profileUrl: string|ArrayBuffer;
  userData: UserModel;

  constructor(
    private userService: UsersService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.userData = this.getUserData();

    this.profileUrl = environment.apiUrl + '/api/user/getProfilePicture/' + this.userData.id;
  }

  getUserData() {
    return this.userService.userData;
  }

  avatarClicked(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = data;
    dialogConfig.width = '744px';
    dialogConfig.height = '580px';

    const dialogRef = this.dialog.open(UserProfilePictureComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(responseUpdate => {
      if (isObject(responseUpdate)) {
        if (responseUpdate.status && responseUpdate.type == 'delete_image') {
          console.log(responseUpdate);
          this.profileUrl = environment.apiUrl + '/api/user/getProfilePicture/0';
        } else {
          this.preview(responseUpdate);
        }
      }
    });
  }

  preview(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.profileUrl = reader.result;
    };
  }

}
