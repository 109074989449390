import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AddresseeModel } from "../../_models/addresseeModel";
import { UsersService } from "../../_services/users.service";
import { Helper } from "../../_helpers/Helper";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: 'app-subject-reviewed',
  templateUrl: './subject-reviewed.component.html',
  styleUrls: ['./subject-reviewed.component.css']
})
export class SubjectReviewedComponent implements OnInit, OnChanges {
  @Input('recipients') recipients: Array<AddresseeModel> = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['reception_date', 'reception_type', 'organizationalUnit', 'username', 'last_seen'];
  dataSource: MatTableDataSource<AddresseeModel>;

  constructor(
      private userService: UsersService
  ) {
  }

  ngOnInit() {
  }

  getReceptionType(recipient) {
    return Helper.getReceptionType(recipient);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.hasOwnProperty('recipients') && changes.recipients.firstChange) {
      const recipients = this.recipients.filter(recipient => recipient.user.id !== this.userService.userData.id);
      this.dataSource = new MatTableDataSource(recipients);
      this.dataSource.paginator = this.paginator;
    }
  }

}
