import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrganizationalUnitService } from '../../_services/organizational-unit.service';
import { WarningConfirmationDialogComponent } from '../../dialog/warning-confirmation-dialog/warning-confirmation-dialog.component';
import { NotifierComponent } from '../../notifier/notifier.component';
import { FunctionExporter } from '../../../../projects/archivistica/src/app/_core/_helper/functionExporter';

const changeActions = {
  normalChange: 'normalChange',
  changeWithCopy: 'changeWithCopy'
};

@Component({
  selector: 'app-migration-users',
  templateUrl: './migration-users.component.html',
  styleUrls: ['./migration-users.component.css']
})
export class MigrationUsersComponent implements OnInit {
    @ViewChild('notifierComponent', { static: true }) notifier: NotifierComponent;
    @ViewChild('stepper') stepper: any;
    @Input() isModal = true;
    @Input() idOrganizationalUnit;

    public firstFormGroup = new FormGroup({
        firstCtrl: new FormControl({ value: '', disabled: false },  [Validators.required])
    });

    public secondFormGroup = new FormGroup({
        secondCtrl: new FormControl({ value: '', disabled: false }, [Validators.required]),
        disabledUser: new FormControl({ value: false, disabled: false }),
    });

    public isEditable = false;
    public checkbox = false;

    public loadProcess = {
        actionModalReplace: false,
        replaceUser: false,
    };
    public isComplete = {
        one: false,
        two: false,
    };
    public users = {
        usersAll: [],
        usersOptions: [],
        usersOptions_2: [],
        replaceUser: {
            user: undefined
        },
        newUser: {},
    };
    private selectMigrateParams = [
        { value: '', step: '', },
        { value: '', step: '', }
    ];

    constructor(
            public dialogRef: MatDialogRef<MigrationUsersComponent>,
            private organizationalUnitService: OrganizationalUnitService,
            private _formBuilder: FormBuilder,
            private dialog: MatDialog,
            @Inject(MAT_DIALOG_DATA) public dialogData,
    ) { }

    ngOnInit(): void {
        if (this.dialogData.hasOwnProperty('allUsers')) {
            this.users.usersOptions = this.dialogData.allUsers.filter(value => value.role.id === this.dialogData.upwardRole.id);
            this.users.newUser = this.dialogData.userSelected.user;
        }
    }

    // Migrate users

    public setValueForm(value, step) {
        this.selectMigrateParams[step].step = step;
        this.selectMigrateParams[step].value = value;
        switch (step) {
            case 0: this.isComplete.one = true; break;
            case 1: this.isComplete.two = true; break;
        }
    }

    public test_one() {
        this.handleSelectedOption('.radio', '.radio_2', 0);
    }

    public test_two() {
        this.handleSelectedOption('.radio_3', '.radio_4', 1);
    }

    private handleSelectedOption(selector1: string, selector2: string, index: number) {
        const option1 = document.querySelector(selector1 + ':checked');
        const option2 = document.querySelector(selector2 + ':checked');

        if (!option1 && !option2) {
            FunctionExporter.showErrorNotifier('Debes de seleccionar una opcion', this.notifier);
            return;
        }

        if (index === 0 && this.selectMigrateParams[index].value === 'basic') {
            return this.dialogRef.close({ changeActions: changeActions.normalChange });
        }

        if (index === 1 && this.selectMigrateParams[index].value === 'basic') {
            const params = {
                originOrganizationalUnitId: this.dialogData.userSelected.organizationalUnit_id,
                destinUserId: this.dialogData.userSelected.user.id,
                originUserId: null,
                type: this.dialogData.operation.type,
                disableUser: false,
            };
            return this.dialogRef.close({ changeActions: changeActions.changeWithCopy, params: params });
        }

        if (index === 1 && this.selectMigrateParams[index].value === 'exist') {
            this.buildTableUser(this.dialogData.userSelected.organizationalUnit_id);
        }
    }

    public closeDialog() {
        this.dialogRef.close(false);
    }

    // Replace users

    public buildTableUser(organizationalUnit_id = null) {
        let idOrganizational = 0;
        if (organizationalUnit_id == null) { idOrganizational = this.idOrganizationalUnit;
        } else { idOrganizational = organizationalUnit_id; }

        this.organizationalUnitService.getMembersOfOrganizationalUnit(idOrganizational).subscribe(response => {
            if (response.status) {
                this.users.usersAll = response.users;
                this.users.usersOptions = JSON.parse(JSON.stringify(response.users));
                if (organizationalUnit_id !== null) {
                    const allUsers = JSON.parse(JSON.stringify(response.users));
                    return this.users.usersOptions_2 = allUsers.filter(value => ( value.user.id !== this.dialogData.userSelected.user.id )
                            && ( value.role.code === this.dialogData.upwardRole.code ));
                }
            }
        });
    }

    public resetFilter() {
        this.users.usersOptions = this.search('');
    }

    public onKey(event) {
        const filterValue = ( event.target as HTMLInputElement ).value.trim();
        this.users.usersOptions = this.search(filterValue);
    }

    private search(value: string) {
        if (value.length === 0) {
            return this.users.usersAll;
        }
        return this.users.usersAll.filter(option => option.name.toLowerCase().indexOf(value) >= 0);
    }

    public filterOptions() {
        if (this.firstFormGroup.valid) {
            const find = this.users.usersOptions.find(value => value.user.id === this.firstFormGroup.value.firstCtrl);
            this.users.usersOptions_2 = this.users.usersOptions.filter(value => ( value.user.id !== this.firstFormGroup.value.firstCtrl )
                    && ( value.role.code === find.role.code ));
            this.isComplete.one = true;
        }
    }

    public replaceUser() {
        const userReplace = this.users.usersAll.find(value => value.user.id === this.firstFormGroup.value.firstCtrl);
        const newReplace = this.users.usersAll.find(value => value.user.id === this.secondFormGroup.value.secondCtrl);

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '70%';
        dialogConfig.data = {
            title: 'Advertencia',
            textContent: 'Realmente desea realizar esta accion, todos los asuntos del usuario: ' +
                    ' <b> ' + userReplace.user.full_name_prefix + '</b>' + ' seran agregados a la bandeja de: ' +
                    ' <b> ' + newReplace.user.full_name_prefix + '</b>' + ', no se puede revertir ' +
                    '<br> ¿Desea continuar?'
        };
        dialogConfig.maxWidth = '350px';
        dialogConfig.panelClass = 'dialog-confirmation-class';

        const dialog = this.dialog.open(WarningConfirmationDialogComponent, dialogConfig);

        dialog.afterClosed().subscribe(userConfirmation => {
            if (userConfirmation !== null && userConfirmation !== undefined && userConfirmation.confirmation) {
                this.actionReplaceUser(userReplace, newReplace);
            } else {
                this.dialogRef.close();
            }
        });
    }

    public resetForm() {
        this.stepper.reset();
        this.firstFormGroup.reset();
        this.secondFormGroup.reset();
        this.isComplete.one = false;
        this.isComplete.two = false;
    }

    public actionModalReplace() {
        const params = {
            originOrganizationalUnitId: this.dialogData.userSelected.organizationalUnit_id,
            destinUserId: this.dialogData.userSelected.user.id,
            originUserId: this.users.replaceUser.user.id,
            disableUser: this.checkbox,
        };
        this.loadProcess.actionModalReplace = true;
        this.organizationalUnitService.replaceMember(params).subscribe(response => {
            if (response.status) {
                return this.dialogRef.close();
            }
        });
    }

    private actionReplaceUser(userReplace, newReplace) {
        const params = {
            originOrganizationalUnitId: this.idOrganizationalUnit,
            destinUserId: newReplace.user.id,
            originUserId: userReplace.user.id,
            disableUser: this.secondFormGroup.value.disabledUser,
        };
        this.loadProcess.replaceUser = true;
        this.organizationalUnitService.replaceMember(params).subscribe(response => {
            if (response.status) {
                if (userReplace.role.code !== newReplace.role.code) {
                    const paramsChangeRole = { role_id: userReplace.role.id, typeChange: changeActions.normalChange };
                    return this.updateRole(newReplace.user, paramsChangeRole);
                }
                return this.dialogRef.close();
            }
        });
    }

    private updateRole(user, params) {
        this.organizationalUnitService.updateMemberRole(this.idOrganizationalUnit, user.id, params).subscribe(response => {
            if (response.status) {
                return this.dialogRef.close();
            }
        });
    }

}
