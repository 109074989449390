import { Component, OnInit } from "@angular/core";

import { FormGroup } from "@angular/forms";

import { FieldConfig } from "../../_models/field.interface";

@Component({

    selector: "app-input-select",

    templateUrl: './input-select.component.html',

    styleUrls: ['./input-select.component.css']

})

export class InputSelectComponent implements OnInit {

    field: FieldConfig;

    group: FormGroup;

    selectedOptions: Array<{id: number, name: string }>;

    constructor() {}

    ngOnInit() {
      this.selectedOptions = this.field.options;
    }

  /* Receive user input and send to search method */
  onKey(value) {
    this.selectedOptions = this.search(value);
  }

  resetFilter(){
    this.selectedOptions = this.search('');
  }

  // Filter the states list and send back to populate the selectedStates**
  search(value: string) {
    value = value.trim();

    if ( value.length == 0) {
      return this.field.options;
    }

    let filter = value.toLowerCase();
    return this.field.options.filter(option => option.name.toLowerCase().indexOf(filter) >= 0);
  }
}
