import { Action } from '@ngrx/store';

export const STORE_GLOBAL_SETTINGS = '[System Settings] Store Global Settings';

export class StoreGlobalSettings implements Action {
    readonly type = STORE_GLOBAL_SETTINGS;
    constructor(public globalSettings: Array<any>) {}
}

export type Actions = StoreGlobalSettings;
