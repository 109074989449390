import { NgModule } from '@angular/core';
import { ErrorMessageComponent } from './messages/error-message/error-message.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadFileComponent } from './share-components/upload-file/upload-file.component';
import { NotifierModule } from 'angular-notifier';
import { AddresseeTreeComponent } from './new-subject/addressee-tree/addressee-tree.component';
import { TreeModule } from 'angular-tree-component';
import { MaterialModule } from './material.module';
import { SubjectSettingsComponent } from './share-components/subject-settings/subject-settings.component';
import { OrganizationalUnitTreeDialogComponent } from './organizational-unit-tree-dialog/organizational-unit-tree-dialog.component';
import { SubjectFlowComponent } from './subject-flow/subject-flow.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SanitizeHtmlPipe } from './_pipe/SanitizeHtmlPipe';
import { NotifierComponent } from './notifier/notifier.component';
import { GraphComponent } from './subject-flow/graph/graph.component';
import { DetailsComponent } from './subject-flow/details/details.component';
import { DocumentsComponent } from './subject-flow/documents/documents.component';
import { NewTaskDialogComponent } from './new-subject/new-task/new-task-dialog.component';
import { NotifyOptions } from './notifier/Options';
import { AvatarModule } from 'ngx-avatar';
import { SubjectNotificationsComponent } from './notifications-menu/subject-notifications/subject-notifications.component';
import { RowComponent } from './notifications-menu/row/row.component';
import { AvatarComponent } from './avatar/avatar.component';
import { EditorNotificationsComponent } from '../../projects/editor/src/app/notifications-menu/editor-notifications/editor-notifications.component';
import { EditorRowComponent } from '../../projects/editor/src/app/notifications-menu/row/editor-row.component';
import { SubstractPipe } from './_pipe/onlydatePipe';
import { SemaforoPipe } from './_pipe/semaforoPipe';
import { UserDataComponent } from './user-data/user-data.component';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavbarLogoComponent } from './navbar-logo/navbar-logo.component';
import { DangerConfirmationDialogComponent } from './dialog/danger-confirmation-dialog/danger-confirmation-dialog.component';
import { WarningConfirmationDialogComponent } from './dialog/warning-confirmation-dialog/warning-confirmation-dialog.component';
import { SubjectPreviewDirective } from './subject/subject-preview.directive';
import { FileUploadModule } from 'ng2-file-upload';
import { SolvedGraphDirective } from './_directives/solved-graph.directive';
import { SubjectAnswerDialogComponent } from './share-components/subject-answer/subject-answer-dialog.component';
import { AvanzarDialogComponent } from './share-components/avanzar/avanzar-dialog.component';
import { SubjectTypeDirectiveDirective } from './_directives/subject-type-directive.directive';
import { OrganizationalUnitTreeHierarchyComponent } from './organizational-unit-tree-hierarchy/organizational-unit-tree-hierarchy.component';
import { SelectOrganizationalUnitTreeComponent } from '../../projects/editor/src/app/editor/select-organizationalUnit-tree/select-organizational-unit-tree.component';
import { MigrationUsersComponent } from './admin-users/migration-users/migration-users.component';
import { FilterPipe } from './_pipe/filter.pipe';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule, // Error message uses Fontawesome
        ReactiveFormsModule,
        NotifierModule.withConfig(NotifyOptions),
        TreeModule.forRoot(),
        MaterialModule,
        NgxGraphModule,
        NgxChartsModule,
        AvatarModule,
        FormsModule,
        MatTooltipModule,
        RouterModule,
        FileUploadModule,
    ],
    declarations: [
        ErrorMessageComponent,
        UploadFileComponent,
        AddresseeTreeComponent,
        SubjectSettingsComponent,
        OrganizationalUnitTreeDialogComponent,
        OrganizationalUnitTreeHierarchyComponent,
        SubjectFlowComponent,
        SanitizeHtmlPipe,
        NotifierComponent,
        GraphComponent,
        DetailsComponent,
        DocumentsComponent,
        NewTaskDialogComponent,
        SubjectNotificationsComponent,
        RowComponent,
        AvatarComponent,
        EditorNotificationsComponent,
        EditorRowComponent,
        SubstractPipe,
        SemaforoPipe,
        FilterPipe,
        UserDataComponent,
        NavbarLogoComponent,
        DangerConfirmationDialogComponent,
        WarningConfirmationDialogComponent,
        SolvedGraphDirective,
        SubjectPreviewDirective,
        SubjectAnswerDialogComponent,
        AvanzarDialogComponent,
        SubjectTypeDirectiveDirective,
        SelectOrganizationalUnitTreeComponent,
        MigrationUsersComponent
    ],
    exports: [
        CommonModule,
        AvatarModule,
        FontAwesomeModule,
        TreeModule,
        ErrorMessageComponent,
        ReactiveFormsModule,
        UploadFileComponent,
        NotifierModule,
        AddresseeTreeComponent,
        NewTaskDialogComponent,
        SubjectSettingsComponent,
        OrganizationalUnitTreeDialogComponent,
        OrganizationalUnitTreeHierarchyComponent,
        SubjectFlowComponent,
        NgxGraphModule,
        NgxChartsModule,
        SanitizeHtmlPipe,
        NotifierComponent,
        SubjectNotificationsComponent,
        RowComponent,
        FormsModule,
        AvatarComponent,
        MaterialModule,
        EditorNotificationsComponent,
        EditorRowComponent,
        SubstractPipe,
        SemaforoPipe,
        FilterPipe,
        UserDataComponent,
        NavbarLogoComponent,
        DangerConfirmationDialogComponent,
        WarningConfirmationDialogComponent,
        SubjectPreviewDirective,
        SubjectTypeDirectiveDirective,
        SelectOrganizationalUnitTreeComponent,
        MigrationUsersComponent,
    ],
    entryComponents: [
        OrganizationalUnitTreeDialogComponent,
        OrganizationalUnitTreeHierarchyComponent,
        NewTaskDialogComponent,
        DangerConfirmationDialogComponent,
        WarningConfirmationDialogComponent,
        AvanzarDialogComponent,
        SubjectAnswerDialogComponent,
    ]
})
export class SharedModule {}
