import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import * as SubjectReducer from "../../_store/reducers/subject.reducer";
import * as SubjectSelector from "../../_store/selectors/subject.selector";
import { Observable } from "rxjs";
import { SubjectMessageInterface } from "../../_models/SubjectMessage.interface";

@Component({
  selector: 'app-subject-messages',
  templateUrl: './subject-messages.component.html',
  styleUrls: ['./subject-messages.component.css']
})
export class SubjectMessagesComponent implements OnInit {

  messages$: Observable<Array<SubjectMessageInterface>>;

  constructor(private subject$: Store<SubjectReducer.State>) { }

  ngOnInit(): void {
    this.messages$ = this.subject$.select(SubjectSelector.getRecipientSubjectMessages);
  }

}
