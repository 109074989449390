import { Action } from '@ngrx/store';

export const STORE_DATA  = '[Tree Expedient Data] Store Data';
export const STORE_SERIES  = '[Tree Expedient Series] Store Series';
export const CLEAR_DATA  = '[Tree Expedient Data] Clear Data';
export const SELECTED_DATA  = '[Tree Expedient Data] Selected Data';

export class StoreTreeExpedients implements Action {
  readonly type = STORE_DATA;

  constructor(public payload ) {}
}

export class StoreTreeSeries implements Action {
  readonly type = STORE_SERIES;

  constructor(public payload ) {}
}

export class ClearDataAction implements Action {
  readonly type = CLEAR_DATA;

  constructor() {}
}

export class SelectedDataAction implements Action {
  readonly type = SELECTED_DATA;

  constructor(public payload) {}
}

export type Actions = StoreTreeExpedients
  | ClearDataAction | SelectedDataAction | StoreTreeSeries;
