import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AddresseeTreeComponent } from "../../new-subject/addressee-tree/addressee-tree.component";
import { NewSubjectService } from "../../_services/new-subject.service";
import { ErrorMessageComponent } from "../../messages/error-message/error-message.component";
import { DynamicFormBuilder } from "../../components/DynamicFormBuilder";
import { FieldConfig } from "../../_models/field.interface";
import { FormBuilder, FormGroup } from "@angular/forms";
import { faArrowCircleRight, faBan, faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { ProcessService } from "../../_services/process.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UploadFileComponent } from "../upload-file/upload-file.component";
import { SubjectService } from "../../_services/subject.service";
import { NotifierComponent } from "../../notifier/notifier.component";
import * as AvanzarSubjectSelector from "../../_store/selectors/avanzar-subject.selector";
import { take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as AvanzarSubjectReducer from "../../_store/reducers/avanzar-subject.reducer";
import * as AvanzarSubjectActions from "../../_store/actions/avanzar-subject.actions";
import { OrganizationalUnitService } from "../../_services/organizational-unit.service";
import { MatHorizontalStepper } from "@angular/material/stepper";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ORGANIZATIONAL_UNIT, USER } from "../../../../projects/editor/src/app/_constants/RecipientTypeConstants";
import {
  WarningConfirmationDialogComponent
} from "../../dialog/warning-confirmation-dialog/warning-confirmation-dialog.component";
import { MatTabGroup } from "@angular/material/tabs";

@Component({
  selector: 'app-avanzar-dialog',
  templateUrl: './avanzar-dialog.component.html',
  styleUrls: ['./avanzar-dialog.component.css']
})
export class AvanzarDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild( ErrorMessageComponent ) errorMessage;
  @ViewChild( AddresseeTreeComponent, {static: true} ) addresseeTreeComponent: AddresseeTreeComponent;
  @ViewChild( 'addresseeRadio', {static: true} ) addresseeRadioCompnenet : MatTabGroup;
  @ViewChild( 'stepper' ) stepper : MatHorizontalStepper;
  @ViewChild( UploadFileComponent ) uploadFiles;
  @ViewChild( NotifierComponent ) notifier;

  disableButton : boolean = false;

  formConfig: FieldConfig[] = [];

  showSpinner = false;
  addresseeTypeRadio: string;

  formGroup: FormGroup;
  submitted: boolean = false;

  titleIcon = faFileSignature;
  faCancel = faBan;
  faNext = faArrowCircleRight;

  companies = [];
  processes = [];
  processName;

  addressees = [
    {
      name: "Unidad Organizacional",
      code: ORGANIZATIONAL_UNIT,
      checked: true
    },
    {
      name: "Responsable",
      code: USER,
      checked: false
    }
  ];

  users = [];
  organizationalUnit = [];

  addresseeGroupButtonStatus = false;

  constructor(
    private processService: ProcessService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: DynamicFormBuilder,
    private newSubjectService: NewSubjectService,
    private subjectService: SubjectService,
    public dialogRef: MatDialogRef<AvanzarDialogComponent>,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private store$: Store<AvanzarSubjectReducer.State>,
    private organizationalUnitService: OrganizationalUnitService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.getAddresseeData();
  }

  getAddresseeData(){
    this.organizationalUnitService.getOrganizationalUnitAndUsers(this.data.organizationalUnitId).subscribe(
      response => {
        if(response.status){

          this.store$.dispatch(new AvanzarSubjectActions.StoreOrganizationalUnits(response.organizationalUnit));

          this.store$.dispatch(new AvanzarSubjectActions.StoreUsers(response.users));

          this.changeAdressee(this.addressees[0].name); // this.changeAdressee(this.addressees[0].code);
        } else {
          this.errorMessage.setErrorMessage(response);
        }
      },
      error => {
        this.errorMessage.setErrorMessage(error);
      }
    );
  }

  changeAdressee(addressType) {
    // this.addresseeRadioCompnenet.value = addressType;

    this.addresseeTypeRadio = addressType;

    this.addresseeGroupButtonStatus = true;

    if (addressType === 'Unidad Organizacional') { // if (addressType === 'organizationalUnit')
      this.addresseeTypeRadio = ORGANIZATIONAL_UNIT;

      this.store$.select(AvanzarSubjectSelector.getOrganizationlUnits)
        .pipe(take(1))
        .subscribe(response => {

          if (response === null) {
            return;
          }

          const organizationalUnits =  Object.assign([], response);

          this.addresseeTreeComponent.buildTree('organizationalUnit', organizationalUnits); // this.addresseeTreeComponent.buildTree('addressType', organizationalUnits);
          this.addresseeTreeComponent.filterNodes('');
          this.addresseeGroupButtonStatus = false;
        });
    }

    if (addressType === 'Responsable' ) { // if (addressType === 'user')
      this.addresseeTypeRadio = USER;

      this.store$.select(AvanzarSubjectSelector.getUsers)
        .pipe(take(1))
        .subscribe(response => {
          const users =  Object.assign([], response);

          this.addresseeTreeComponent.buildTree('user', users); // this.addresseeTreeComponent.buildTree('addressType', users);
          this.addresseeTreeComponent.filterNodes('');
          this.addresseeGroupButtonStatus = false;
        });
    }

  }

  getFilesAttached(){
    return this.uploadFiles.getFilesForm();
  }

  submit(){
    this.submitted = true;

    this.buttonLoading();

    let selectedNodes = this.addresseeTreeComponent.getAddresseeSelected();

    let attachments = this.getFilesAttached();

    attachments.append('recipients', JSON.stringify(selectedNodes.recipients));
    attachments.append('tasks', JSON.stringify(selectedNodes.tasks));
    attachments.append('recipientType', this.addresseeTypeRadio);

    if (!(this.data.subjectRequest.id > 0) || !(this.data.organizationalUnitId > 0)) {
      return;
    }

    this.subjectService.avanzar(this.data.subjectRequest.id, this.data.organizationalUnitId, attachments).subscribe(
      response => {

        this.enableButton();

        if(response['status']){
          this.dialogRef.close({

          });
          // this.showDetailSubjectSent(response['detail']);
        }else{
          this.errorMessage.setErrorMessage(response);
        }
      },
      error => {
        console.error(error);
        this.enableButton();
        this.errorMessage.setErrorMessage(error);
      }
    );

  }

  /**
   * show subject folio
   * @param detail
   */
  showDetailSubjectSent(detail){
    this.notifier.show({
      type: 'success',
      message: 'Asunto avanzado'
    });
    // let dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = false;
    // dialogConfig.autoFocus = true;
    // dialogConfig.width = '500px';
    // dialogConfig.height = '400px';
    // dialogConfig.data = detail;
    //
    // const dialogClosed = this.dialogClosed.open(SubjectSentDialogComponent, dialogConfig);
    //
    // dialogClosed.afterClosed().subscribe(result => {
    //
    // });
  }

  registerTabClick(): void {
    // Get the handler reference
    const handleTabClick = this.addresseeRadioCompnenet._handleClick;

    this.addresseeRadioCompnenet._handleClick = (tab, header, index) => {
      // const tabContent = this.getTabContentElement(this.addresseeRadio.selectedIndex);
      // console.log(tabContent);

      // Get tab content reference for the current Tab -> currentTabIndex
      // since index here is already the "new" tab index

      // If you want to prevent the user to navigate to the new tab,
      // you can avoid invoking the 'apply' method below
      if ( this.addresseeRadioCompnenet.selectedIndex != index ) {

        const recipienSelected = this.addresseeTreeComponent.hasSomeRecipientSelected();
        const AdressSelected = this.addresseeTreeComponent.hasAddresseeSelected();

        if (recipienSelected || AdressSelected) {
          // this.confirmChangeTab(this.recipientOptions[index].name);

          let dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.data = {title: 'Advertencia', textContent: 'Si Continua se perderá la selección actual y sus tareas'};
          dialogConfig.maxWidth = '300px';
          dialogConfig.panelClass = 'dialog-confirmation-class';

          const dialog = this.dialog.open(WarningConfirmationDialogComponent, dialogConfig);

          dialog.afterClosed().subscribe(respuesta => {

            if (respuesta.confirmation == true) {
              handleTabClick.apply(this.addresseeRadioCompnenet, [tab, header, index]);
              this.changeAdressee(this.addressees[index].name);
            }
          });

        } else {
          handleTabClick.apply(this.addresseeRadioCompnenet, [tab, header, index]);
          this.changeAdressee(this.addressees[index].name);
        }


        // We update the currentIndex, as we need it again when
        // another tab is clicked
        // this.currentTabIndex = index;
      }

    };
  }

  buttonLoading(){
    this.showSpinner = true;
    this.disableButton = true;
  }

  enableButton(){
    this.showSpinner = false;
    this.disableButton = false;
  }

  ngAfterViewInit() {
    this.registerTabClick();
  }

  ngOnDestroy(): void {
    this.store$.dispatch(new AvanzarSubjectActions.ClearAll());
  }

}
