import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthClass } from "../../auth/Auth.class";
import { LocalstorageService } from "../../_services/Localstorage.service";
import { Router } from "@angular/router";
import { WarningConfirmationDialogComponent } from "../../dialog/warning-confirmation-dialog/warning-confirmation-dialog.component";
import { UsersService } from "../../_services/users.service";
import { SystemSettingsService } from "../../_services/system-settings.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import * as ProcessSelector from "../../_store/selectors/process.selector";
import { takeUntil} from "rxjs/operators";
import { Store} from "@ngrx/store";
import * as ProcessReducer from "../../_store/reducers/process.reducer";
import { Subject} from "rxjs";
import { OrganizationalUnitModel } from "../../_models/OrganizationalUnit.model";

@Component({
  selector: 'app-panel-navbar',
  templateUrl: './panel-navbar.component.html',
  styleUrls: ['./panel-navbar.component.css']
})
export class PanelNavbarComponent implements OnInit,AfterViewInit {
  @Input() sideBar;

  faSignOut = faSignOutAlt;
  faBars = faBars;
  destroy: Subject<void> = new Subject();
  navbarOpen = false;
  systemSettings: Object = {};
  processes;
  organizationalUnit: Array<OrganizationalUnitModel> = [];
  constructor(
      private auth: AuthClass,
      private localStorage: LocalstorageService,
      private router: Router,
      private dialog: MatDialog,
      private systemSettingsService: SystemSettingsService,
      public userService: UsersService,
      private process$: Store<ProcessReducer.State>
  ) {

  }

  toggleNavbar() {
      this.navbarOpen = !this.navbarOpen;
  }

  toggleSidebar(){
      this.sideBar.toggle();
  }

  ngOnInit() {
      this.systemSettings = this.systemSettingsService.getLoginSettings();

      this.process$.select(ProcessSelector.getProcesses)
          .pipe(takeUntil(this.destroy))
          .subscribe(processes => {
              if (processes !== null) {
                  this.processes = processes;
                  this.organizationalUnit = this.userService.userData.organizationalUnits as Array<OrganizationalUnitModel>;
              }
          });
  }

    ngAfterViewInit(){

    }

    logout(){

    }
}
