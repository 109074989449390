import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

@Directive({
  selector: '[appPrivateIcon]'
})
export class PrivateIconDirective {
  @Input() titularOnly: boolean;
  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(){
    if(!this.titularOnly){
      this.el.nativeElement.remove();
    }else{
      //this.el.nativeElement.style.visibility = true;
    }
  }
}
