export const MAILBOX_TYPE_INBOX         = 'inbox';
export const MAILBOX_TYPE_INBOXOUT      = 'inboxout';
export const MAILBOX_TYPE_HISTORICAL    = 'historico';
export const MAILBOX_STATUS_NUEVO       = 'nuevo';
export const MAILBOX_STATUS_AVANZADO    = 'avanzado';
export const MAILBOX_STATUS_RESUELTO    = 'resuelto';
export const MAILBOX_STATUS_REACTIVADO  = 'reactivado';
export const MAILBOX_STATUS_HISTORICAL  = 'historico';
export const MAILBOX_STATUS_ARCHIVAL  = 'archivado';
export const MAILBOX_STATUS_REJECTED    = 'rejected';
export const MAILBOX_STATUS_CANCELED = 'cancelado';
export const GLOBAL_REPORT = 'global';
