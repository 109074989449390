import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as MAILBOX_TYPE from '../_constants/MailboxConstants';
import * as SUBJECT_TYPE from '../_constants/SubjectTypeConstants';

@Injectable({
  providedIn: 'root'
})
export class MailboxService {

    constructor(private http: HttpClient) { }

    url = environment.apiUrl + '/api/mailbox';

    /**
     *
     * @returns {Observable<any>}
     * @param internoExterno
     * @param mailboxType
     * @param mailboxStatus
     * @param organizationalUnitId
     * @param params
     */
    getMailbox(internoExterno, mailboxType, mailboxStatus = '', organizationalUnitId, params = null): Observable<any> {
        if (internoExterno === SUBJECT_TYPE.INTERNAL_SUBJECT_CODE) {
            if (mailboxType === MAILBOX_TYPE.MAILBOX_TYPE_HISTORICAL) {
                return this.historico(organizationalUnitId, params);
            }
            if (mailboxType === MAILBOX_TYPE.MAILBOX_STATUS_ARCHIVAL) {
                return this.getSubjectArchival(organizationalUnitId, params, SUBJECT_TYPE.INTERNAL_SUBJECT_CODE);
            } else {
                return this.getInternalMailbox(mailboxType, mailboxStatus, organizationalUnitId, params);
            }
        } else {
            if (mailboxType === MAILBOX_TYPE.MAILBOX_TYPE_HISTORICAL) {
                return this.externalHistorico(organizationalUnitId, params);
            }
            if (mailboxType === MAILBOX_TYPE.MAILBOX_STATUS_ARCHIVAL) {
                return this.getSubjectArchival(organizationalUnitId, params, SUBJECT_TYPE.EXTERNAL_SUBJECT_CODE);
            } else {
                return this.getExternalMailbox(mailboxType, mailboxStatus, organizationalUnitId, params);
            }
        }
    }

    getSubjectArchival(organizationalUnitId, params = null, status): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };

        const url = this.url + '/archival/' + organizationalUnitId + '/' + status;
        if (params === null || params === undefined) {
            return this.http.get(url, httpOptions);
        } else {
            httpOptions['params'] = params;

            return this.http.get(url, httpOptions);
        }
    }

    getInternalMailbox(mailboxType, mailboxStatus = '', organizationalUnitId, params = null): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };

        const url = this.url + '/' + mailboxType + '/' + mailboxStatus + '/' + organizationalUnitId;

        if (params === null || params === undefined) {
            return this.http.get(url, httpOptions);
        } else {
            httpOptions['params'] = params;

            return this.http.get(url, httpOptions);
        }
    }

    /**
     *
     * @param mailboxType
     * @param mailboxStatus
     * @param organizationalUnitId
     * @param params
     */
    getExternalMailbox(mailboxType, mailboxStatus, organizationalUnitId, params = null): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
        };

        const url = (mailboxType !== null) ? this.url + '/external/' + mailboxType + '/' + mailboxStatus + '/' + organizationalUnitId
            : this.url  + '/external/' + mailboxStatus + '/' + organizationalUnitId;

        if (params === null || params === undefined) {
            return this.http.get(url, httpOptions);
        } else {
            httpOptions['params'] = params;

            return this.http.get(url, httpOptions);
        }
    }


    historico(organizationalUnitId, params = null): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
        };

        const url = this.url + '/historico/' + organizationalUnitId;
        if (params === null || params === undefined) {
            return this.http.get(url, httpOptions);
        } else {
            httpOptions['params'] = params;

            return this.http.get(url, httpOptions);
        }
    }

    externalHistorico(organizationalUnitId, params): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
        };
        const url = this.url + '/external/historico/' + organizationalUnitId;

        if (params === null || params === undefined) {
            return this.http.get(url, httpOptions);
        } else {
            httpOptions['params'] = params;

            return this.http.get(url, httpOptions);
        }
    }

    unreadMailbox() {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
        };

        return this.http.get(this.url + '/unread', httpOptions);
    }
}
