import { faFilePdf, faFileImage, faFileWord, faFile, faFileExcel } from '@fortawesome/free-solid-svg-icons';

export class File {
  /**
   *
   * @param fileName
   */
    static extension(fileName: string): string {
        const ext = fileName.split('.').pop();

        return ext.toLowerCase();

    }

  /**
   *
   * @param filename
   */
  static icon(filename: string) {
        const ext = this.extension(filename);

        switch (ext) {
            case 'pdf' :
                return faFilePdf;
            case 'jpge' :
                return faFileImage;
            case 'jpg' :
                return faFileImage;
            case 'png' :
                return faFileImage;
            case 'doc' :
                return faFileWord;
            case 'docx' :
                return faFileWord;
            case 'xls' :
                return faFileExcel;
            case 'xlsx' :
                return faFileExcel;
        }

        return faFile;

    }
}
