import { Action } from '@ngrx/store';

export const SET_IS_LOADING = '[General] Set Is Loading';
export const ADD_BREADCRUMD = '[General] Add Breadcrumb';
export const SPLIT_BREADCRUMD = '[General] Split Breadcrumb';
export const CLEAR_BREADCRUMD = '[General] Clear Breadcrumb';
export const CLEAR_ALL_DATA = '[General] Clear Data';

export class SetIsLoadingArch implements Action {
    readonly type = SET_IS_LOADING;

    constructor(public isLoading: boolean) {}
}

export class AddBreadcrumb implements Action {
    readonly type = ADD_BREADCRUMD;

    constructor(public payload) {}
}

export class SplitBreadcrumb implements Action {
    readonly type = SPLIT_BREADCRUMD;

    constructor(public payload) {}
}

export class ClearBreadcrumb implements Action {
    readonly type = CLEAR_BREADCRUMD;

    constructor() {}
}

export class ClearDataAction implements Action {
    readonly type = CLEAR_ALL_DATA;

    constructor() {}
}

export type Actions = SetIsLoadingArch
        | AddBreadcrumb
        | SplitBreadcrumb
        | ClearBreadcrumb
        | ClearDataAction;
