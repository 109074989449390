import { Action } from '@ngrx/store';
import { UserModel } from "../../../../../../src/app/_models/user.model";
import { UserRoleOrganizationalUnitModel } from "../../_models/UserRoleOrganizationalUnit.model";

export const STORE_RECIPIENTS  = '[Recipients Tree] Store Recipients'
export const CLEAR_ALL = '[Recipients Tree] Clear All';

export class StoreRecipients implements Action {
    readonly type = STORE_RECIPIENTS;

    constructor(public users: Array<UserRoleOrganizationalUnitModel>, public organizationalUnit: any) {}
}

export class ClearAll implements Action {
    readonly type = CLEAR_ALL;

    constructor() {}
}

export type Actions = StoreRecipients
  | ClearAll;
