import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import * as NewSubjectReducer from '../reducers/new-subject.reducer';
import * as AvanzarSubjectReducer from '../reducers/avanzar-subject.reducer';
import * as MailboxReducer from '../reducers/mailbox.reducer';
import * as ProcessReducer from './process.reducer';
import * as SubjectReducer from './subject.reducer';
import * as NotificationReducer from './notification.reducer';
import * as SystemSettings from './system-settings.reducer';
import * as Archival from './archival.reducer';
import * as Graph from './graph.reducer';

export interface State {
    newSubject: NewSubjectReducer.State; /** Sirve para la creación un asunto */
    avanzarSubject: AvanzarSubjectReducer.State;  /** Aquí se guarda la información relacionada para avanzar un asunto */
    mailbox: MailboxReducer.State; /** Aquí se guarda toda la información relacionada a los buzones */
    process: ProcessReducer.State;  /** Aquí guarda la info relacionada a los procesos */
    subject: SubjectReducer.State;  /** Guarda la información relacionada a los asuntos (Al consultar un asunto )*/
    notification: NotificationReducer.State;
    systemSettings: SystemSettings.State;
    archival: Archival.State;
    graph: Graph.State;
}

export const reducers: ActionReducerMap<any> = {
    newSubject: NewSubjectReducer.reducer,
    avanzarSubject : AvanzarSubjectReducer.reducer,
    mailbox: MailboxReducer.reducer,
    process: ProcessReducer.reducer,
    subject: SubjectReducer.reducer,
    notification: NotificationReducer.reducer,
    systemSettings: SystemSettings.reducer,
    archival: Archival.reducer,
    graph: Graph.reducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return function(state: State, action: any): State {
        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger, storeFreeze] : [];
