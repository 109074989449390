import * as Actions from './../actions/notification.action'
import { NotificationInterface } from "../../_models/notification.interface";

export interface State {
    notifications: Array<NotificationInterface>
}

const initialState: State = {
  notifications: []
};

export function reducer(state = initialState, action: Actions.Actions): any {

  switch (action.type) {
    case Actions.STORE_NOTIFICATIONS:
      return {...state, notifications: action.notifications};
    case Actions.MARK_AS_OPEN:
      const notificationsMarkAsOpen = state.notifications.map(notification => {
        if (notification.id === action.notficationId) {
          return { ...notification, open: true };
        }
        return notification;
      });
      return {...state, notifications: notificationsMarkAsOpen};
    case Actions.REMOVE_NOTIFICATION:
      const notificationsAfterRemove = state.notifications.filter(notification => notification.id !== action.notificationId);
      return {...state, notifications: notificationsAfterRemove}
    case Actions.MARK_ALL_NOTIFICATIONS_AS_OPEN:
      const notificationsMarkedAsOpen = state.notifications.map(notification => {
        return {...notification, open: true};
      });
      return {...state, notifications: notificationsMarkedAsOpen};
    default:
      return {...state};
  }
}


