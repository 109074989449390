import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SubjectPreviewInitializatorComponent } from './subject-preview-initializator.component';
import { InboxoutPreviewDialogComponent } from './inboxout-preview/inboxout-preview-dialog.component';
import { SharedModule } from '../shared.module';
import { DynamicformsModule } from '../dynamicforms.module';
import { SubjectDocumentsComponent } from '../share-components/subject-documents/subject-documents.component';
import { SubjectAnswersComponent } from '../share-components/subject-answer/subject-answers.component';
import { SubjectHeaderComponent } from './subject-header/subject-header.component';
import { SubjectTasksComponent } from '../share-components/subject-tasks/subject-tasks.component';
import { SubjectReviewedComponent } from './subject-reviewed/subject-reviewed.component';
import { DocumentViewerComponent } from '../document-viewer/document-viewer.component';
import { InboxPreviewDialogComponent } from './inbox-preview/inbox-preview-dialog.component';
import { ExternalInboxoutPreviewComponent } from './external-inboxout-preview/external-inboxout-preview.component';
import { ExternalPreviewDialogComponent } from './external-preview-dialog/external-preview-dialog.component';
import { InternalHistoricoPreviewDialogComponent } from './internal-historico-preview/internal-historico-preview-dialog.component';
import { ExternalFinishedPreviewComponent } from './external-finished-preview/external-finished-preview.component';
import { AnswerDocumentsComponent} from '../share-components/answer-documents/answer-documents.component';
import { SubjectMessagesComponent } from './subject-messages/subject-messages.component';
import { SpanTitleDirective } from '../_directives/span-title.directive';
import { PrivateIconDirective } from '../_directives/private-icon.directive';
import { DivPrivateSubjectDirective } from '../_directives/div-private-subject.directive';
import { ScrollPaddingDirective } from '../_directives/scroll-padding.directive';
import { SendCopyDialogComponent } from './send-copy-dialog/send-copy-dialog.component';
import { TreeModule } from 'angular-tree-component';
import { SubjectButtonsActionComponent } from './subject-buttons-action/subject-buttons-action.component';

export const ROUTES: Routes = [
    // {
    //   path: '',
    //   component: SubjectPreviewInitializatorComponent
    // },
    // {
    //   path: ':subjectRequestId',
    //   component: SubjectPreviewInitializatorComponent,
    // },

  ];

@NgModule({
    exports: [
        CommonModule,
        SubjectDocumentsComponent,
        SubjectHeaderComponent,
        SubjectAnswersComponent,
        SubjectMessagesComponent,
        SubjectTasksComponent,
        DocumentViewerComponent,
        DivPrivateSubjectDirective,
        SpanTitleDirective,
        PrivateIconDirective,
        SubjectReviewedComponent,
        SubjectButtonsActionComponent,
    ],
  declarations: [
      SubjectPreviewInitializatorComponent,
      InboxoutPreviewDialogComponent,
      SubjectDocumentsComponent,
      SubjectAnswersComponent,
      SubjectHeaderComponent,
      SubjectTasksComponent,
      SubjectReviewedComponent,
      DocumentViewerComponent,
      InboxPreviewDialogComponent,
      ExternalInboxoutPreviewComponent,
      ExternalPreviewDialogComponent,
      InternalHistoricoPreviewDialogComponent,
      ExternalFinishedPreviewComponent,
      AnswerDocumentsComponent,
      SubjectMessagesComponent,
      SpanTitleDirective,
      PrivateIconDirective,
      DivPrivateSubjectDirective,
      ScrollPaddingDirective,
      SendCopyDialogComponent,
      SubjectButtonsActionComponent,
      // SubjectPreviewDirective,
  ],
  imports: [
    RouterModule.forChild(ROUTES),
    SharedModule,
    DynamicformsModule,
    TreeModule,
  ],
  entryComponents: [
    InboxoutPreviewDialogComponent,
    InboxPreviewDialogComponent,
    ExternalInboxoutPreviewComponent,
    ExternalPreviewDialogComponent,
    InternalHistoricoPreviewDialogComponent,
    AnswerDocumentsComponent,
    SendCopyDialogComponent
  ],
  providers: [

  ]
})
export class SubjectModule { }
