import { Directive, ElementRef, Input, SimpleChange } from '@angular/core';

@Directive({
  selector: '[appSpanTitle]'
})
export class SpanTitleDirective {
  @Input() titleLength: number;
  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes: {[property: string]: SimpleChange}){
    let change = changes['titleLength'];
    let classList = this.el.nativeElement.classList;

    if(change !== undefined && !classList.contains('toolbar-spacer')){
      if(change.currentValue > 80){
        classList.add('toolbar-spacer');}
    }
  }

}
