import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UploadFileComponent } from '../../share-components/upload-file/upload-file.component';
import { ErrorMessageComponent } from '../../messages/error-message/error-message.component';
import { NotifierComponent } from '../../notifier/notifier.component';
import { UserModel } from '../../_models/user.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faIdCard } from '@fortawesome/free-solid-svg-icons/faIdCard';
import { UsersService } from '../../_services/users.service';
import { SignatureService } from '../../_services/signature.service';
import { SIGNATURE_METHODS } from '../../_constants/CatSignatureMethodsConstants';

@Component({
  selector: 'app-sat-signature',
  templateUrl: './sat-signature.component.html',
  styleUrls: ['./sat-signature.component.css']
})
export class SatSignatureComponent implements OnInit, AfterViewInit {
  @ViewChild( 'uploadFileComponent', { static: true }) uploadFile: UploadFileComponent;
  @ViewChild( 'errorMessageComponent' , { static: true }) errorMessage: ErrorMessageComponent;
  @ViewChild( 'notifierComponent', { static: true } ) notifierComponent: NotifierComponent;
  @Input('user') user: UserModel;

  satFormGroup: FormGroup;
  acceptString: string = '.cert, .cer, .crt';
  submitted = false;
  isLoading: boolean = false;
  idCardIcon = faIdCard;

  constructor(
    private userService: UsersService,
    private fb: FormBuilder,
    private signatureService: SignatureService
  ) { }

  ngOnInit(): void {
    this.satFormGroup = this.fb.group({
      sat: this.fb.group({
        certFile: [{ disabled: true }, []],
        //password: ['', [Validators.required]]
      })
    });
    this.satForm['certFile'].disable();
  }

  uploadFiles() {
    if ( !(this.uploadFile.getDocumentsList().length > 0) ) {
      return;
    }

    const files = this.uploadFile.getFilesForm();

    files.append('data', JSON.stringify(this.satFormGroup.value));

    this.isLoading = true;

    this.signatureService.uploadSatFiles(files).subscribe(
      response => {
        this.isLoading = false;

        if (response['status']) {
          this.notifierComponent.show({
            type: 'success',
            message: 'Datos de firma actualizados con éxito.'
          });
          // this.user.firelName = response['firel'].firelName;
          this.satForm['certFile'].setValue(response['sat']['cert']);
        } else {
          this.errorMessage.setErrorMessage(response);
        }
      },
      error => {
        console.error(error);
        this.isLoading = false;
        this.errorMessage.setErrorMessage(error);
      }
    );
  }

  public setUserData() {
    if ( !(this.user.signatureMethods.length > 0) ) {
      return;
    }

    const satMethod = this.user.signatureMethods.find(method => method.catSignatureMethod.code == SIGNATURE_METHODS.SAT);

    if ( satMethod ) {
      this.satForm['certFile'].setValue(satMethod.cert);
      this.satForm['keyFile'].setValue(satMethod.private_key);
    }
  }

  get satForm() {
    return this.satFormGroup.controls['sat']['controls'];
  }

  ngAfterViewInit() {
    this.setUserData();
  }

}
