import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DynamicFormBuilder } from "../../components/DynamicFormBuilder";
import { ActivatedRoute, Router } from "@angular/router";
import { ProcessService } from "../../_services/process.service";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { SubjectService } from "../../_services/subject.service";
import { UsersService } from "../../_services/users.service";
import { Store } from "@ngrx/store";
import * as MailboxReducer from "../../_store/reducers/mailbox.reducer";
import * as MailboxSelector from "../../_store/selectors/mailbox.selector";
import * as SubjectReducer from "../../_store/reducers/subject.reducer";
import { filter, takeUntil} from "rxjs/operators";
import { AddresseeModel } from "../../_models/addresseeModel";
import { Observable, Subject } from 'rxjs';
import { SubjectRequestModel} from "../../_models/SubjectRequest.model";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SubjectDialogDataService } from "../../_services/subject-dialog-data.service";
import { Preview } from "../../mailboxes/Preview";
import { SystemSettingsService } from "../../_services/system-settings.service";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { GeneratepdfService } from "../../_services/generatepdf.service"
import * as NewOfficeReducer from "../../../../projects/editor/src/app/_store/reducers/new-document.reducer";
import { SubjectRequestService } from '../../_services/subject-request.service';
import { saveAs } from '@progress/kendo-file-saver';
import * as SubjectActions from '../../_store/actions/subject.actions';
import { getIschangeTask, getIsLoading, getIsLoadingPDF } from "../../_store/selectors/subject.selector";
import { MAILBOX_INBOXOUT_CODE } from '../../../../projects/editor/src/app/editor/panel/MailboxesNode';
import * as SubjectSelector from '../../_store/selectors/subject.selector';

@Component({
  selector: 'app-inboxout-preview-dialog',
  templateUrl: './inboxout-preview-dialog.component.html',
  styleUrls: ['./inboxout-preview-dialog.component.css']
})
export class InboxoutPreviewDialogComponent extends Preview implements OnInit, OnDestroy {
    allowCancelSubject: boolean = false;

    destroy: Subject<void> = new Subject();
    mailboxType = MAILBOX_INBOXOUT_CODE;
    faUserSecret = faUserSecret;
    icoPdf = faFilePdf;
    disabled: boolean;

    subjectRequest: SubjectRequestModel;
    organizationalUnitId: number = null;

    fullMetadatos: any;
    fullAnswer: any;
    systemSettings: Object = {};
    showLoading$: Observable<boolean>;
    TaskUser = { onTask : false, onUserTask : false,total: 0, colorbk: 'red' };

    constructor(
        private _GeneratepdfService: GeneratepdfService,
        public systemSettingsService: SystemSettingsService,
        public subjectRequestService: SubjectRequestService,
        private subjectService: SubjectService,
        private processService: ProcessService,
        public activatedRoute: ActivatedRoute,
        private formBuilder: DynamicFormBuilder,
        public dialogRef: MatDialogRef<InboxoutPreviewDialogComponent>,
        public userService: UsersService,
        public dialog: MatDialog,
        private store$: Store<MailboxReducer.State>,
        public subject$: Store<SubjectReducer.State>,
        private subjectDialogDataService: SubjectDialogDataService,
        public router: Router,
        public newOffice$: Store<NewOfficeReducer.State>,
        @Inject(MAT_DIALOG_DATA) public subject: AddresseeModel
    ) {
        super(systemSettingsService, router, activatedRoute, dialogRef, newOffice$, subject$, dialog, userService, subjectRequestService);
    }

    ngOnInit() {
        this.systemSettings = this.systemSettingsService.getLoginSettings();
        this.buildSubjectForm();
        this.store$.select(MailboxSelector.getCurrentOrganizationalUnitId)
            .pipe(
                takeUntil(this.destroy),
                filter(data => data !== null)
            )
            .subscribe(
                response => {
                    this.organizationalUnitId = response;
                }
            );

        this.showLoading$ = this.subject$.select(getIsLoadingPDF);
        this.isLoading$ = this.subject$.select(getIsLoading);

        this.subject$.select(getIschangeTask).pipe(
          takeUntil(this.destroy),
          filter(data => data !== null)
          ).subscribe(subject => {
            if (subject.ischange === true) {
              this.TaskUser = this.TaskUserOpened(subject);
            }
          });

          this.subject$.dispatch(new SubjectActions.SetIsChangeTask(true));

        this.subject$.select(SubjectSelector.getIsLoadingFlowChart).pipe(takeUntil(this.destroy),
                filter(data => data !== null)).subscribe(loading => {
            if (loading) {
                this.scrollToElement();
            }
        });
    }

  private TaskUserOpened(subject): any {
      let color = 'red';
      const TaskOpened: any[] = subject.tasks.filter(task => task.completed == false);
      const UserTask: any[] = subject.tasks.filter(task =>
        {
          if (task.userAssigned === null) {
            return false;
          }
          return task.userAssigned.id === this.userService.userData.id && task.completed == false
        });

      const UserTaskAnswer: any[] = subject.tasks.filter(task =>
          {
            if (task.answers === null || task.answers.length === 0) {
              return false;
            }
            return true;
          });

      if (UserTaskAnswer.length > 0) {
        color = 'orangered';
      }

    this.subject$.dispatch(new SubjectActions.SetIsChangeTask(false));
      return {
        onTask : TaskOpened.length > 0,
        onUserTask : UserTask.length > 0,
        total: UserTask.length,
        colorbk: color
      };
    }

  buildSubjectForm(){
        let addresseeId = this.subjectDialogDataService.recipientId;

        if (!(addresseeId > 0)) {
            alert('el recipientId no es válido: ' + addresseeId);
            return 0;
        }

        this.loading = true;

        this.subjectService.getCurrentSubject()
          .pipe(
            takeUntil(this.destroy),
            filter(data => data !== null && data !== undefined)
          )
          .subscribe(subjectRequest => {
            this.loading = false;
            this.subjectRequest = subjectRequest;
            this.mapFieldsAndBuildForm();
            },
            error => {
                this.loading = false;
                console.error(error);
            }
        );
    }

  mapFieldsAndBuildForm() {
        let metadata = [];
        let metadataValue = this.subjectRequest.metadataValue;

        this.subjectRequest.process.metadata.forEach(function(field, index){
            let metadataFieldValue = metadataValue.find(metadataValueField => metadataValueField.metadata_id === field.id);

            if(metadataFieldValue === undefined || metadataFieldValue === null)
                return;

            field = {...field, value:metadataFieldValue.value};
            metadata.push(field);
        });

        this.fullMetadatos = metadata;
        this.dynamicForm.createControls(metadata);
        this.dynamicForm.disableForm();
    }

  openSubjectAnswerDialog() {
  }

  finalizarAsunto() {
  }

  marcarResuelto(recipient) {
  }

  avanzar() {
  }

  canSeeSubjectReviewed(): boolean{
        // return this.userService.isTitular(this.organizationalUnitId) === true ||
        //     this.userService.isAsistente(this.organizationalUnitId) === true;
        return true;
    }

  showTasksTab(): boolean {
        if (!this.subjectRequest || this.subjectRequest.tasks == null || !this.organizationalUnitId) {
            return false;
        }

        if(this.subjectRequest.tasks.filter(task => task.sender_user_id === this.userService.userData.id).length > 0) {
            return true;
        } else if (this.userService.isTitular(this.organizationalUnitId) || this.userService.isAsistente(this.organizationalUnitId)) {
            return this.subjectRequest.tasks.length > 0;
        } else {
            return this.subjectRequest.tasks.filter(task => task.userAssigned !== null && task.userAssigned.id === this.userService.userData.id).length > 0;
        }
    }

  generatePdf(){
      this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(true));
      const params = {
        title: [this.subjectRequest.title],
        subjectRequest:  this.subjectRequest,
        fullMetadata:  this.fullMetadatos,
      };
      const nameFile = 'Flow - ' + this.subjectRequest.folio;
      this._GeneratepdfService.subjectReport(params).subscribe(response => {
        saveAs(response, nameFile);
        this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(false));
      }, error => {
        this.errorMessage.setErrorMessage(error);
        this.subject$.dispatch(new SubjectActions.SetIsLoadingPDF(true));
      });
    }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
