import { Pipe, PipeTransform } from '@angular/core';
/*
 * Usage:
 *   value | substract : n
*/
@Pipe({name: 'substract'})
export class SubstractPipe implements PipeTransform {
  transform(value: string, lenght = 1): string {
  let resp = value.substr(0,lenght);
  return resp;
  }
}
