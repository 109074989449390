import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router
  ) { }

  /**
   *
   * @param organizationalUnitId
   * @param participantId
   */
  public openOffice(organizationalUnitId: number, participantId: number) {
    const url = '/editor/office/' + organizationalUnitId + '/' + participantId;
    return this.router.navigate([url]);
  }
}
