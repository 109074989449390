import * as generalAction from '../actions/General.actions';

export interface State {
    isLoading: boolean;
    breadcrumb: any[];
}

const initialState: State = {
    isLoading: false,
    breadcrumb: [],
};

export function reducer(state = initialState, action: generalAction.Actions): any {

    switch (action.type) {
        case generalAction.SET_IS_LOADING:
            return { ...state, isLoading: action.isLoading };

        case generalAction.ADD_BREADCRUMD:
            return { ...state, breadcrumb: state.breadcrumb.concat(action.payload) };

        case generalAction.SPLIT_BREADCRUMD:
            return { ...state, breadcrumb: state.breadcrumb.filter(value => value !== action.payload) };

        case generalAction.CLEAR_BREADCRUMD:
            return { ...state, breadcrumb: [] };

        case generalAction.CLEAR_ALL_DATA:
            return { ...state, ...initialState };

        default:
            return state;
    }
}
