import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import * as SubjectSelector from '../../_store/selectors/subject.selector';
import { SubjectFlowModel } from '../../_models/SubjectRequest.model';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Edge } from '@swimlane/ngx-graph';
import * as SubjectActions from '../../_store/actions/subject.actions';

export const finished = 'Finalizó el asunto';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: [ './graph.component.css' ]
})
export class GraphComponent implements OnInit, OnDestroy {
  @ViewChild('myChart') chartContainer: any;
  destroy$: Subject<void> = new Subject();
  solved = 'Resuelto';
  unsolved = 'No resuelto';
  // div
  divBase = { 'width' : 1120, 'height': 600 };
  divBaseStyle = { 'width' : this.divBase.width + 'px', 'height': this.divBase.height + 'px' };
  // card
  v_wcard = 345;
  v_hcard = 170;
  // count nodes
  rowbox: any;
  maxRowbox = 0;
  maxStep = 0;
  statusNode = [];

  draggingEnabled = false;
  panningEnabled = true;
  zoomEnabled = true;
  autoCenter = true;

  subjectFlow: Array<SubjectFlowModel>;
  public layoutSettings = {
    orientation: 'LR'
  };

  nodes: Array<any> = [];
  links: Array<Edge> = [];

  constructor(
          private store$: Store<SubjectReducer.State>,
          private subject$: Store<SubjectReducer.State>) {
  }

  ngOnInit() {
    // calcula widthScreen para div scroll
    this.SetWidthScreen();
    this.store$.select(SubjectSelector.getSubjectFlow)
    .pipe(
            takeUntil(this.destroy$),
            filter(data => data !== null)
    )
    .subscribe(subjectFlow => {
      this.subjectFlow = subjectFlow.details;
      this.nodes = this.fetchNodes(subjectFlow.details);
      this.links = this.fetchLinks(subjectFlow.details);
      this.subject$.dispatch(new SubjectActions.SetIsLoadingFlowChart(true));
      // console.log(document.getElementById('mychart'));
      // console.log(this.chartContainer);
    });
  }

  // checa cuantos children tiene cada nodo
  checkNodeStyleCanvas(sender, sstep = 1) {
    const senderid = sender + '_' + sstep;
    if (this.rowbox[senderid] === undefined) {
      this.rowbox[senderid] = 1;
    } else {
      this.rowbox[senderid] = this.rowbox[senderid] + 1;
    }
    if (this.rowbox[senderid] > this.maxRowbox) { this.maxRowbox = this.rowbox[senderid]; }
  }

  // tamaño del canvas
  getStyle() {
    let width = this.v_wcard * this.maxStep;
    let height = this.v_hcard * (this.maxRowbox - 1);

    if (width < (this.divBase.width - 10)) { width = this.divBase.width - 10; }
    if (height < this.divBase.height - 10) { height = this.divBase.height - 10 ; }

    return {
      'width': width + 'px',
      'height': height + 'px'
    };
  }

  /**
   *
   * @param subjectFlow
   */
  fetchNodes(subjectFlow: Array<any>): any {
    this.rowbox = [];
    this.maxStep = 0;
    this.maxRowbox = 0;
    this.statusNode = [];
    const nodes = [];
    for (const nodeFlow of subjectFlow) {

      let senderNode = {};
      let senderId;

      if (nodeFlow.sender_user_id !== null) { // User
        senderId = String(nodeFlow.sender_organizationalUnit_id) + '_' + String(nodeFlow.sender_user_id);
      } else { // OrganizationalUnit
        senderId = String(nodeFlow.sender_organizationalUnit_id);
      }

      const existsSender = nodes.find(node => node.id === senderId);
      if (nodeFlow.step > this.maxStep) {
        this.maxStep = nodeFlow.step;
      }
      this.checkNodeStyleCanvas(senderId, nodeFlow.step);
      if (existsSender === undefined || existsSender === null) {
        let senderUserName = 'Cuenta Desactivada';
        if (nodeFlow.senderUser !== null && nodeFlow.senderUser.user !== null) {
          senderUserName = nodeFlow.senderUser.user.full_name_prefix;
        } else if (nodeFlow.sender_user !== null) {
          senderUserName = nodeFlow.sender_user.full_name_prefix;
        }

        let senderRoleName = '(Reubicado)';
        if (nodeFlow.senderUser !== null) {
          if (nodeFlow.senderUser.user !== null && nodeFlow.senderUser.role !== null) {
            senderRoleName = nodeFlow.senderUser.role.name;
          } else {
            senderRoleName = '(Reubicado)';
          }
        } else if (nodeFlow.senderRoleUser !== null) {
          senderRoleName = nodeFlow.senderRoleUser.name;
        }

        senderNode = {
          id: senderId,
          label: ( nodeFlow.senderOrganizationalUnit !== null ? nodeFlow.senderOrganizationalUnit.name : 'Unidad Desactivada' ),
          user: senderUserName,
          step: nodeFlow.step,
          senderOrganizationalUnitId: nodeFlow.sender_organizationalUnit_id,
          recipientType: nodeFlow.recipientType,
          role: senderRoleName,
          state: this.getState(nodeFlow),
          isSolved: nodeFlow.recipient.resuelto,
        };

        nodes.push(senderNode);
      }

      let destinId;

      if (nodeFlow.destin_user_id !== null) { // User
        destinId = String(nodeFlow.destin_organizationalUnit_id) + '_' + String(nodeFlow.destin_user_id);
      } else { // OrganizationalUnit
        destinId = String(nodeFlow.destin_organizationalUnit_id);
      }
      // evalua todos los recipientes del usuario
      /*const ResolvedDestinNode = subjectFlow.filter(node =>
              node.destin_organizationalUnit_id === nodeFlow.destin_organizationalUnit_id &&
              node.destin_user_id === nodeFlow.destin_user_id &&
              node.recipient.resuelto == 1
      );*/

      // evalua informacion destin
      let destinUserName = 'Cuenta Desactivada';
      if (nodeFlow.destinUser !== null && nodeFlow.destinUser.user !== null) {
        destinUserName = nodeFlow.destinUser.user.full_name_prefix;
      } else if (nodeFlow.destin_user !== null) {
        destinUserName = nodeFlow.destin_user.full_name_prefix;
      }

      let roleName = '(Reubicado)';
      if (nodeFlow.destinUser !== null) {
        if (nodeFlow.destinUser.user !== null && nodeFlow.destinUser.role !== null) {
          roleName = nodeFlow.destinUser.role.name;
        } else {
          roleName = '(Reubicado)';
        }
      } else if (nodeFlow.destinRoleUser !== null) {
        roleName = nodeFlow.destinRoleUser.name;
      }

      const destinNode = {
        id: destinId,
        label: nodeFlow.destinOrganizationalUnit !== null ? nodeFlow.destinOrganizationalUnit.name : 'Unidad Desactivada',
        user: destinUserName,
        step: nodeFlow.step,
        destinOrganizationalUnitId: nodeFlow.destin_organizationalUnit_id,
        recipientType: nodeFlow.recipientType,
        role: roleName,
        state: this.getState(nodeFlow), // ResolvedDestinNode.length, nodeFlow),
        isSolved: nodeFlow.recipient.resuelto // ResolvedDestinNode.length > 0 ? 1 : 0,
      };

      const existsDestinNode = nodes.find(node => node.id === destinId);

      if (existsDestinNode === undefined || existsDestinNode === null) {
        nodes.push(destinNode);
      }
    }
    return nodes;
  }

  /*getState(state, recipient = null): string {
    let recipientStore;
    recipient === null ? recipientStore = state : recipientStore = recipient;

    if (recipient == null) {
      if (recipientStore.subjectRequest.finisher_user_id === recipientStore.sender_user.id
              && recipientStore.subjectRequest.finisher_organizational_unit_id === recipientStore.senderOrganizationalUnit.id) {
        return finished;
      }
      if (recipientStore.subjectRequest?.isExternal === 0 && recipientStore.subjectRequest?.finisher_user_id === null) {
        return finished;
      }
      return recipientStore.senderRecipient.resuelto === 1 ? this.solved : this.unsolved;
    }

    if (recipientStore.subjectRequest.finisher_user_id === recipientStore.destin_user.id
            && recipientStore.subjectRequest.finisher_organizational_unit_id === recipientStore.destinOrganizationalUnit.id) {
      return finished;
    }

    return state > 0 ? this.solved : this.unsolved;
  }*/

  getState(nodo): string {
    // asunto finalizado
    let userid = nodo.sender_user_id;
    let Unitid = nodo.sender_organizationalUnit_id;
    if (this.statusNode.find(x => x === nodo.sender_user_id)) {
      userid = nodo.destin_user_id;
      Unitid = nodo.destin_organizationalUnit_id;
    }
    this.statusNode.push(userid);
    if (nodo.subjectRequest.finalizar_datetime !== null) {
      if (nodo.subjectRequest.finisher_user_id === userid
        && nodo.subjectRequest.finisher_organizational_unit_id === Unitid) {
        return finished;
      }
    }

    return (nodo.recipient.resuelto === 1 ? this.solved : this.unsolved);
  }
  /**
   *  enlace del flujo
   * @param subjectFlow
   */
  fetchLinks(subjectFlow): any {
    const links = [];

    for (let cont = 0; cont < subjectFlow.length; cont++) {
      const nodeFlow = subjectFlow[cont];

      let senderId;
      let targetId;

      if (nodeFlow.sender_user_id !== null) { // User
        senderId = String(nodeFlow.sender_organizationalUnit_id) + '_' + String(nodeFlow.sender_user_id);
      } else { // OrganizationalUnit
        senderId = String(nodeFlow.sender_organizationalUnit_id);
      }

      if (nodeFlow.destin_user_id !== null) { // User
        targetId = String(nodeFlow.destin_organizationalUnit_id) + '_' + String(nodeFlow.destin_user_id);
      } else { // OrganizationalUnit
        targetId = String(nodeFlow.destin_organizationalUnit_id);
      }

      const link = {
        'source': senderId,
        'target': targetId,
        'label': String(nodeFlow.step),
        'receptionType': nodeFlow.receptionType
      };

      links.push(link);
    }

    console.log(links);

    return links;
  }

  SetWidthScreen() {
    this.divBase.width = 1120;
    this.divBase.height = 600;
    const xs = 768;
    const sm = 992;
    const md = 1170;
    const lg = 1170;

    const ws = window.screen.width;
    const hs = window.screen.height;
    const wd = document.documentElement.scrollWidth;

    let w = ws;
    if ( wd < ws ) { w = wd; }

    let prwd = 1;
    let prhd = 1;

    if (w < xs) { prwd = (xs * 0.83) / lg; console.log('xs'); } else
    if (w < sm) { prwd = (sm * 0.93) / lg; console.log('sm'); } else
    if (w < md) { prwd = (md * 0.83) / lg; console.log('md'); } // else { console.log('lg'); }

    if (hs < xs) { prhd = (xs * .93) / lg; console.log('xs'); } else
    if (hs < sm) { prhd = (sm * .96) / lg; console.log('sm'); } else
    if (hs < (md * 0.90)) { prhd = (md * .83) / lg; console.log('md'); } // else { console.log('lg'); }

    this.divBase.width = this.divBase.width * prwd;
    this.divBase.height = this.divBase.height * prhd;

    this.divBaseStyle.width = this.divBase.width + 'px';
    this.divBaseStyle.height = this.divBase.height + 'px';

  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
