import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Helper } from "../_helpers/Helper";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root'
})
export class SubjectNotificationService {

  constructor(private http: HttpClient, helper: Helper) {
  }

  url = environment.apiUrl + '/api/notification/subject';

  public get() : Observable<any> {
    return this.http.get(this.url, httpOptions);
  }

  /**
   *
   * @param notification_id
   */
  public markAsOpen(notification_id: number) {
    const url = this.url + '/markasopen/' + notification_id;

    return this.http.post(url, httpOptions);
  }

  /**
   *
   */
  public markAllAsOpen() {
    const url = this.url + '/markallasopen';

    return this.http.post(url, httpOptions);
  }

  /**
   *
   */
  public removeAll() {
    const url = this.url + '/removeall';

    return this.http.delete(url, httpOptions);
  }

  /**
   *
   * @param id
   */
  public remove(id) {
    const url = this.url + '/' + id;

    return this.http.delete(url, httpOptions);
  }
}
