export const SYSTEM_LOGO = 'system_logo';
export const SYSTEM_BACKGROUND = 'system_background';
export const ARCHIVAL_ENABLED = 'archival_enabled';
export const ARCHIVAL_HOST = 'archival_host';
export const COLOR_ANSWER = 'color_answer';
export const COLOR_RESOLVED = 'color_resolved';
export const COLOR_SOLVED = 'color_solved';
export const EDITOR_ACTIVATED = 'editor_activated';
export const SETTING_PDF = 'setting_pdf';
export const BUTTON_REGISTER = 'button_register';
export const LINK_LOGO_DEFAULT =  'system_settings/system_logo/logoDefault.png';
export const CC_RESPONSE_ENABLED = 'cc_response_enable';
export const CCC_RESPONSE_ENABLED = 'ccc_response_enable';
export const LIMITED_RECORDS = 'limited_records';
export const CANCELED_DAYS = 'canceled_days';
export const FOOT_LEYENDS = 'foot_leyend';
export const SYSTEM_MANUAL = 'system_manual';
export const MANUAL = 'manual';
