import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DocumentModel } from '../_models/Document.model';

@Injectable({
    providedIn: 'root'
})
export class SubjectDocumentService {
    constructor(private http: HttpClient) { }

    url = environment.apiUrl + '/api/subjectDocument';

    /**
     *
     * @returns {Observable<any>}
     */
    public download(documentIds): Observable<any> {
        return this.http.post(this.url + '/download' , documentIds, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        } );
    }

    public downloadAllSubject(dataToDownload) {
        return this.http.post(this.url + '/downloadAll', dataToDownload, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
    }

  /**
   *
   * @returns {Observable<any>}
   */
    public deleteDocument(document: DocumentModel): Observable<any> {
        return this.http.post(this.url + '/delete', document );
    }
}
