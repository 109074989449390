import { Action } from '@ngrx/store';

export const STORE_TYPE_REPORT = '[Graph] Store Type Report';
export const CLEAR_ALL = '[Archival] Clear All';

export class StoreTypeReport implements Action {
    readonly type = STORE_TYPE_REPORT;

    constructor(public payload) {}
}

export class ClearAll implements Action {
    readonly type = CLEAR_ALL;

    constructor() {}
}

export type Actions = StoreTypeReport

        | ClearAll;
