import { Action } from '@ngrx/store';
import { SubjectSeverityModel } from '../../_models/SubjectSeverityModel';

export const ADD_PROCESSES         = '[Process] Add processes'
export const CLEAR_ALL_PROCESSES   = '[Process] Clear all processes'

export class AddProcessesActions implements Action {
    readonly type = ADD_PROCESSES;

    constructor(public payload, public severity: Array<SubjectSeverityModel> ) {}
}

export class ClearAllProcessesAction implements Action {
    readonly type = CLEAR_ALL_PROCESSES;

    constructor() {}
}

export type Actions = AddProcessesActions
    | ClearAllProcessesAction;
