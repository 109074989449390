import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditorGuard } from '../../../../src/app/_guards/editor.guard';
import { EditorRouteGuard } from "./_guards/editor-route.guard";

const routes: Routes = [
    {
        canActivate: [EditorRouteGuard,EditorGuard],
        canDeactivate: [EditorRouteGuard],
        path: 'editor',
        children: [
          {
            path: '',
            redirectTo: 'mailbox',
            pathMatch: 'full'
          },
          // {
          //   path: 'mailbox',
          //   pathMatch: 'prefix',
          //   loadChildren: () => import('./mailboxes/mailboxes.module').then(m => m.MailboxesModule)
          // },
          {
            path: 'templates',
            loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule)
          },
          {
            path: 'systemSettings',
            loadChildren: () => import('./system-settings/system-settings.module').then(m => m.SystemSettingsModule)
          },
          {
            path: 'office',
            loadChildren: () => import('./editor/editor.module').then(m => m.EditorModule)
          },
          {
            path: 'new-office',
            loadChildren: () => import('./new-document/new-document.module').then(m => m.NewDocumentModule)
          },
        ]
        // redirectTo: 'mailbox',
        // pathMatch: 'full'
    },

];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
