import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from '../_helpers/Helper';
import { environment } from '../../environments/environment';
import {
  EXTERNAL_SUBJECT,
  EXTERNAL_SUBJECT_CODE, EXTERNAL_SUBJECT_LABEL,
  INTERNAL_SUBJECT,
  INTERNAL_SUBJECT_CODE, INTERNAL_SUBJECT_LABEL
} from '../_constants/SubjectTypeConstants';
import * as SubjectSelector from '../_store/selectors/subject.selector';
import { Store } from '@ngrx/store';
import * as MailboxReducer from '../_store/reducers/mailbox.reducer';
import { SubjectRequestModel } from '../_models/SubjectRequest.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};
@Injectable({
  providedIn: 'root'
})
export class SubjectService {

    constructor(
      private http: HttpClient, helper: Helper,
      private subject$: Store<MailboxReducer.State>,
    ) { }

    url = environment.apiUrl + '/api/subject';
    url_ = environment.apiUrl + '/api/subjectRequest';

    /**
     *
     * @returns {Observable<any>}
     */
    public add(userData): Observable<any> {
        return this.http.post(this.url , userData );
    }

    /**
     *
     * @param answer
     * @param recipientId
     */
    public addAnswer(answer, recipientId): Observable<any> {
        return this.http.post(this.url + '/addAnswer/' + recipientId , answer );
    }

    public addAnswerType(answer, recipientId): Observable<any> {
      return this.http.post(this.url + '/addAnswerType/' + recipientId , answer );
    }

    /**
     *
     * @param id
     * @param organizationalUnit_id
     */
    public finalizarAsunto(id, organizationalUnit_id): Observable<any> {
        const params = {
            organizationalUnit: organizationalUnit_id
        };
        return this.http.post(this.url + '/finalizarAsunto/' + id, params);
    }

    /**
     *
     * @param recipientId
     * @param status
     */
    public marcarResuelto(recipientId: number, status: number): any {
        return this.http.post(this.url + '/resolver/' + recipientId + '/' + status, httpOptions);
    }

    /**
     *
     * @param subjectRequestId
     * @param organizationalUnitId
     * @param data
     */
    public avanzar(subjectRequestId: number, organizationalUnitId: number, data ) {
        return this.http.post(this.url + '/avanzar/' + subjectRequestId + '/' + organizationalUnitId, data);
    }

    /**
     *
     * @param params
     */
    public rejectSubject(params: { reasonForRejection: string, recipient_id: number } ): Observable<any> {
        return this.http.post(this.url + '/reject', params);
    }

    /**
     *
     * @param recipientId
     * @param data
     */
    public addSubjectAttachments(recipientId: number, data): Observable<any> {
        return this.http.post(this.url + '/attachments/' + recipientId, data);
    }

    /**
     *
     * @param recipientId
     */
    public cancel(recipientId): Observable<any> {
        return this.http.post(this.url + '/cancel/' + recipientId, httpOptions);
    }

  /**
   *
   * @param subjectType
   */
  public getSubjectTypeCode(subjectType: number): string {
      switch (subjectType) {
        case INTERNAL_SUBJECT:
          return INTERNAL_SUBJECT_CODE;
        case EXTERNAL_SUBJECT:
          return EXTERNAL_SUBJECT_CODE;
      }
  }

  /**
   * 1 => internal
   * 2 => external
   * @param subjectType
   */
  public getSubjecTypetLabel(subjectType: number): string {
    switch (subjectType) {
      case INTERNAL_SUBJECT:
        return INTERNAL_SUBJECT_LABEL;
      case EXTERNAL_SUBJECT:
        return EXTERNAL_SUBJECT_LABEL;
      default:
        return INTERNAL_SUBJECT_LABEL;
    }
  }

  /**
   * internal => 1
   * external => 2
   * @param subjectType
   */
  public getSubjectType(subjectType: string): number {
    switch (subjectType) {
      case INTERNAL_SUBJECT_CODE:
        return INTERNAL_SUBJECT;
      case EXTERNAL_SUBJECT_CODE:
        return EXTERNAL_SUBJECT;
    }
  }

  public getCurrentSubject(): Observable<SubjectRequestModel> {
    return this.subject$.select(SubjectSelector.getCurrentSubject);

  }

    public subjectClassification(params): Observable<any> {
        return this.http.post(this.url_ + '/classification', params);
    }
}
