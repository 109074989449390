import { Action } from '@ngrx/store'
import * as ProcessActions from './../actions/process.actions';
import { ProcessModel } from '../../_models/process.model';
import { SubjectSeverityModel } from '../../_models/SubjectSeverityModel';

export interface State {
  list: Array<ProcessModel>,
  severity: Array<SubjectSeverityModel>
}

const initialState: State = {
  list: null,
  severity: null
};

export function reducer(state = initialState, action: ProcessActions.Actions) : any {

    switch(action.type) {
        case ProcessActions.ADD_PROCESSES:
            return {...state, list: action.payload, severity: action.severity};
        case ProcessActions.CLEAR_ALL_PROCESSES:
            return [];
        default:
            return state;
    }
}


