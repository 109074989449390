import { MatDialogConfig } from '@angular/material/dialog';
import { CAT_DATA_TYPE } from '../constants/CatDataType';
import { DynamicFormBuilder } from '../../dynamic-form/components/DynamicFormBuilder';
import * as DialogActions from '../_imports/dialog.import';

export class FunctionExporter {

  static isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }

  static isEmptyObject(obj: object) {
    return Object.keys(obj).length === 0;
  }

  static warningMessage(message, dialog, disableClose = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Advertencia',
      textContent: message
    };
    dialogConfig.maxWidth = '350px';
    dialogConfig.panelClass = 'dialog-confirmation-class';

    return  dialog.open(DialogActions.WarningConfirmationDialogComponent, dialogConfig);
  }

  static showSuccessNotifier(message, notifier) {
    notifier.show({
      type: 'success',
      message: message
    });
  }

  static showWarningNotifier(message, notifier) {
    notifier.show({
      type: 'warning',
      message: message
    });
  }

  static showErrorNotifier(message, notifier) {
    notifier.show({
      type: 'error',
      message: message
    });
  }

  static filterNodes(text, treeComponent) {
    if (text.trim().length > 0) {
      treeComponent.treeModel.filterNodes(text.trim(), true);
    } else {
      treeComponent.treeModel.clearFilter();
    }
  }

  static updateTreeAndExpand(treeComponent) {
    treeComponent.treeModel.update();

    setTimeout(() => {
      treeComponent.treeModel.expandAll();
    }, 100);
  }

  static createBasicStructure(res) {
    const structure = [
      [
        {
          'type': CAT_DATA_TYPE.SECTION,
          'name': CAT_DATA_TYPE.SECTION,
          'field_name_text': 'Plazo de Conservación',
          'tooltip': 'Plazo de Conservación',
          'system_field': true
        }
      ]
    ];

    let newArray = [];
    res.data.forEach(data => {
      newArray.push(data);
      if (newArray.length >= 2) {
        structure.push(newArray);
        newArray = [];
      }
    });
    structure.push([]);
    return structure;
  }

  static newStructure(res) {
    const global = [];
    Object.getOwnPropertyNames(res.templateValidity).forEach(val => {
      const array = Object.values(res.templateValidity[val]);
      switch (val) {
        case 'section_1': { global.push([this.createSectionStructure('Valor Documental')]); break; }
        case 'section_2': { global.push([this.createSectionStructure('Plazos de Conservación')]); break; }
        case 'section_3': { global.push([this.createSectionStructure('Destino Final')]); break; }
        case 'section_4': { global.push([this.createSectionStructure('Clasificación de la Informacion')]); break; }
        case 'section_6': { return; }
      }
      this.arrayChunk(array, 3).forEach(value => {
        global.push(value);
      });
    });
    global.push([]);

    return global;
  }

  static createSectionStructure(res) {
    return {
      'type': CAT_DATA_TYPE.SECTION,
      'name': CAT_DATA_TYPE.SECTION,
      'field_name_text': res,
      'tooltip': res,
      'system_field': true
    };
  }

  static arrayChunk(array, chunkSize) {
    const chunk = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunk.push(array.slice(i, i + chunkSize));
    }
    return chunk;
  }

  // validate name duplicate
  static noDuplicates(data) {
    const names = [];
    let size;
    let unicos;
    data.forEach(item => {
      if (item.type !== CAT_DATA_TYPE.SECTION && item.field_name !== '') {
        names.push(item.field_name);
      }
    });
    size = names.length;
    unicos = names.filter((valor, indice) => {
      return names.indexOf(valor) === indice;
    });

    return size === unicos.length;
  }

  // validate components fields
  static ownProperty(flatData) {
    const arr = [];
    flatData.forEach(flatItem => {
      if (flatItem.type !== CAT_DATA_TYPE.SECTION) {
        const validate = flatItem.hasOwnProperty('field_name_text') && ( flatItem.field_name_text.length > 0 );
        arr.push(validate);
      }
    });
    return arr.includes(false);
  }

  static originComponent(item, savedStructure) {
    const arr = [];
    const dataFlat = DynamicFormBuilder.flatDeep(savedStructure, 1);
    dataFlat?.forEach(data => {
      arr.push(data['field_name_text'] === item['field_name_text']);
    });
    return arr.includes(true);
  }

  static helperList(dataType) {
    switch (dataType.field_name.toLowerCase()) {
      case CAT_DATA_TYPE.STRING:
        return {
          name: 'input',
          icon: 'font_download',
          placeholder: dataType.name
        };
      case CAT_DATA_TYPE.TEXT:
        return {
          name: 'textarea',
          icon: 'description',
          placeholder: dataType.name
        };
      case CAT_DATA_TYPE.DATE_TIME:
        return {
          name: 'date',
          icon: 'today',
          placeholder: dataType.name
        };
      case CAT_DATA_TYPE.BOOLEAN:
        return {
          name: 'checkbox',
          icon: 'check_box',
          placeholder: dataType.name
        };
      case CAT_DATA_TYPE.INTEGER:
        return {
          name: 'input',
          icon: 'pin',
          placeholder: dataType.name
        };
      case CAT_DATA_TYPE.SECTION:
        return {
          name: 'section',
          placeholder: dataType.name
        };
    }
  }

  static formValueByFieldName(data, fieldName) {
    return data.flat().find(item => item.field_name === fieldName);
  }

  static ID() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  static openModals(data = null) {
    const dialogConfig = new MatDialogConfig();
    const mobileMode = window.innerWidth <= 960;
    dialogConfig.data = data;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.backdropClass = 'backdrop';
    dialogConfig.panelClass = 'dialog-confirmation-class';
    if (mobileMode) {
      dialogConfig.minWidth = '100vw';
      dialogConfig.minWidth = '100vh';
      dialogConfig.maxWidth = '100vw';
      dialogConfig.maxHeight = '100vh';
      dialogConfig.hasBackdrop = false;
    } else {
      dialogConfig.maxHeight = '85vh';
      dialogConfig.minWidth = '60vw';
    }

    return dialogConfig;
  }

  static cloneObject(object) {
    return JSON.parse(JSON.stringify(object));
  }
}
