import * as DocumentActions from '../actions/office.actions'
import { ParticipantModel } from '../../_models/Participant.model';
import { OfficeModelInterface } from '../../_models/OfficeModelInterface';
import { UserRoleOrganizationalUnitModel } from '../../_models/UserRoleOrganizationalUnit.model';
import { UserModel } from "../../../../../../src/app/_models/user.model";

export interface State {
  documentParticipant: ParticipantModel,
  participants: Array<UserRoleOrganizationalUnitModel>,
  selectedParticipants: Array<UserModel>,
  validDocumentMetadata: boolean,
  office: OfficeModelInterface,
  isLoading: boolean,
  isLoadingDialog: boolean,
  currentOrganizationalUnitId: number
  currentParticipantId: number;
  hasChanges: boolean;
  isOpenOffice: boolean;
}

const initialState: State = {
  documentParticipant: null,
  participants: [],
  selectedParticipants: null,
  validDocumentMetadata: false,
  office: null,
  isLoading: false,
  isLoadingDialog: false,
  currentOrganizationalUnitId: null,
  currentParticipantId: null,
  hasChanges: false,
  isOpenOffice: true
};

export function reducer(state = initialState, action: DocumentActions.Actions) : any  {

    switch(action.type) {
      case DocumentActions.CLEAR_ALL:
        return initialState;
      case DocumentActions.STORE_DOCUMENT_PARTICIPANTS:
        return {...state, documentParticipant: action.documentParticipant };
      case DocumentActions.ADD_OFFICE_PARTICIPANT:
        let officeParticipants = (state.office.participants == null) ? [] : Object.assign([], state.office.participants);
        let office = {...state.office, participants: officeParticipants.concat(action.participant) };
        return {...state, office: office }
      case DocumentActions.STORE_OFFICE:
        return { ...state, office: action.document };
      case DocumentActions.UPDATE_OFFICE:
        return { ...state, office: {...state.office, ...action.officeAttributes}  };
      case DocumentActions.STORE_DOCUMENT:
        return { ...state, office: action.document };
      case DocumentActions.SET_IS_LOADING:
        return { ...state, isLoading: action.isLoading };
      case DocumentActions.SET_IS_LOADING_DIALOG:
        return { ...state, isLoadingDialog: action.isLoading };
      case DocumentActions.SET_CURRENT_ORGANIZATIONAL_UNIT_ID:
        return { ...state, currentOrganizationalUnitId: action.organizationalUnitId }
      case DocumentActions.SET_CURRENT_PARTICIPANT_ID:
        return { ...state, currentParticipantId: action.participant_id }
      case DocumentActions.SET_IS_OPEN_OFFICE:
        return { ...state, isOpenOffice: action.isOpenOffice };
      case DocumentActions.UPDATE_RUBRICA: {
        let participants = state.office.participants.map(participant => {
          if ( participant.id == action.rubrica.participant_id ) {
            return { ...participant, rubrica: action.rubrica };
          }
          return participant;
        });
        const office = {...state.office, participants: participants }
        return { ...state, office: office };
      }
      case DocumentActions.HAS_CHANGES: {
        return { ...state, hasChanges: action.hasChanges }
      }
      case DocumentActions.UPDATE_PARTICIPANT_ATTRIBUTES: {
        let attributes = { ...state.office.participant, ...action.attributes };

        let participants = state.office.participants.map(participant => {
          if ( state.office.participant.id == participant.id ) {
            return { ...participant, ...action.attributes };
          }
          return participant;
        });

        return { ...state, office: {...state.office, participant: attributes, participants: participants }  };
      }
      default:
        return state;
    }
}


