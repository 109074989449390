import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { faBan, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubjectService } from '../../_services/subject.service';
import { ErrorMessageComponent } from '../../messages/error-message/error-message.component';

@Component({
  selector: 'app-reject-subject',
  templateUrl: './reject-subject.component.html',
  styleUrls: ['./reject-subject.component.css']
})
export class RejectSubjectComponent implements OnInit {
  @ViewChild(ErrorMessageComponent, {static: true}) errorMessage;

  formGroup: FormGroup;
  title: String;
  textContent: String;
  faCancel = faBan;
  faConfirm = faExclamationCircle;
  submitted = false;

  constructor(
    public dialogRef: MatDialogRef<RejectSubjectComponent>,
    private fb: FormBuilder,
    private subjectService: SubjectService,
    @Inject(MAT_DIALOG_DATA) public dialogData
  ) {
    this.title = this.dialogData.title;
    this.textContent = this.dialogData.textContent;
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      reasonForRejection: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(10000)]],
    });
  }

  // convenience getter for easy access to form fields
  get form() { return this.formGroup.controls; }

  confirm() {
    this.submitted = true;

    Object.keys(this.formGroup.controls).forEach(field => { // {1}
      const control = this.formGroup.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });

    // stop here if form is invalid
    if (this.formGroup.invalid) {
      return;
    }

    const reason = this.formGroup.get('reasonForRejection').value;

    const params = {
      reasonForRejection: reason,
      recipient_id: this.dialogData.recipient.id
    };

    console.log(params);

    this.subjectService.rejectSubject(params).subscribe(response => {
      if (response['status']) {
        this.dialogRef.close({confirmation: true});
      } else {
        console.error(response);
        this.errorMessage.setErrorMessage(response);
      }
    }, error => {
      this.errorMessage.setErrorMessage(error);
    });

  }

  cancel() {
    this.dialogRef.close({confirmation: false});
  }

}
