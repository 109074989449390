import { Action } from '@ngrx/store'
import * as UserDataAction from './../actions/userData.actions';
import { UserModel } from "../../../../../../src/app/_models/user.model";

export interface State {
    userData: UserModel,
}

const initialState: State = {
    userData: null,
};

export function reducer(state = initialState, action: UserDataAction.Actions) : any {

    switch(action.type) {
        case UserDataAction.STORE_DATA:
            return {...state.userData, userData: action.payload};
        case UserDataAction.CLEAR_DATA:
            return [];
        default:
            return state;
    }
}


