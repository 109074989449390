import { Action } from '@ngrx/store';
import { SubjectRequestModel } from "../../_models/SubjectRequest.model";

export const STORE_SUBJECT_REQUEST = '[Archival] Store Subject Request';
export const SET_LOADING_STATE     = '[Archival] Set loading state';
export const SET_IS_NEW_EXPEDIENT  = '[Archival] Set isNewExpedient';
export const STORE_TEMPLATE_METADATA = '[Archival] Store Template Metadata';
export const SET_IS_VALID_SERIE    = '[Archival] Set is valid serie';
export const SET_IS_VALID_TEMPLATE = '[Archival] Set is valid template';
export const STORE_SERIE_DATA      = '[Archival] Store Serie Data';
export const STORE_TEMPLATE_METADATA_VALUE  = '[Archival] Store metadata value';
export const CLEAR_ALL             = '[Archival] Clear All';

export class StoreSubjectRequest implements  Action {
    readonly type = STORE_SUBJECT_REQUEST;

    constructor(public subjectRequest: SubjectRequestModel) {}
}

export class SetLoadingState implements  Action {
  readonly type = SET_LOADING_STATE;

  constructor(public setLoading: boolean) {}
}

export class StoreTemplateMetadata implements  Action {
  readonly type = STORE_TEMPLATE_METADATA;

  constructor(public templateMetadata: Array<any>) {}
}

/**
 * Store value filled by the users
 */
export class StoreTemplateMetadataValue implements  Action {
  readonly type = STORE_TEMPLATE_METADATA_VALUE;

  constructor(public templateMetadata: any) {}
}

export class SetIsNewExpedient implements  Action {
  readonly type = SET_IS_NEW_EXPEDIENT;

  constructor(public isNewExpedient: boolean) {}
}

export class SetIsValidSerie implements  Action {
  readonly type = SET_IS_VALID_SERIE;

  constructor(public isValidSerie: boolean) {}
}

export class SetIsValidTemplate implements  Action {
  readonly type = SET_IS_VALID_TEMPLATE;

  constructor(public isValidTemplate: boolean) {}
}

export class StoreSerieData implements  Action {
  readonly type = STORE_SERIE_DATA;

  constructor(public serieData: any) {}
}


export class ClearAll implements Action {
  readonly type = CLEAR_ALL;

  constructor() {}
}

export type Actions = StoreSubjectRequest
  | SetLoadingState
  | SetIsNewExpedient
  | SetIsValidSerie
  | StoreTemplateMetadata
  | SetIsValidTemplate
  | StoreSerieData
  | StoreTemplateMetadataValue
  | ClearAll;
