import { Action } from '@ngrx/store';

export const STORE_DATA  = '[User Data] Store Data'
export const CLEAR_DATA  = '[User Data] Clear Data'

export class StoreDataAction implements Action {
    readonly type = STORE_DATA;

    constructor(public payload ) {}
}

export class ClearDataAction implements Action {
    readonly type = CLEAR_DATA;

    constructor() {}
}

export type Actions = StoreDataAction
    | ClearDataAction;
