import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubjectModule } from '../../../../src/app/subject/subject.module';
import { FilterPipe } from './_core/_pipe/filter.pipe';
import { SharedModule } from '../../../../src/app/shared.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
    declarations: [
        FilterPipe,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        // Modulos de Paquteria NPM
        CKEditorModule,
        // Modulos del Proyectos
        SubjectModule,
        SharedModule,
        // Pipe
        FilterPipe
    ],
})
export class SharedArchivalModule {
}
