import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubjectRequestModel } from '../../_models/SubjectRequest.model';
import { Store } from '@ngrx/store';
import * as SubjectReducer from '../../_store/reducers/subject.reducer';
import * as SubjectSelector from '../../_store/selectors/subject.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-subject-header',
  templateUrl: './subject-header.component.html',
  styleUrls: [ './subject-header.component.css' ]
})
export class SubjectHeaderComponent implements OnInit, OnDestroy {
  subjectRequest: SubjectRequestModel;
  destroy$: Subject<void> = new Subject();

  constructor(
          private store$: Store<SubjectReducer.State>,
          private subject$: Store<SubjectReducer.State>
  ) { }

  ngOnInit() {
    this.subject$.select(SubjectSelector.getCurrentSubject).pipe(
            takeUntil(this.destroy$),
            filter(data => data !== null)
    ).subscribe(subject => {
      this.subjectRequest = subject;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
