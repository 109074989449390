import * as SubjectActions from './../actions/subject.actions';
import { SubjectFlowModel, SubjectRequestModel } from '../../_models/SubjectRequest.model';

export interface State {
  currentSubject: SubjectRequestModel;
  subjectFlow: { details: Array<SubjectFlowModel>, documents: Array<any> };
  isLoadingDialog: boolean;
  isLoadingPDF: boolean;
  isChangeTask: boolean;
  isLoading: boolean;
  isLoadingFlowChart: boolean;
}

const initialState: State = {
  currentSubject: null,
  subjectFlow: null,
  isLoadingDialog: false,
  isLoadingPDF: false,
  isLoading: false,
  isLoadingFlowChart: false,
  isChangeTask: false,
};

export function reducer(state = initialState, action: SubjectActions.Actions): any {

  switch (action.type) {
    case SubjectActions.SET_IS_LOADING_DIALOG: {
      return {...state, isLoadingDialog: action.isLoading };
    }

    case SubjectActions.SET_IS_LOADING_PDF: {
      return {...state, isLoadingPDF: action.isLoading };
    }

    case SubjectActions.SET_IS_LOADING: {
      return {...state, isLoading: action.isLoading };
    }
    case SubjectActions.SET_IS_LOADING_FLOW_CHART: {
      return {...state, isLoadingFlowChart: action.isLoadingFlowChart };
    }

    case SubjectActions.SET_IS_CHANGE_TASK: {
      return {...state, isChangeTask: action.ischangeT };
    }

    case SubjectActions.CLEAR_SUBJECT: {
      return {...initialState};
    }
      case SubjectActions.STORE_CURRENT_SUBJECT:
            return {...state, currentSubject: action.subject};
        case SubjectActions.STORE_SUBJECT_FLOW:
            const subjectFlow = { details: action.subjectFlow, documents: action.documents };
            return {...state, subjectFlow: subjectFlow};
        case SubjectActions.ADD_TASK_ANSWER:
            let tasks = state.currentSubject.tasks.map(task => {
                if (task.id === action.task.id) {
                    return {...task, answers: task.answers.concat(action.answer)};
                }
                return task;
            });

            return {...state, currentSubject: {...state.currentSubject, tasks: tasks, isChangeTask: true}};
        case SubjectActions.UPDATE_TASK_STATUS:
            tasks = state.currentSubject.tasks.map(task => {
                if (task.id === action.task.id) {
                    return {...task, completed: action.status};
                }
                return task;
            });

            return {...state, currentSubject: {...state.currentSubject, tasks: tasks}, isChangeTask: true};
        case SubjectActions.ASSIGN_USER_TO_TASK:
            tasks = state.currentSubject.tasks.map(task => {
                if (task.id === action.task.id) {
                    return {...task, userAssigned: action.user};
                }
                return task;
            });

            return {...state, currentSubject: {...state.currentSubject, tasks: tasks}, isChangeTask: true};
        case SubjectActions.ADD_SUBJECT_ANSWER:
            const subjectAnswers = (state.currentSubject.answers !== null) ? state.currentSubject.answers : [];

            return {...state, currentSubject: {...state.currentSubject, answers: subjectAnswers.concat(action.answer)}};
        case SubjectActions.ADD_SUBJECT_DOCUMENTS:
            const subjectDocuments = state.currentSubject.documents;
            return {...state, currentSubject: { ...state.currentSubject, documents: subjectDocuments.concat(action.documents) }};

        case SubjectActions.UPDATE_SUBJECT_DOCUMENTS:
          return {...state, currentSubject: { ...state.currentSubject, documents: action.documents }};

    case SubjectActions.UPDATE_SUBJECT_ATTRIBUTES: {
      return { ...state, currentSubject: {...state.currentSubject, ...action.attributes}  };
    }
        default:
            return {...state};
    }
}


