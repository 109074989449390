import { Component, OnInit, ViewChild } from '@angular/core';
import { OrganizationalUnitService } from '../_services/organizational-unit.service';
import { ErrorMessageComponent } from '../messages/error-message/error-message.component';
import { faBan, faBuilding, faSitemap, faUsers, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { TreeComponent, TreeModel } from 'angular-tree-component';
import { MatDialogRef } from '@angular/material/dialog';
import { OrganizationalUnitModel } from '../_models/OrganizationalUnit.model';
import { UsersService } from '../_services/users.service';
import { UserRole } from '../_constants/CatUserRoleConstants';
import { CAT_SYSTEM_USER_ROLE } from '../_constants/CatSystemUserRoleConstants';

const COMPANY_NODE = 'company';
const PREFIX_COMPANY_NODE = 'company_';


@Component({
  selector: 'app-organizational-unit-tree-hierarchy',
  templateUrl: './organizational-unit-tree-hierarchy.component.html',
  styleUrls: ['./organizational-unit-tree-hierarchy.component.css']
})
export class OrganizationalUnitTreeHierarchyComponent implements OnInit {
  @ViewChild('tree') treeComponent: TreeComponent;
  @ViewChild(ErrorMessageComponent) errorMessage;

  loading = true;
  organizationalUnitIconTitle = faSitemap;

  faUsers = faUsers;
  faSearch = faSearchPlus;
  faCompany = faBuilding;
  faCancel = faBan;

  faOrganizationalUnit = faSitemap;
  treeModel: TreeModel;

  nodes = [];
  companies = [];
  organizationalUnit: any;

  treeOptions: any = {
    isExpandedField: 'expanded',
    useVirtualScroll: false,
    animateExpand: true,
    scrollOnActivate: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
  };

  constructor(
    private organizationalUnitService: OrganizationalUnitService,
    private dialogRef: MatDialogRef<OrganizationalUnitTreeHierarchyComponent>,
    public userService: UsersService,
  ) {
  }

  ngOnInit(): void {
    this.organizationalUnit = this.userService.userData.organizationalUnits.filter(organizationalUnit => {
      return organizationalUnit.role.code == UserRole.TITULAR || UserRole.ASISTENTE;
    });

    this.organizationalUnitService.getWithCompanies().subscribe(
      response => {
        if (response.status) {
          const user = this.userService.userData.systemRole.code;
          if (user == CAT_SYSTEM_USER_ROLE.ADMIN) {
            this.treeComplete(response);
          } else {
            this.treeHierarchy(response);
          }
        } else {
          this.errorMessage.setErrorMessage(response);
          this.loading = false;
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.errorMessage.setErrorMessage(error);
      }
    );
  }

  treeComplete(data) {
    let organizationalUnit = data.organizationalUnit;

    this.companies = data.companies;

    if (this.companies.length === 0 && organizationalUnit.length === 0) {
      this.loading = false;
      return 0;
    }

    for (let index = 0; index < this.companies.length; index++) {

      let companyNodeData = this.companies[index];
      this.companies[index].company_id = companyNodeData.id;
      this.companies[index].id = PREFIX_COMPANY_NODE + companyNodeData.id;
      this.companies[index].nodeType = COMPANY_NODE;

    }

    for (let index = 0; index < organizationalUnit.length; index++) {
      const organizationalUnitNodeData = organizationalUnit[index];

      const findCompany = this.companies.filter(function (company) {
        return company.id === PREFIX_COMPANY_NODE + organizationalUnitNodeData.company.id;
      });

      if (findCompany.length > 0) {
        const company = findCompany[0];

        if (!company.hasOwnProperty('children')) {
          company.children = [organizationalUnitNodeData];
        } else {
          company.children.push(organizationalUnitNodeData);
        }
      }
    }

    let self = this;
    this.companies.forEach(function (company) {
      if (company.hasOwnProperty('children')) {
        self.nodes.push(company);
      }
    });

    this.treeComponent.treeModel.update();

    this.treeComponent.treeModel.getVisibleRoots().forEach(function (root) {
      root.expand();
    });

    this.loading = false;
  }

  treeHierarchy(data) {
    this.companies = data.companies;
    if (this.companies.length === 0) {
      this.loading = false;
      return 0;
    }

    for (let index = 0; index < this.companies.length; index++) {
      let companyNodeData = this.companies[index];
      this.companies[index].company_id = companyNodeData.id;
      this.companies[index].id = PREFIX_COMPANY_NODE + companyNodeData.id;
      this.companies[index].nodeType = COMPANY_NODE;
    }

    this.recursive(data.organizationalUnit);
  }

  recursive(organizationalUnit) {
    let self = this;
    organizationalUnit.forEach(function (organizationalUnit) {

      const findOrganizationalUnit = self.organizationalUnit.find(organizationalUnit_ => organizationalUnit_.id == organizationalUnit.id);

      if (findOrganizationalUnit) {
        self.buildTreeHierarchy(organizationalUnit);
      }

      if (organizationalUnit.children.length) {
        self.recursive(organizationalUnit.children);
      }
    });
  };

  buildTreeHierarchy(data) {
    const organizationalUnitNodeData = data;

    const findCompany = this.companies.find(function (company) {
      return company.id === PREFIX_COMPANY_NODE + organizationalUnitNodeData.company_id;
    });

    if (findCompany) {
      let company = findCompany;
      if (!company.hasOwnProperty('children')) {
        company['children'] = [organizationalUnitNodeData];
      } else {
        company['children'].push(organizationalUnitNodeData);

      }
    }

    this.nodes.push(findCompany);
    this.treeComponent.treeModel.update();
    this.treeComponent.treeModel.getVisibleRoots().forEach(function (root) {
      root.expand();
    });

    this.loading = false;
  }

  filterNodes(text) {
    if (text.trim().length > 0) {
      this.treeComponent.treeModel.filterNodes(text.trim(), true);
    } else {
      this.treeComponent.treeModel.clearFilter();
    }
  }

  selectOrganizationalUnit(): OrganizationalUnitModel {
    let activeNode = this.treeComponent.treeModel.getActiveNode();

    if (activeNode === undefined || activeNode === null) {
      return null;
    }

    let organizationalUnit = activeNode.data as OrganizationalUnitModel;

    if (organizationalUnit.children && organizationalUnit.children.length > 0) {
      delete organizationalUnit.children;
    }

    this.dialogRef.close(activeNode.data as OrganizationalUnitModel);
  }

}
