import { Component, OnInit } from '@angular/core';
import {UserModel} from '../_models/user.model';
import {UsersService} from '../_services/users.service';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.css']
})
export class UserDataComponent implements OnInit {
  userData: UserModel;
  organizationalUnitsRole: Array<any>;
  public activateSearchBtn: boolean;

  constructor(
    private userService: UsersService,
  ) { }

  ngOnInit(): void {
    this.userData = this.getUserData();
    this.setUserData();
    this.activateSearchBtn = !this.searchbutton();
  }

  getUserData() {
    return this.userService.userData;
  }

  setUserData() {
    if (this.userData === null) {
      return;
    }

    this.organizationalUnitsRole = this.userData.organizationalUnits;
  }

  searchbutton() {
    return window.location.pathname == '/search/finder';
  }

}
