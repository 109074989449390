import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalstorageService } from './Localstorage.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ARCHIVAL_ENABLED, EDITOR_ACTIVATED } from '../_constants/SystemSettingsConstants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {

  constructor(
      private http: HttpClient,
      private localStorage: LocalstorageService,
  ) { }

  url = environment.apiUrl + '/api/systemSettings';

  /**
   *
   * @param {any} idUser
   * @returns {Observable<any>}
   */
  global(idUser = null): Observable<any> {
    const url = this.url + '/login';

    return this.http.get(url, httpOptions);
  }

  setLoginSettings(loginSettings) {
    this.localStorage.storeJsonItem('loginSettings', loginSettings);
  }

  setFontPdfSettings(fontSettings) {
    this.localStorage.storeJsonItem("fontSettings", fontSettings);
  }

  getLoginSettings() {
    return this.localStorage.getJsonItem('loginSettings');
  }

  getFontSettings() {
    return this.localStorage.getJsonItem("fontSettings");
  }

  modifyTemplate(params): Observable<any> {
    const url = this.url + '/emailTemplates/modifyTemplate';

    return this.http.put(url, params);
  }

  getSystemSettings(): Observable<any> {
    const url = this.url;

    return this.http.get(url, httpOptions);
  }

  /*getFontPdf(): Observable<any> {
    const url = environment.apiUrl + '/api/pdf/getfontpdf';

    return this.http.get(url, httpOptions);
  }*/

  setGlobalSettings(globalSettings) {
    this.localStorage.storeJsonItem('system_settings', globalSettings);
  }

  /**
   *
   */
  getGlobalSettings() {
    return this.localStorage.getJsonItem('system_settings');
  }

  /**
   *
   * @param params
   */
  storeSystemLogo(params) {
    return this.http.post(this.url + '/systemlogo', params);
  }

  public storeSystemManual(params): Observable<any> {
    return this.http.post(this.url + '/systemManual', params);
  }

  /**
   *
   * @param params
   */
  storeSystemBackground(params) {
    return this.http.post(this.url + '/systembackground', params);
  }

  get systemSettings(): Array<any> {
    return this.localStorage.getJsonItem('system_settings') as Array<any>;
  }

  getSettingValue(code: string): any | null {
    const settings = this.systemSettings;

    if (settings instanceof Array) {
      const find = settings.find(setting => setting.code == code);
      return (find) ? find.value : '';
    }

    return null;
  }

  modifySettingByCode(code: string, value: string): void {
    const settings = this.systemSettings.map(settings => {
      if (settings.code == code) {
        return {...settings, value: value};
      }

      return settings;
    });

    this.setGlobalSettings(settings);
  }

  /**
   * Update value of system settings by id
   * @param settings
   */
  updateById(settings: { settings: Array<{ code: string, value: string }> }): Observable<any> {
    const url = this.url + '/byCode';
    return this.http.put(url, settings);
  }

  deleteSettingsFile(data): Observable<any> {
    return this.http.post(this.url + '/deleteSettingsFile', data);
  }

  systemSettingsAnswer(params): Observable<any> {
    return this.http.post(this.url + '/systemSettingsAnswer', params);
  }

  isEditorActive(): boolean {
    const editorActivated = this.getSettingValue(EDITOR_ACTIVATED);
    if (editorActivated > 0) {
      return true;
    } else {
      return false;
    }
  }

  isArchivalActive(): boolean {
    const archivalActivated = this.getSettingValue(ARCHIVAL_ENABLED);
    return archivalActivated > 0;
  }

  /**
   *
   * @param params
   */
  systemSettingsPDF(params): Observable<any> {
    return this.http.put(this.url + '/systemSettingsPDF', params);
  }

  setLeyendOficcePDF(params): Observable<any> {
    return this.http.put(this.url + '/systemSettingsLeyendPDF', params);
  }
}
